import { EdgeEventModel } from "@dono-business/shared/models";
import { Link } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import { ModalSubtitle, ModalTitle } from "./styles";

interface TermsModalContentProps {
    event?: EdgeEventModel;
}
export const TermsModalContent = ({ event }: TermsModalContentProps) => {
    return (
        <Box pl={2}>
            <ModalTitle sx={{ display: "block", mb: 3 }}>Terms & Conditions</ModalTitle>
            <Link
                sx={(theme) => ({
                    color: theme.palette.secondary.main,
                    mb: 3,
                    display: "block",
                    fontSize: 14,
                })}
                href="https://donocard.com/raffle-terms-and-conditions"
            >
                DONO Edge Terms & Conditions
            </Link>{" "}
            {event?.termsAndConditions && (
                <>
                    <ModalSubtitle sx={{ fontWeight: 500, mb: 1, display: "block", fontSize: 14 }}>
                        Sponsor's Terms & Conditions
                    </ModalSubtitle>
                    <ModalSubtitle sx={{ display: "block", mb: 2, fontSize: 14 }}>
                        <span dangerouslySetInnerHTML={{ __html: event?.termsAndConditions || "" }}></span>
                    </ModalSubtitle>
                </>
            )}
        </Box>
    );
};
