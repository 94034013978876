/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Countdown } from "@dono-business/shared/components";
import { EdgeEventModel } from "@dono-business/shared/models";
import { formatToInternational, validateUSPhoneNumber } from "@dono-business/shared/utils";
import { Box, Checkbox, FormHelperText, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import toast from "react-simple-toasts";
import dayjs from "dayjs";
import DonoLogo from "../../../../assets/images/logo.svg";
import { ModalContext, ModalItems } from "../modals";
import { useAppRequests } from "@dono-business/shared/hooks";
import { ExpandableText } from "./ExpandableText";

export const RegistrationLandingScreenContent = () => {
    const { edgeRequests } = useAppRequests();

    const [event, setEvent] = useState<EdgeEventModel>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);

    const { eventUniqueId } = useParams();
    const { setIsTermsModalOpen } = useContext(ModalContext) ?? {};

    useEffect(() => {
        if (eventUniqueId) {
            edgeRequests.getEdgeEventByUniqueId(eventUniqueId).then((res) => {
                setEvent(res.data);
            });
        }
    }, [eventUniqueId]);

    const {
        control,
        formState: { isValid },
        getValues,
        handleSubmit,
    } = useForm({
        mode: "onChange",
    });

    const registerUser = useCallback(() => {
        if (event && event?.Id) {
            const { termsAndConditions, ...otherFields } = getValues();
            let customFields = { ...otherFields };
            const phoneNumber = formatToInternational(customFields["Mobile Number"]);
            customFields = {
                ...customFields,
                "Mobile Number": phoneNumber,
            };
            const leadData: any = Object.entries(customFields).map(([name, value]) => ({ name, value }));
            setIsSubmitting(true);
            edgeRequests
                .registerUser(
                    event?.Id,
                    phoneNumber,
                    customFields["First Name"],
                    customFields["Last Name"],
                    customFields["Email"],
                    leadData,
                )
                .then(() => {
                    toast("Registered successfully!");
                    setIsRegistered(true);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    }, [event, getValues]);

    const handleSponsorsTermsClick = () => setIsTermsModalOpen?.(true);
    const hasRegistrationEnded = dayjs(event?.registrationEndDate).isBefore(dayjs());
    const logo = event?.logoContent?.logoContentValue ?? event?.company.logo ?? DonoLogo;
    return (
        <>
            <Box textAlign="center" my={3}>
                <a href={window.location.origin}>
                    <img src={DonoLogo} alt="DONO" />
                </a>
            </Box>
            <Box
                sx={{ background: "linear-gradient(90deg, #0C2145 0%, #12264B 50.77%, #434E82 100%)" }}
                p={3}
                width="100%"
            >
                <Box display="flex" alignItems="center" gap={2} maxWidth={390} margin="auto" position={"relative"}>
                    <Box
                        sx={{
                            width: "75px",
                            minWidth: "75px",
                            height: "75px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            backgroundImage: `url(${logo})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                    />
                    <Box display="flex" flexDirection="column">
                        <Typography fontSize={20} fontWeight={500} color="text.contrast">
                            {event?.name || "-"}
                        </Typography>
                        <Typography fontSize={14} fontWeight={500} color="background.freshDark">
                            {event?.status === "ended" ? (
                                "Ended"
                            ) : hasRegistrationEnded ? (
                                "Registration period is over."
                            ) : (
                                <>
                                    Registration time remaining:{" "}
                                    <Countdown
                                        endDate={
                                            event?.registrationEndDate
                                                ? new Date(event?.registrationEndDate).toLocaleString()
                                                : ""
                                        }
                                    />
                                </>
                            )}
                        </Typography>
                        <Typography fontSize={12} fontWeight={500} color="#C2C6DA">
                            By {event?.company?.name || "-"}
                        </Typography>
                    </Box>
                    <Box
                        position="absolute"
                        sx={{
                            bottom: "0px",
                            transform: "translateY(calc(50% + 24px))",
                            right: "50px",
                        }}
                    >
                        <Typography
                            color="text.contrast"
                            sx={(theme) => ({ background: theme.palette.text.fresh })}
                            px={1}
                            py={0.5}
                            borderRadius={"5px"}
                        >
                            {event?.status}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box maxWidth={400} px={2} margin="auto" my={4}>
                <form onSubmit={handleSubmit(registerUser)}>
                    {event?.leadFieldData.map((field) => (
                        <Box mt={3} textAlign="center" key={field.name}>
                            <Controller
                                name={field.name ?? ""}
                                control={control}
                                rules={{
                                    required: { value: field.required || false, message: `${field.name} is required` },
                                    ...(field.name === "Mobile Number" && {
                                        validate: (value) =>
                                            validateUSPhoneNumber(value) || "Please enter a valid number",
                                    }),
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        label={field.name}
                                        placeholder={
                                            (field.name === "Name" && "John") ||
                                            (field.name === "Email" && "john@example.com") ||
                                            ""
                                        }
                                        autoComplete="off"
                                        variant="standard"
                                        error={Boolean(error)}
                                        helperText={error?.message ?? ""}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                    ))}
                    <Box mt={3}>
                        <Controller
                            name={"termsAndConditions"}
                            control={control}
                            rules={{
                                required: { value: true, message: "Terms & Conditions should be accepted" },
                            }}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <Box>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems={"center"}
                                        columnGap={"1rem"}
                                    >
                                        <Grid item xs>
                                            <Typography variant="subtitle2" fontWeight={"400"}>
                                                I have read and accept{" "}
                                                <Link href="https://donocard.com/raffle-terms-and-conditions">
                                                    DONO Edge Terms & Conditions
                                                </Link>{" "}
                                                and any{" "}
                                                <Link onClick={handleSponsorsTermsClick}>
                                                    Sponsor's Terms & Conditions
                                                </Link>{" "}
                                                added by the sponsor company{event?.termsAndConditions ? ":" : "."}
                                                <br />
                                                {event?.termsAndConditions && (
                                                    <ExpandableText text={event.termsAndConditions} isHtml />
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={"auto"}>
                                            <Checkbox
                                                checked={value}
                                                onChange={(e, checked) => {
                                                    onChange(checked);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                                </Box>
                            )}
                        />
                    </Box>
                    {!isRegistered && (
                        <Button sx={{ mt: 4, mb: 5 }} type="submit" isLoading={isSubmitting} disabled={!isValid}>
                            Register
                        </Button>
                    )}

                    {isRegistered && (
                        <Button
                            sx={{ mt: 4, mb: 5 }}
                            isLoading={isSubmitting}
                            onClick={() => {
                                window.location.href = "https://app.donocard.com";
                            }}
                        >
                            Go to DONO app
                        </Button>
                    )}
                </form>
                <ModalItems event={event} showTerms />
            </Box>
        </>
    );
};
