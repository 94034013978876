import { amountCellValueFormatter } from "@dono-business/shared/utils";
import { capitalize } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { AmountCellRenderer } from "./AmountCellRenderer";
import { BucksTransactionModel } from "packages/shared/models";

export const useBucksTransactionsGridColumns = () => {
    const columns: GridColDef<BucksTransactionModel>[] = useMemo(
        () => [
            {
                field: "amount",
                headerName: "Amount",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueFormatter: amountCellValueFormatter,
                valueGetter: (value, row) => row?.amount?.value ?? "-",
                renderCell: AmountCellRenderer,
            },
            {
                field: "dateCreated",
                headerName: "Date",
                minWidth: 150,
                flex: 1,
                align: "left",
                type: "date",
                valueFormatter: (value) => new Date(value).toLocaleDateString(),
            },
            {
                field: "by",
                headerName: "By",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueGetter: (value, row) => (row?.by?.trim?.() ? row.by : "-"),
            },
            {
                field: "description",
                headerName: "Description",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueFormatter: (value) => {
                    const description = value ?? "-";
                    return String(description)
                        .split("_")
                        .map((str: string) => capitalize(str))
                        .join(" ");
                },
            },
            {
                field: "balance",
                headerName: "Balance",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueFormatter: amountCellValueFormatter,
                valueGetter: (value, row) => row?.balance?.value ?? "-",
            },
        ],
        [],
    );
    return columns;
};
