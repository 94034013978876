import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button, CircularProgress, ClickAwayListener, IconButton, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { FileContent, SelectedFiles, useFilePicker } from "use-file-picker";
import { AxiosResponse } from "axios";
import { MediaFileData } from "packages/shared/models";
import Draggable from "react-draggable";
import html2canvas from "html2canvas";
import { FaRegTrashAlt } from "react-icons/fa";
import { Overlay } from "./styled";

type Props = {
    onUploadStart?: () => void;
    onUploadFinish?: (data: { file: MediaFileData; mediaId: string } | null) => void;
    onFileSelected?: (data: MediaFileData) => void;
    defaultFile?: MediaFileData;
    onClearDefaultFile?: () => void;
    onClearUploadedFile?: () => void;
    onClearSelectedFile?: () => void;
    uploadPhotoRequest?: (photo: File) => Promise<AxiosResponse>;
    uploadVideoRequest?: (video: File) => Promise<AxiosResponse>;
    draggableSrc?: string;
    placeholderTitle?: string | JSX.Element;
    placeholderText?: string | JSX.Element;
    placeholderWidth?: string | number;
    placeholderHeight?: string | number;
    uploadButtonText?: string;
    idealWidth?: number;
    idealHeight?: number;
    acceptedFileTypes?: string[];
};

const idealPersonalizeImageWidth = 328;
const idealPersonalizeImageHeight = 418;
const maximumFileSizeInMb = 30;
export const UploadMedia = function UploadMedia({
    onUploadFinish,
    onUploadStart,
    onFileSelected,
    defaultFile,
    onClearDefaultFile,
    onClearUploadedFile,
    onClearSelectedFile,
    uploadPhotoRequest,
    uploadVideoRequest,
    draggableSrc,
    placeholderTitle,
    placeholderText,
    uploadButtonText,
    idealWidth,
    idealHeight,
    acceptedFileTypes,
    placeholderHeight,
    placeholderWidth,
}: Props) {
    const [selectedFileData, setSelectedFileData] = useState<SelectedFiles | null>(null);
    const [hasRemovedDraggable, setHasRemovedDraggable] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const { filesContent, plainFiles } = selectedFileData ?? {};
    const [lastUploadedFile, setLastUploadedFile] = useState<MediaFileData>();
    const [isUploading, setIsUploading] = useState(false);
    const getSelectedFileType = (plainFiles: File[]) => {
        return plainFiles.length ? (plainFiles[0].type.startsWith("image") ? "photo" : "video") : null;
    };

    const mergeImages = (containerElement: HTMLElement): Promise<{ file: File; dataURL: string }> =>
        new Promise((res) => {
            html2canvas(containerElement, {
                logging: false,
                useCORS: true,
            }).then((canvas1) => {
                const dataURL = canvas1.toDataURL();
                canvas1.toBlob(
                    (blob) =>
                        blob &&
                        res({
                            file: new File([blob], "photo", { type: "image" }),
                            dataURL,
                        }),
                );
            });
        });

    const convertFileToFileContent = (file: File, content: string) => {
        return { name: file.name, lastModified: file.lastModified, content };
    };
    const handleFileSelected = (files: SelectedFiles) => {
        const fileType = getSelectedFileType(files.plainFiles);
        if (fileType === null) return;
        onFileSelected?.({ filesContent: files.filesContent, type: fileType });
        setSelectedFileData(files);
    };
    const handleDefaultFileClear = () => {
        onClearDefaultFile?.();
    };
    const handleSelectedFileClear = () => {
        setSelectedFileData(null);
        onClearSelectedFile?.();
        setHasRemovedDraggable(false);
        setUploadError(false);
        clear();
    };
    const handleUploadedFileClear = () => {
        setLastUploadedFile(undefined);
        onClearUploadedFile?.();
    };
    const createFilesContentAndPlainFilesAfterMerge = async () => {
        if (mainImageRef.current) {
            const { file, dataURL } = await mergeImages(mainImageRef.current);
            const filesContent = convertFileToFileContent(file, dataURL);
            return {
                filesContent: [filesContent as FileContent],
                plainFiles: [file],
            };
        }
    };
    const prepareForUpload = async () => {
        if (plainFiles && filesContent) {
            const fileType = getSelectedFileType(plainFiles);
            if (fileType === null) return;
            else if (isDragModeActive) {
                handleUploadStart?.();
                let result:
                    | {
                          filesContent: FileContent[];
                          plainFiles: File[];
                      }
                    | undefined;
                try {
                    result = await createFilesContentAndPlainFilesAfterMerge();
                } catch {
                    handleUploadFinish?.(null);
                }
                if (result) {
                    const { filesContent, plainFiles } = result;
                    handleFilesUpload({ filesContent, plainFiles });
                }
            } else handleFilesUpload({ filesContent, plainFiles });
        }
    };

    const handleUploadStart = () => {
        setIsUploading(true);
        onUploadStart?.();
    };
    const handleUploadFinish = (data: { file: MediaFileData; mediaId: string } | null) => {
        setIsUploading(false);
        onUploadFinish?.(data);
    };
    const handleFilesUpload = useCallback(
        ({
            filesContent,
            plainFiles,
            onSuccess,
        }: {
            filesContent: FileContent[];
            plainFiles: File[];
            onSuccess?: () => void;
        }) => {
            const selectedFileType = getSelectedFileType(plainFiles);

            if (selectedFileType) {
                handleUploadStart();
                const uploadFileRequest =
                    selectedFileType === "photo"
                        ? uploadPhotoRequest?.(plainFiles[0])
                        : uploadVideoRequest?.(plainFiles[0]);
                uploadFileRequest
                    ?.then((res) => {
                        const mediaId = res.data;
                        setLastUploadedFile({ filesContent, type: selectedFileType });
                        handleUploadFinish({ file: { filesContent, type: selectedFileType }, mediaId });
                        onSuccess?.();
                        handleSelectedFileClear();
                    })
                    .catch(() => {
                        setUploadError(true);
                        handleUploadFinish(null);
                    });
            }
        },
        [onFileSelected, onUploadFinish, onUploadStart],
    );
    const handleRetryButtonClick = () => {
        prepareForUpload();
    };
    const handleBlur = () => {
        if (hasUnuploadedFile && !uploadError && !isUploading) {
            prepareForUpload();
        }
    };
    const [openFileSelector, { clear, errors }] = useFilePicker({
        readAs: "DataURL",
        accept: acceptedFileTypes ?? ["image/*", "video/*"],
        maxFileSize: maximumFileSizeInMb,
        onFilesSuccessfulySelected: handleFileSelected,
    });
    const selectedFileType = defaultFile
        ? defaultFile.type
        : lastUploadedFile
        ? lastUploadedFile.type
        : plainFiles && getSelectedFileType(plainFiles);
    const displayedFile = defaultFile
        ? defaultFile.filesContent
        : lastUploadedFile
        ? lastUploadedFile.filesContent
        : filesContent;
    const hasFile = Boolean(displayedFile?.length);
    const clearFunc = defaultFile
        ? handleDefaultFileClear
        : lastUploadedFile
        ? handleUploadedFileClear
        : handleSelectedFileClear;
    const mainImageRef = useRef<HTMLDivElement | null>(null);
    const draggableImageSrc = draggableSrc;
    const isDragModeActive =
        draggableImageSrc &&
        hasFile &&
        !defaultFile &&
        !lastUploadedFile &&
        !hasRemovedDraggable &&
        selectedFileType === "photo";

    const hasUnuploadedFile = hasFile && displayedFile === filesContent;
    const _idealWidth = idealWidth ?? idealPersonalizeImageWidth;
    const _idealHeight = idealHeight ?? idealPersonalizeImageHeight;
    const _placeholderTitle = placeholderTitle ? (
        typeof placeholderTitle === "string" ? (
            <Typography>{placeholderTitle}</Typography>
        ) : (
            placeholderTitle
        )
    ) : (
        <Typography>Upload a photo or video.</Typography>
    );
    const _placeholderText = placeholderText ? (
        typeof placeholderText === "string" ? (
            <Typography>{placeholderText}</Typography>
        ) : (
            placeholderText
        )
    ) : (
        <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
            <Typography>
                Photo dimensions should ideally be {_idealWidth} x {_idealHeight} pixels.
            </Typography>
            <Typography>Video clips have a maximum file size of {maximumFileSizeInMb} MB.</Typography>
        </Box>
    );

    const _placeHolderWidth = placeholderWidth ?? idealPersonalizeImageWidth;
    const _placeHolderHeight = placeholderHeight ?? idealPersonalizeImageHeight;
    const _uploadButtonText = uploadButtonText ?? "Upload";

    return (
        <ClickAwayListener onClickAway={handleBlur}>
            <Box
                width={_placeHolderWidth}
                height={_placeHolderHeight}
                sx={{ cursor: "pointer" }}
                onClick={() => !hasFile && openFileSelector()}
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="relative"
                border={"1px solid #cbcbcb"}
                borderRadius={2}
                overflow="hidden"
            >
                {hasFile && (
                    <IconButton
                        color="error"
                        onClick={clearFunc}
                        size="small"
                        style={{
                            position: "absolute",
                            top: 2,
                            right: 2,
                            zIndex: 1,
                        }}
                    >
                        <FaRegTrashAlt />
                    </IconButton>
                )}
                {!hasFile && (
                    <Box
                        height={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"space-between"}
                        px={"33px"}
                        py="28px"
                    >
                        {_placeholderTitle}
                        {_placeholderText}
                        {errors.some((err) => err.fileSizeToolarge) && (
                            <Typography color={"error"}>Uploaded files should be smaller than 10 MB.</Typography>
                        )}
                        <Button variant="outlined" fullWidth>
                            {_uploadButtonText}{" "}
                        </Button>
                    </Box>
                )}
                {hasFile &&
                    displayedFile?.map(
                        (file, index) =>
                            (selectedFileType === "photo" && (
                                <Box
                                    style={{
                                        height: _idealHeight,
                                        width: _idealWidth,
                                        backgroundImage: `url(${file.content})`,
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        position: "relative",
                                    }}
                                    key={index}
                                    ref={mainImageRef}
                                >
                                    {isDragModeActive && (
                                        <Draggable bounds="parent" defaultPosition={{ x: 15, y: 15 }}>
                                            <Box position={"relative"} width={"fit-content"}>
                                                <img
                                                    draggable="false"
                                                    src={draggableImageSrc}
                                                    width={100}
                                                    height={"auto"}
                                                />
                                                <IconButton
                                                    color="info"
                                                    size="small"
                                                    onClick={() => setHasRemovedDraggable(true)}
                                                    sx={(theme) => ({
                                                        position: "absolute",
                                                        right: 0,
                                                        zIndex: 1,
                                                        top: 0,
                                                        transform: "translate(50%,-50%)",
                                                        padding: 0,
                                                    })}
                                                    data-html2canvas-ignore
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </Box>
                                        </Draggable>
                                    )}
                                </Box>
                            )) ||
                            (selectedFileType === "video" && (
                                <video
                                    style={{
                                        height: _idealHeight,
                                        maxWidth: _idealWidth,
                                        objectFit: "contain",
                                    }}
                                    key={index}
                                    src={file.content}
                                    controls
                                />
                            )),
                    )}
                {isUploading && (
                    <Overlay data-html2canvas-ignore zIndex={100}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <CircularProgress
                                size={"small"}
                                sx={(theme) => ({ color: theme.palette.text.contrast, width: "20px" })}
                            />
                            <Typography color="white" mt={2}>
                                Uploading...
                            </Typography>
                        </Box>
                    </Overlay>
                )}

                {uploadError && !isUploading && (
                    <Overlay data-html2canvas-ignore>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={handleRetryButtonClick}
                            sx={() => ({
                                width: "fit-content",
                                whiteSpace: "nowrap",
                                px: "2rem",
                                background: (theme) => theme.palette.background.paper,
                                color: (theme) => theme.palette.background.darker,
                                ":hover": {
                                    background: (theme) => theme.palette.background.disabled,
                                    color: (theme) => theme.palette.background.paper,
                                },
                            })}
                        >
                            <Typography display={"block"} variant="subtitle1" fontWeight="bold">
                                Upload failed!
                            </Typography>
                            <Typography display={"block"} variant="body2">
                                Click here to retry.
                            </Typography>
                        </Button>
                    </Overlay>
                )}
            </Box>
        </ClickAwayListener>
    );
};
