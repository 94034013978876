import ReactQuill, { ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { forwardRef } from "react";
import { Box } from "@mui/material";
import { Toolbar } from "./Toolbar";

export const TextEditorInput = forwardRef(function TextEditorInput(
    { error, modules, uniqueName, ...props }: ReactQuillProps & { error?: boolean; uniqueName: string },
    ref: React.ForwardedRef<ReactQuill>,
) {
    return (
        <Box
            className="texteditor-input"
            sx={(theme) => ({
                border: "1px solid #ccc",
                borderRadius: "10px",
                "& .quill": { position: "relative" },
                "& .ql-toolbar.ql-snow ": {
                    paddingTop: "12px",
                    border: "none",
                    borderBottom: "1px solid #ccc",
                },
                "& .ql-container.ql-snow": {
                    minHeight: "100px",
                    maxHeight: "300px",
                    overflow: "auto",
                    border: "none",
                    position: "static",
                },
                "& .ql-editor": {
                    "&.ql-blank::before": {
                        color: "#a6a6a6",
                        fontStyle: "normal",
                    },
                },
                ...(error && {
                    border: `1px solid ${theme.palette.error.main}`,

                    "&:focus-within": {
                        border: `2px solid ${theme.palette.error.main} !important`,
                    },
                }),
            })}
        >
            <Toolbar id={uniqueName} />
            <ReactQuill
                {...props}
                modules={{ toolbar: { container: `#toolbar-${uniqueName}` }, ...modules }}
                ref={ref}
                onChange={(value, ...restParams) => {
                    if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
                        props.onChange?.("", ...restParams);
                    } else props.onChange?.(value, ...restParams);
                }}
            />
        </Box>
    );
});
