import { styled } from "@mui/material/styles";

export const HeaderLogoContainer = styled("a")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& img": {
        width: 110,
        height: "auto",
        [theme.breakpoints.down("sm")]: {
            width: 79,
        },
    },
}));
