import { styled } from "@mui/material";
import { Button } from "./Button";

export const ButtonWithIcon = styled(Button)(({ theme }) => ({
    display: "flex",
    height: "auto",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    color: theme.palette.text.contrast,
    padding: "10px 16px",
    background: theme.palette.text.fresh,
    width: "auto",
    boxShadow: "none",
    fontWeight: "500",
    lineHeight: 1.4,
    fontSize: "14px",
    letterSpacing: ".1px",
    minWidth: "auto",
    "&:hover": { background: "rgba(114, 137, 173,.8)", boxShadow: "none" },
    "&.Mui-disabled": { background: "#D9D9D9" },
}));
