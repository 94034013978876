import { styled } from "@mui/material/styles";
import { NavLink as RouterNavLink } from "react-router-dom";

export const NavLink = styled(RouterNavLink)(({ theme }) => ({
    fontSize: 16,
    fontWeight: 600,
    padding: theme.spacing(4, 3),
    borderRadius: 8,
    color: theme.palette.text.contrast,
    cursor: "pointer",

    "&.active ": {
        backgroundColor: theme.palette.background.active,
        color: theme.palette.text.contrast,
    },
}));
