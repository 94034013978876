import { Grid } from "@mui/material";
import React, { SyntheticEvent, useContext, useState } from "react";
import { BackButton } from "./BackButton";
import { BoldBlueTextLarge, ButtonWithIcon, EventTitle } from "./styles";
import { EventModifyIcon } from "@dono-business/shared/icons/";
import { ImportantDetails } from "./ImportantDetails";
import { CustomAccordion } from "./CustomAccordion";
import { ExpandableSection } from "./types";
import { EventDetailContext } from "../EventDetailProvider";
import { DetailsBoxMobile } from "./DetailsBoxMobile";
import { Participants } from "./Participants";
import { RegistrationKit } from "./RegistrationKit";
import { CancelEventDialog } from "./CancelEventDialog";

export const EventDetailContent = () => {
    const { event, handleNavigateBackToReports, handleModifyEvent, hasDrawingStarted } =
        useContext(EventDetailContext) ?? {};
    const { name, participantCount } = event ?? {};

    const [expandedSection, setExpandedSection] = useState<null | ExpandableSection>(null);
    const handleExpandableSectionChange = (isExpanded: boolean, section: ExpandableSection) => {
        setExpandedSection(isExpanded ? section : null);
    };
    const handleBackButtonClick = () => handleNavigateBackToReports?.();
    const handleModifyButtonClick = () => handleModifyEvent?.();
    const createSectionChangeHandler = (section: ExpandableSection) => (e: SyntheticEvent, isExpanded: boolean) =>
        handleExpandableSectionChange(isExpanded, section);

    return (
        <>
            <Grid container direction={"column"} alignItems="center">
                <Grid item mb={"28px"} alignSelf="flex-start">
                    <BackButton label="Back to All Reports" onClick={handleBackButtonClick} />
                </Grid>
                <Grid item mb={"16px"} px={"32px"}>
                    <EventTitle>{name}</EventTitle>
                </Grid>
                <Grid item mb="24px">
                    <ButtonWithIcon
                        sx={{ width: "108px" }}
                        onClick={handleModifyButtonClick}
                        disabled={hasDrawingStarted}
                    >
                        <EventModifyIcon width={24} height={24} />
                        Edit
                    </ButtonWithIcon>
                </Grid>
                <Grid item mb={"33px"} sx={{ px: "45px" }}>
                    <ImportantDetails />
                </Grid>
                <Grid item mb={"24px"} alignSelf="flex-start">
                    <BoldBlueTextLarge>Event Information</BoldBlueTextLarge>
                </Grid>
                <Grid item container direction={"column"} gap={"24px"}>
                    <Grid item width={"100%"}>
                        <CustomAccordion
                            label={ExpandableSection.EventDetails}
                            isExpanded={expandedSection === ExpandableSection.EventDetails}
                            onChange={createSectionChangeHandler(ExpandableSection.EventDetails)}
                        >
                            <DetailsBoxMobile />
                        </CustomAccordion>
                    </Grid>{" "}
                    <Grid item width={"100%"}>
                        <CustomAccordion
                            label={ExpandableSection.RegistrationKit}
                            isExpanded={expandedSection === ExpandableSection.RegistrationKit}
                            onChange={createSectionChangeHandler(ExpandableSection.RegistrationKit)}
                        >
                            <RegistrationKit />
                        </CustomAccordion>
                    </Grid>{" "}
                    <Grid item width={"100%"}>
                        <CustomAccordion
                            label={`${ExpandableSection.Participants} ${
                                participantCount !== undefined ? `(${participantCount})` : ""
                            }`}
                            isExpanded={expandedSection === ExpandableSection.Participants}
                            onChange={createSectionChangeHandler(ExpandableSection.Participants)}
                        >
                            <Participants />
                        </CustomAccordion>
                    </Grid>
                </Grid>
            </Grid>
            <CancelEventDialog />
        </>
    );
};
