import { Divider, IconButton, Menu as MuiMenu, MenuItem, Typography } from "@mui/material";
import { getUser } from "@dono-business/shared/utils";
import LogoutIcon from "@mui/icons-material/Logout";

import React, { useState } from "react";
import { useNavigate } from "react-router";
import { HeaderMenuIcon } from "@dono-business/shared/icons";
interface Props {
    additionalOptions?: { title: string; onClick: (e: React.SyntheticEvent) => void }[];
    routes: { title: string; path: string }[];
}
export const Menu = ({ additionalOptions, routes }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const user = getUser();
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLinkItemClick = (path: string) => {
        navigate(path);
        handleClose();
    };
    const logout = () => {
        localStorage.clear();
        navigate("/login");
    };
    const handleLogout = () => {
        logout();
        handleClose();
    };
    const handleBucksLinkClick = () => {
        navigate("/bucks");
    };
    return (
        <div>
            <IconButton
                id="header-menu-button"
                aria-controls={open ? "header-menu-button" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <HeaderMenuIcon />
            </IconButton>
            <MuiMenu
                id="header-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "header-menu-button",
                }}
            >
                <MenuItem>
                    <Typography variant="label">{user?.email || user?.phone}</Typography>
                </MenuItem>
                <Divider />
                {routes.map((route) => (
                    <MenuItem key={route.path} onClick={() => handleLinkItemClick(route.path)}>
                        <Typography>{route.title}</Typography>
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem onClick={handleBucksLinkClick}>
                    <Typography>DONO Bucks</Typography>
                </MenuItem>
                <Divider />
                {additionalOptions?.map(({ title, onClick }) => (
                    <MenuItem key={title} onClick={onClick}>
                        <Typography>{title}</Typography>
                    </MenuItem>
                ))}

                <MenuItem onClick={handleLogout} sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography>Logout</Typography>
                    <IconButton>
                        <LogoutIcon color="error" />
                    </IconButton>
                </MenuItem>
            </MuiMenu>
        </div>
    );
};
