import { Box } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

export const BillContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4, 7),
    borderRadius: 8,
    background: alpha(theme.palette.background.freshLight, 0.1),
    [theme.breakpoints.down("lg")]: {
        padding: theme.spacing(4, 4),
    },
}));
