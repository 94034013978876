import { saveAs } from "file-saver";

export const downloadMedia = async (src: string, filename: string) => {
    const url = src;
    saveAs(url, filename);
};
export const shareUrl = async ({ title, url }: { title: string; url: string }) => {
    const data = { title, url };
    if (navigator.canShare(data)) {
        try {
            navigator.share(data);
        } catch (e) {
            console.log(e);
        }
    }
};
