import { RegisterEdgeEventFormFieldsModel } from "@dono-business/shared/models";
import { useForm } from "react-hook-form";

export const useRegistrationForm = ({ defaultValues }: { defaultValues?: RegisterEdgeEventFormFieldsModel }) => {
    const formOptions = useForm<RegisterEdgeEventFormFieldsModel>({
        mode: "onChange",
        defaultValues: defaultValues ?? {
            eventName: "",
            companyName: "",
            drawingTime: "",
            registrationPeriodEndDateAndTime: "",
            registrationPeriodStartDateAndTime: "",
            termsAndConditions: "",
        },
    });
    return formOptions;
};
