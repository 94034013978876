import React, { useContext, useState } from "react";
import { CustomAccordion } from "./CustomAccordion";
import { Box } from "@mui/material";
import { WideButtonWithIcon } from "./styles";
import { EventCopyIcon, EventModifyIcon, EventCancelIcon } from "@dono-business/shared/icons/";
import { EventDetailContext } from "../EventDetailProvider";

export const MoreEventOptions = () => {
    const {
        setIsCancelEventDialogOpen,
        handleCopyEvent,
        handleModifyEvent,
        hasRegistrationStarted,
        hasDrawingStarted,
    } = useContext(EventDetailContext) ?? {};
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = (e: React.SyntheticEvent, isExpanded: boolean) => setIsExpanded(isExpanded);
    const handleModifyButtonClick = () => handleModifyEvent?.();
    const handleDuplicateButtonClick = () => handleCopyEvent?.();
    const handleCancelButtonClick = () => setIsCancelEventDialogOpen?.(true);

    return (
        <CustomAccordion
            label="More Event Options"
            isExpanded={isExpanded}
            onChange={toggleExpand}
            accordionSummaryStyles={{
                borderRadius: 0,
                border: "none",
                borderTop: "1px solid #bababa",
                pl: "35px",
                ...(!isExpanded && { background: "#EAEDF3" }),
            }}
            labelStyles={{
                fontWeight: "800",
                fontSize: "18px",
            }}
        >
            <Box display={"flex"} flexDirection={"column"} alignItems="center" gap="32px" py="32px">
                <WideButtonWithIcon onClick={handleCancelButtonClick} disabled={hasRegistrationStarted}>
                    <EventCancelIcon />
                    Delete
                </WideButtonWithIcon>
                <WideButtonWithIcon onClick={handleDuplicateButtonClick}>
                    <EventCopyIcon />
                    Duplicate
                </WideButtonWithIcon>
                <WideButtonWithIcon onClick={handleModifyButtonClick} disabled={hasDrawingStarted}>
                    <EventModifyIcon />
                    Edit
                </WideButtonWithIcon>
            </Box>
        </CustomAccordion>
    );
};
