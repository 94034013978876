import { Box, capitalize, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useNavigate } from "react-router";
import React from "react";
import { EdgeReportGetAllEventModel, EventStatus } from "@dono-business/shared/models";
import { createDropDownFilterOptionsFromEnum } from "@dono-business/shared/utils";
export const useReportEventsGridColumns = () => {
    const navigate = useNavigate();
    const columns: GridColDef<EdgeReportGetAllEventModel>[] = useMemo(
        () => [
            {
                field: "dateCreated",
                headerName: "Date Created",
                minWidth: 150,
                flex: 1,
                align: "left",
                type: "date",
                valueFormatter: (value) => new Date(value).toLocaleDateString(),
            },
            {
                field: "name",
                headerName: "Event Name",
                minWidth: 150,
                flex: 1,
                align: "left",
            },
            {
                field: "createdByUserName",
                headerName: "Created By",
                minWidth: 150,
                flex: 1,
                align: "left",
            },
            {
                field: "drawingDate",
                headerName: "Drawing Date",
                minWidth: 150,
                flex: 1,
                align: "left",
                type: "date",
                valueFormatter: (value) => new Date(value).toLocaleDateString(),
            },
            {
                field: "status",
                headerName: "Status",
                minWidth: 100,
                flex: 1,
                align: "left",
                type: "singleSelect",
                valueOptions: createDropDownFilterOptionsFromEnum(EventStatus),
                valueFormatter: capitalize,
                renderCell: (params) => (
                    <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
                        <Box
                            sx={{
                                alignSelf: "center",
                                lineHeight: "18px",
                                ...(params.value === "running" && {
                                    borderRadius: "24px",
                                    py: "7px",
                                    px: "9px",
                                    background: "#EFF1F4",
                                }),
                            }}
                        >
                            <Typography
                                sx={{
                                    color: (theme) => theme.palette.text.fresh,
                                    fontWeight: "800",
                                    fontSize: "12px",
                                    lineHeight: "18px",
                                }}
                            >
                                {params.formattedValue}
                            </Typography>
                        </Box>
                    </Box>
                ),
            },
            {
                field: "registeredCount",
                headerName: "Participants",
                minWidth: 120,
                align: "right",
                flex: 1,
            },
            {
                field: "winnersCount",
                headerName: "Winners",
                minWidth: 120,
                align: "right",
                flex: 1,
            },
            {
                field: "totalAmount",
                headerName: "Dono Card Total",
                minWidth: 120,
                align: "right",
                flex: 1,

                valueGetter(value, row) {
                    return row.amount ? `${row.amount?.symbol}${row.amount?.value}` : "-";
                },
            },
            {
                field: "uniqueId",
                headerName: "ID",
                minWidth: 150,
                flex: 1,
                align: "left",
            },
        ],
        [navigate],
    );
    return columns;
};
