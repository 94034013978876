import { StepBackButton } from "@dono-business/shared/components/step-button-back/StepBackButton";
import { StepContentContainer } from "@dono-business/shared/components/step-content-container";
import React from "react";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { CreateEdgeEventContext } from "./CreateEdgeEventProvider";
import { WideButton } from "./CreateEdgeEventScreen.styles";
import { EventRegistrationFields } from "./EventRegistrationFields";
import { ConfigDivider } from "./styles";
import { EventGiftMedia } from "./EventGiftMedia";
import { GiftsListConfig } from "./GiftsListConfig";
import { useNavigate } from "react-router";
import { modifyTotalValueErrorMessage } from "./helpers";

export const Step2 = () => {
    const {
        giftsList,
        isSubmittingRequest,
        submitRequest,
        setActiveStep,
        setEvent,
        mode,
        event,
        setGiftsListError,
        previouslyPaidAmount,
        rawAmountToPay,
        isCompanyLogoUploading,
        isLoserMediaUploading,
        isWinnerMediaUploading,
        hasLoserMediaUnuploadedFile,
        hasWinnerMediaUnuploadedFile,
        winnerMessageError,
        nonWinnerMessageError,
    } = useContext(CreateEdgeEventContext) ?? {};
    const isUploadingMedia = isCompanyLogoUploading || isLoserMediaUploading || isWinnerMediaUploading;
    const hasUnuploadedFile = hasLoserMediaUnuploadedFile === true || hasWinnerMediaUnuploadedFile === true;
    const hasUploadError =
        hasLoserMediaUnuploadedFile === "uploadError" || hasWinnerMediaUnuploadedFile === "uploadError";
    const navigate = useNavigate();
    const validateGiftTypeSelection = () => {
        if (giftsList?.length === 0) {
            return "At least one giveaway is required.";
        } else {
            return null;
        }
    };
    const validateModifyTotalAmount = () => {
        if (mode === "modify" && (previouslyPaidAmount ?? 0) !== (rawAmountToPay ?? 0)) {
            return modifyTotalValueErrorMessage;
        } else return null;
    };
    const onNextButtonClick = () => {
        if (hasUnuploadedFile) return;
        const giftTypeSelectionError = validateGiftTypeSelection();
        const modifyTotalAmountError = validateModifyTotalAmount();
        if (giftTypeSelectionError) {
            setGiftsListError?.(giftTypeSelectionError);
        } else if (modifyTotalAmountError) {
            setGiftsListError?.(modifyTotalAmountError);
        } else {
            setGiftsListError?.("");

            submitRequest?.({
                onCreateSuccess: (edgeEvent) => {
                    edgeEvent && setEvent?.(edgeEvent);
                    setActiveStep?.(2);
                },
                onModifySuccess: () => {
                    navigate(event?.Id ? `/report/events/${event?.Id} ` : "/report");
                },
            });
        }
    };
    const onPrevButtonClick = () => {
        setActiveStep?.(0);
    };
    return (
        <StepContentContainer isLoading={isSubmittingRequest}>
            <EventRegistrationFields />
            <ConfigDivider orientation="vertical" />
            <GiftsListConfig />
            <ConfigDivider orientation="vertical" />
            <EventGiftMedia />

            <Box mt={2} display="flex" flexDirection={{ xs: "column", sm: "row" }} justifyContent="space-between">
                <StepBackButton onClick={onPrevButtonClick} />

                <WideButton
                    fullWidth={false}
                    disabled={isUploadingMedia || hasUploadError || !!winnerMessageError || !!nonWinnerMessageError}
                    onClick={onNextButtonClick}
                >
                    {isUploadingMedia ? (
                        "Uploading..."
                    ) : mode === "modify" ? (
                        "Submit Changes"
                    ) : (
                        <>
                            Next <KeyboardArrowRight />
                        </>
                    )}
                </WideButton>
            </Box>
        </StepContentContainer>
    );
};
