import { Typography, useTheme } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import React from "react";
import { BucksTransactionModel } from "@dono-business/shared/models/bucks-transaction-model";

type Props = GridRenderCellParams<BucksTransactionModel, number, string>;

export const AmountCellRenderer = (props: Props) => {
    const theme = useTheme();
    const value = props.value;
    const isNegative = (value ?? 0) < 0;
    const isPositive = (value ?? 0) > 0;
    const Icon = isNegative ? MdArrowDownward : isPositive ? MdArrowUpward : () => null;
    const color = isNegative
        ? theme.palette.error.dark
        : isPositive
        ? theme.palette.success.light
        : theme.palette.text.fresh;

    return (
        <Typography
            sx={{
                fontSize: ".875rem",
                display: "flex",
                alignItems: "center",
                color,
                height: "100%",
                lineHeight: "normal",
            }}
        >
            {Icon && <Icon fontSize="1rem" />} {props.formattedValue}
        </Typography>
    );
};
