import React from "react";
import { ModalProvider } from "../modals";
import { RegistrationLandingScreenContent } from "./RegistrationLandingScreenContent";

export const RegistrationLandingScreen = () => {
    return (
        <ModalProvider>
            <RegistrationLandingScreenContent />
        </ModalProvider>
    );
};
