import { Menu } from "@dono-business/shared/components/header/Menu";
import { NavBar } from "@dono-business/shared/components/header/Navbar";
import { UsernameWithSignoutButton } from "@dono-business/shared/components/header/UsernameWithSignoutButton1";
import { useSmallScreenSize } from "@dono-business/shared/hooks";
import Logo from "@dono-business/shared/images/logo.svg";
import { Box, Grid } from "@mui/material";
import React, { memo } from "react";

import { HeaderLogoContainer } from "./Header.styles";
interface Props {
    routes: {
        path: string;
        title: string;
    }[];
}
export const Header = memo(function Header({ routes }: Props) {
    const isSmallScreen = useSmallScreenSize();

    return (
        <div>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item sm={9} xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={{ xs: 2, sm: 5 }} mx={2}>
                        <HeaderLogoContainer href="/">
                            <img src={Logo} alt="DONO LOGO" />
                        </HeaderLogoContainer>{" "}
                        <Box display="flex" justifyContent="center" alignItems={"start"} flexDirection={"column"}>
                            {isSmallScreen ? <Menu routes={routes} /> : <UsernameWithSignoutButton />}
                        </Box>
                    </Box>
                </Grid>
                {!isSmallScreen && <NavBar links={routes} />}
            </Grid>
        </div>
    );
});
