import React from "react";

export const GiftBoxIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="22" viewBox="0 0 17 22" fill="none">
            <rect x="0.579102" y="6.58594" width="7.44558" height="3.78453" rx="0.5" fill="#0C2145" />
            <rect x="9.13379" y="6.58594" width="7.44558" height="3.78453" rx="0.5" fill="#0C2145" />
            <rect x="0.579102" y="11.3281" width="7.44558" height="10.0072" rx="0.5" fill="#0C2145" />
            <rect x="9.13379" y="11.3984" width="7.44558" height="10.0072" rx="0.5" fill="#0C2145" />
            <path
                d="M3.44427 0.844484C5.50248 0.293752 8.72609 4.02899 8.72609 4.02899C8.72609 4.02899 2.5651 5.72064 2.14035 3.21355C1.96794 2.19593 2.41966 1.11865 3.44427 0.844484Z"
                stroke="#0C2145"
                strokeWidth="1.5"
            />
            <path
                d="M13.713 0.844484C11.6547 0.293752 8.43113 4.02899 8.43113 4.02899C8.43113 4.02899 14.5921 5.72064 15.0169 3.21355C15.1893 2.19593 14.7376 1.11865 13.713 0.844484Z"
                stroke="#0C2145"
                strokeWidth="1.5"
            />
        </svg>
    );
};
