import { alpha, Box, styled } from "@mui/system";

export const Overlay = styled(Box)(({ theme }) => ({
    cursor: "auto",
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    background: alpha(theme.palette.background.darker, 0.1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));
