import { EdgeEventUserStatus } from "@dono-business/shared/models";
import toast from "react-simple-toasts";

export const getEdgeUserStatusDisplayValue = (status?: string): string | undefined => {
    if (!status) return status;
    const values: { [key: string]: string } = {
        [EdgeEventUserStatus.Invited]: "Invited",
        [EdgeEventUserStatus.Registered]: "Registered",
        [EdgeEventUserStatus.Winner]: "Winner",
        [EdgeEventUserStatus.Loser]: "Non Winner",
        [EdgeEventUserStatus.NonWinner]: "Non Winner",
    };
    return values[status] || status;
};
export const copyTextToClipboard = (textToCopy: string) => {
    navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
            toast("Copied to clipboard");
        })
        .catch((error) => {
            toast("Error copying");
        });
};
