import React, { createContext, PropsWithChildren, useCallback, useState } from "react";

export const ModalContext = createContext<{
    isInstructionModalOpen: boolean;
    isTimeModalOpen: boolean;
    isPrizeModalOpen: boolean;
    isTermsModalOpen: boolean;
    setIsInstructionModalOpen: (open: boolean) => void;
    setIsTimeModalOpen: (open: boolean) => void;
    setIsPrizeModalOpen: (open: boolean) => void;
    setIsTermsModalOpen: (open: boolean) => void;
    closeModal: () => void;
} | null>(null);
export const ModalProvider = ({ children }: PropsWithChildren) => {
    const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(false);
    const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);
    const [isPrizeModalOpen, setIsPrizeModalOpen] = useState(false);
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const closeModal = useCallback(() => {
        setIsInstructionModalOpen(false);
        setIsPrizeModalOpen(false);
        setIsTimeModalOpen(false);
        setIsTermsModalOpen(false);
    }, []);

    return (
        <ModalContext.Provider
            value={{
                closeModal,
                isInstructionModalOpen,
                isTimeModalOpen,
                isPrizeModalOpen,
                isTermsModalOpen,
                setIsInstructionModalOpen,
                setIsPrizeModalOpen,
                setIsTermsModalOpen,
                setIsTimeModalOpen,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};
