import { useAppRequests } from "@dono-business/shared/hooks";
import { CompanyModel } from "@dono-business/shared/models";
import { getUser } from "@dono-business/shared/utils";
import { useEffect, useState } from "react";
export const useCompany = (defaultValue?: CompanyModel) => {
    const [company, setCompany] = useState<CompanyModel | undefined>(defaultValue);
    const { userRequests } = useAppRequests();

    useEffect(() => {
        if (company) return;
        const userId = getUser()?.id;
        userRequests.getProfile(userId).then((res) => {
            setCompany(res.data?.company);
        });
    }, []);
    return company;
};
