export const appName = "DŌNŌ";
export const defaultGiftAmount = 50;
export const minGiftAmount = 0;
export const baseUrl = "https://api.donocard.com/GiftCardGal/GiftGal/1.3.0";
export const production = false;
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// PROD:
export const firebase = {
    apiKey: "AIzaSyCazhryUOTydtar_34li1ji0L4JBi419Pg",
    authDomain: "dono-app.firebaseapp.com",
    projectId: "dono-app",
    storageBucket: "dono-app.appspot.com",
    messagingSenderId: "84736921243",
    appId: "1:84736921243:web:02ec13f98ec905bee6403e",
    measurementId: "G-C2EZ47PZH3",
};
