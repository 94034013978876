import React, { useContext } from "react";
import { CreateEdgeEventContext } from "../CreateEdgeEventProvider";
import { ListUtilsProvider } from "components/list-utils";
import { OnsiteContactsContent } from "./OnsiteContactsContent";
import { OnsiteContactsContext } from "./Context";

export const OnsiteContacts = () => {
    const { setOnsiteContacts: _setOnsiteContacts } = useContext(CreateEdgeEventContext) ?? {};
    const setOnsiteContacts = _setOnsiteContacts ?? (() => null);
    return (
        <ListUtilsProvider idField={"id"} setItems={setOnsiteContacts} Context={OnsiteContactsContext}>
            <OnsiteContactsContent />
        </ListUtilsProvider>
    );
};
