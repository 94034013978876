import { Components, Theme } from "@mui/material";
import { lightColors } from "../system/colors";

export const MuiTextField = (theme: Theme): Components["MuiTextField"] => ({
    styleOverrides: {
        root: {
            "& .Mui-error": {
                borderColor: theme.palette.error.main,
            },
            "& .MuiInputBase-sizeSmall": {
                height: "48px",
            },
            "& .MuiInputBase-root": { borderRadius: 8 },
            "& .MuiFormLabel-root": {
                fontSize: 16,
                color: theme.palette.text.primary,
            },
            "& .MuiFormLabel-root.Mui-error": {
                color: theme.palette.error.main,
            },
        },
    },
    defaultProps: {
        size: "medium",
        fullWidth: true,
        variant: "outlined",
        InputLabelProps: {
            shrink: true,
        },
        InputProps: {
            style: {
                backgroundColor: lightColors.neutral.white,
                color: lightColors.neutral.black,
            },
        },
        FormHelperTextProps: {
            style: {
                border: "none",
                fontSize: 12,
                marginLeft: 0,
            },
        },
    },
});
