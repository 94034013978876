import React from "react";

export const LinkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 25" fill="none">
            <path
                d="M7.95605 5.7959C6.92105 5.7959 6.08105 6.6359 6.08105 7.6709V17.4209C6.08105 18.4559 6.92105 19.2959 7.95605 19.2959H17.7061C18.7411 19.2959 19.5811 18.4559 19.5811 17.4209V15.1709C19.5811 14.8725 19.6996 14.5864 19.9106 14.3754C20.1215 14.1644 20.4077 14.0459 20.7061 14.0459C21.0044 14.0459 21.2906 14.1644 21.5015 14.3754C21.7125 14.5864 21.8311 14.8725 21.8311 15.1709V17.4209C21.8311 18.5149 21.3965 19.5641 20.6229 20.3377C19.8493 21.1113 18.8001 21.5459 17.7061 21.5459H7.95605C6.86204 21.5459 5.81283 21.1113 5.03924 20.3377C4.26565 19.5641 3.83105 18.5149 3.83105 17.4209V7.6709C3.83105 6.57688 4.26565 5.52767 5.03924 4.75408C5.81283 3.9805 6.86204 3.5459 7.95605 3.5459H10.2061C10.5044 3.5459 10.7906 3.66442 11.0015 3.8754C11.2125 4.08638 11.3311 4.37253 11.3311 4.6709C11.3311 4.96927 11.2125 5.25542 11.0015 5.46639C10.7906 5.67737 10.5044 5.7959 10.2061 5.7959H7.95605ZM12.8311 4.6709C12.8311 4.37253 12.9496 4.08638 13.1606 3.8754C13.3715 3.66442 13.6577 3.5459 13.9561 3.5459H20.7061C21.0044 3.5459 21.2906 3.66442 21.5015 3.8754C21.7125 4.08638 21.8311 4.37253 21.8311 4.6709V11.4209C21.8311 11.7193 21.7125 12.0054 21.5015 12.2164C21.2906 12.4274 21.0044 12.5459 20.7061 12.5459C20.4077 12.5459 20.1215 12.4274 19.9106 12.2164C19.6996 12.0054 19.5811 11.7193 19.5811 11.4209V7.3874L14.7511 12.2174C14.6472 12.3248 14.523 12.4104 14.3858 12.4693C14.2485 12.5282 14.1008 12.5592 13.9515 12.5604C13.8021 12.5616 13.654 12.5331 13.5157 12.4765C13.3775 12.4199 13.2519 12.3363 13.1464 12.2306C13.0408 12.1249 12.9573 11.9993 12.9008 11.861C12.8443 11.7227 12.8159 11.5745 12.8173 11.4252C12.8186 11.2758 12.8497 11.1282 12.9088 10.9909C12.9678 10.8537 13.0536 10.7296 13.1611 10.6259L17.9911 5.7959H13.9561C13.6577 5.7959 13.3715 5.67737 13.1606 5.46639C12.9496 5.25542 12.8311 4.96927 12.8311 4.6709Z"
                fill="white"
            />
        </svg>
    );
};
