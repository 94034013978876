import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { NavLink } from "./Navbar.styles";

export const NavBar = ({ links }: { links: { path: string; title: string }[] }) => {
    return (
        <Grid
            container
            sx={{ backgroundColor: "background.freshDark" }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            mt={3}
        >
            <Grid item sm={9} xs={12}>
                <Box ml={2} sx={{ py: 3 }}>
                    {links.map(({ path, title }) => (
                        <NavLink key={title} to={path} className={(navData) => (navData.isActive ? "active" : "")}>
                            <Typography>{title}</Typography>
                        </NavLink>
                    ))}
                </Box>
            </Grid>
        </Grid>
    );
};
