/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";

import {
    ItemDeleteSnack,
    ItemEditSnack,
    ItemSaveSnack,
    NewItemButton,
} from "../../../components/list-utils/components";
import { SavedItem } from "components/list-utils/";
import { GiftsContext } from "./Context";
import { CreateEdgeEventContext } from "../CreateEdgeEventProvider";
import { GiftWithId } from "../types";
import { GiftForm } from "./GiftForm";
import { GiftBoxIcon } from "@dono-business/shared/icons";
import { EdgeEventGiftValueType } from "@dono-business/shared/models";

export const GiftsContent = () => {
    const { giftsList } = useContext(CreateEdgeEventContext)!;
    const {
        handleItemDelete: handleDeleteGift,
        handleSelectItemForEdit: handleSelectGiftForEdit,
        handleItemEditSave: handleGiftEditSave,
        handleItemEditCancel: handleGiftEditCancel,
        selectedItem: currentlyEditingGift,
        handleNewItemCancel: handleNewGiftCancel,
        handleNewItemSave: handleNewGiftSave,
        handleItemDeleteUndo: handleGiftDeleteUndo,
        handleNewItemButtonClick: handleNewGiftButtonClick,
        handleDeleteSnackClose,
        handleEditSnackClose,
        handleSaveSnackClose,
        isDeleteSnackShowing,
        isEditSnackShowing,
        isSaveSnackShowing,
        isNewItemFormShowing: isNewGiftFormShowing,
    } = useContext(GiftsContext)!;
    useEffect(() => {
        if (!giftsList.length) handleNewGiftButtonClick();
    }, [giftsList, handleNewGiftButtonClick]);

    return (
        <Grid container>
            <Grid item container xs={12}>
                {!!giftsList.length && (
                    <SavedItem<null>
                        containerSx={{ pointerEvents: "none", marginBottom: 2, pt: "40px" }}
                        item={null}
                        onDeleteButtonClick={() => null}
                        onEditButtonClick={() => null}
                        renderItem={() => (
                            <Grid container pl="6.5px">
                                <Grid
                                    item
                                    xs={"auto"}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mr: "35px",
                                        "& svg": { visibility: "hidden" },
                                    }}
                                >
                                    <GiftBoxIcon />
                                </Grid>
                                <Grid
                                    item
                                    flexShrink={1}
                                    flexBasis={{ xs: "55%", md: "250px", lg: "350px", xl: "450px" }}
                                    alignSelf="center"
                                >
                                    <Typography fontWeight={"600"} lineHeight={1.5}>
                                        Prize(s)
                                    </Typography>
                                </Grid>
                                <Grid item xs alignSelf="center">
                                    <Typography fontWeight={"600"} lineHeight={1.5}>
                                        Quantity
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        renderEditMode={() => null}
                        isEditing={false}
                    />
                )}
                {giftsList?.map((gift) => (
                    <SavedItem<GiftWithId>
                        key={gift.id}
                        item={gift}
                        onDeleteButtonClick={handleDeleteGift}
                        onEditButtonClick={handleSelectGiftForEdit}
                        containerSx={{ "&:hover p": { fontWeight: "700" } }}
                        renderItem={({ value, quantity }) => (
                            <Grid container height={"35px"} pl="6.5px">
                                <Grid
                                    item
                                    xs={"auto"}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mr: "35px",
                                    }}
                                >
                                    <GiftBoxIcon />
                                </Grid>
                                <Grid
                                    item
                                    flexShrink={1}
                                    flexBasis={{ xs: "55%", md: "250px", lg: "350px", xl: "450px" }}
                                    alignSelf="center"
                                >
                                    <Typography>{value}</Typography>
                                </Grid>
                                <Grid item xs alignSelf="center" pl="27px">
                                    <Typography>{quantity}</Typography>
                                </Grid>
                            </Grid>
                        )}
                        renderEditMode={({ value, id, quantity, type }) => (
                            <Box width={1} py={2}>
                                <GiftForm
                                    onSubmit={({ name, quantity }) =>
                                        handleGiftEditSave({ value: name, quantity, id, type })
                                    }
                                    onCancel={handleGiftEditCancel}
                                    defaultValues={{ name: value, quantity }}
                                    nonAllowedGiftNames={giftsList
                                        .map((gift) => gift.value)
                                        .filter((_value) => _value !== value)}
                                />
                            </Box>
                        )}
                        isEditing={currentlyEditingGift?.id === gift.id}
                        disableDelete={isDeleteSnackShowing}
                    />
                ))}
            </Grid>
            {isNewGiftFormShowing && (
                <Grid item width={"100%"} pt={giftsList.length ? "40px" : "52px"}>
                    <GiftForm
                        onSubmit={({ name, quantity }) => {
                            handleNewGiftSave({ value: name, quantity, type: EdgeEventGiftValueType.NonDonoGift });
                        }}
                        onCancel={handleNewGiftCancel}
                        nonAllowedGiftNames={giftsList.map((gift) => gift.value)}
                    />{" "}
                </Grid>
            )}
            <Grid item xs={12} mt={"73px"}>
                <NewItemButton
                    sx={{ width: "196px" }}
                    onClick={handleNewGiftButtonClick}
                    disabled={isNewGiftFormShowing}
                >
                    + Add Prize{" "}
                </NewItemButton>
            </Grid>

            <ItemDeleteSnack
                isShowing={isDeleteSnackShowing}
                handleClose={handleDeleteSnackClose}
                handleUndo={handleGiftDeleteUndo}
                message="Prize removed."
            />
            <ItemEditSnack isShowing={isEditSnackShowing} handleClose={handleEditSnackClose} message="Prize updated." />
            <ItemSaveSnack
                isShowing={isSaveSnackShowing}
                handleClose={handleSaveSnackClose}
                message="Prize successfully added!"
            />
        </Grid>
    );
};
