import { theme } from "./../../../../../../packages/shared/theme/theme";
import { Box, Typography, styled } from "@mui/material";

export const ModalTitle = styled(Typography)({
    fontSize: "16px",
    lineHeight: "22px",
    color: "#434E82",
    fontWeight: 600,
    textAlign: "center",
});

export const ModalSubtitle = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    lineHeight: "22px",
    color: theme.palette.text.fresh,
    fontWeight: 400,
}));

export const ModalCaption = styled(Typography)({
    fontSize: "12px",
    lineHeight: "16px",
    color: "#C2C6DA",
    fontWeight: 500,
});

export const ModalMessage = styled(Typography)({
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#B993C2",
    textAlign: "center",
});

export const ItemContainer = styled(Box)(({ theme }) => ({
    cursor: "pointer",
    boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "5px",
    aspectRatio: "1/1",
    justifyContent: "center",
    width: "100px",
    height: "100px",
}));
export const ItemLabel = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    marginTop: "10px",
    fontWeight: 500,
}));
