import React, { useContext, useEffect } from "react";
import { CreateEdgeEventContext } from "../CreateEdgeEventProvider";
import { ListUtilsProvider } from "components/list-utils";
import { GiftsContent } from "./GiftsContent";
import { GiftsContext } from "./Context";
export const Gifts = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { setGiftsList, giftsList } = useContext(CreateEdgeEventContext)!;
    return (
        <ListUtilsProvider idField={"id"} setItems={setGiftsList} Context={GiftsContext}>
            <GiftsContent />
        </ListUtilsProvider>
    );
};
