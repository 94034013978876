import { alertClasses, Components, Theme } from "@mui/material";
import { lightColors } from "../system/colors";

export const MuiAlert = (theme: Theme): Components["MuiAlert"] => ({
    styleOverrides: {
        root: {
            padding: 8,
            color: theme.palette.primary.main,
        },
        icon: {
            marginTop: 4,
            marginRight: 8,
            marginLeft: 8,
        },
        standardInfo: {
            backgroundColor: theme.palette.primary.light,
            [`.${alertClasses.icon}`]: {
                color: lightColors.blue.main,
            },
        },
        standardWarning: {
            backgroundColor: lightColors.orange.light,
            color: lightColors.orange.main,
            [`.${alertClasses.icon}`]: {
                color: lightColors.orange.main,
            },
        },
        standardError: {
            color: lightColors.red.main,
            [`.${alertClasses.icon}`]: {
                color: lightColors.red.main,
            },
        },
    },
});
