import { PaginatedResponse, BucksBalanceModel, BucksTransactionModel } from "@dono-business/shared/models";
import { AxiosInstance } from "axios";
export const bucksRequests = (baseUrl: string, customAxios: AxiosInstance) => ({
    getUserBucksBalance: () => {
        return customAxios.get<BucksBalanceModel>(`${baseUrl}/bucks/balance`);
    },
    getUserBucksTransactions: (pageNumber: number, pageSize: number) => {
        return customAxios.get<PaginatedResponse<BucksTransactionModel>>(`${baseUrl}/bucks/transactions`, {
            params: { skip: pageNumber * pageSize, limit: pageSize },
        });
    },
});
