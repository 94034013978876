import { StepBackButton, StepContentContainer, Button } from "@dono-business/shared/components";
import { PaymentMethodModel } from "@dono-business/shared/models";
import {
    Box,
    capitalize,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { BillContainer } from "./Payment.styles";
import { CreateEdgeEventContext } from "./CreateEdgeEventProvider";
import { useAppRequests } from "@dono-business/shared/hooks";

export const Payment = () => {
    const { giftRequests } = useAppRequests();

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodModel[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const {
        event,
        rawAmountToPay,
        setActiveStep,
        setSelectedPaymentMethod,
        selectedPaymentMethod,
        bucksBalance,
        shouldUseBucksBalance,
        setShouldUseBucksBalance,
    } = useContext(CreateEdgeEventContext) ?? {};

    const amountToPay = rawAmountToPay ?? 0;
    const isAmountToPayNumber = typeof amountToPay === "number";

    useEffect(() => {
        if (isAmountToPayNumber && amountToPay >= 0) {
            setIsUploading(true);
            giftRequests
                .getPaymentMethods(amountToPay, shouldUseBucksBalance ?? false)
                .then((res) => {
                    setPaymentMethods(res.data);
                    setSelectedPaymentMethod?.(res.data[0]);
                })
                .catch(() => {
                    setPaymentMethods([]);
                    setSelectedPaymentMethod?.(undefined);
                })
                .finally(() => {
                    setIsUploading(false);
                });
        }
    }, [amountToPay, shouldUseBucksBalance]);

    const onPaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const spm = paymentMethods.find((paymentMethod) => paymentMethod.method === event.target.value);
        setSelectedPaymentMethod?.(spm);
    };

    const billDetails = [
        { label: "Gift Subtotal", value: selectedPaymentMethod?.excludeFeeAmount?.value },
        { label: "Payment Method Fee", value: selectedPaymentMethod?.feeAmount?.value },
        {
            label: "DONO Bucks",
            value: selectedPaymentMethod?.bucksAmount?.value ?? 0,
            hidden: !shouldUseBucksBalance,
        },
        { label: "Transaction Fee", value: selectedPaymentMethod?.transactionFeeAmount?.value },
    ];
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { edgeRequests } = useAppRequests();
    const onSubmitButtonClick = useCallback(() => {
        if (!selectedPaymentMethod?.method || !event) return;
        setIsSubmitting(true);
        edgeRequests
            .createStripeCheckoutSession(
                event.Id,
                selectedPaymentMethod.method,
                window.location.origin + "/payment/successful?eventUniqueId=" + event.uniqueId,
                window.location.origin + "/payment/unsuccessful",
                shouldUseBucksBalance ?? false,
            )
            .then((res) => {
                if (res?.data?.amount?.value === 0) {
                    window.location.href =
                        window.location.origin + "/payment/successful?eventUniqueId=" + event.uniqueId;
                } else {
                    if (res?.data?.url) {
                        window.location.href = res.data.url;
                    }
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }, [event, selectedPaymentMethod]);

    const onPrevButtonClick = () => {
        setActiveStep?.(1);
    };
    const onUseBucksToggle = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
        setShouldUseBucksBalance?.(checked);

    const disablePaymentMethods = !selectedPaymentMethod?.includeFeeAmount?.value;
    const hasBucksBalance = !!bucksBalance?.availableBalance?.value;
    const shouldShowBucksOption = !!amountToPay && hasBucksBalance;

    return (
        <StepContentContainer isLoading={isUploading}>
            <Grid container spacing={2}>
                <Grid item xs={12} mb="8px">
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight: "700",
                            color: (theme) => theme.palette.text.primary,
                        }}
                    >
                        Event Payment
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl sx={{ width: "100%" }}>
                        <FormLabel id="payment-methods-radio-button" sx={{ mb: 2 }}>
                            <Typography
                                variant="subTitle"
                                sx={{
                                    color: (theme) => (disablePaymentMethods ? "#707070" : theme.palette.text.primary),
                                }}
                            >
                                Choose payment method:
                            </Typography>
                        </FormLabel>
                        {shouldShowBucksOption && (
                            <FormControlLabel
                                control={<Checkbox checked={shouldUseBucksBalance} onChange={onUseBucksToggle} />}
                                sx={{ mb: 1 }}
                                label={
                                    <Typography
                                        sx={(theme) => ({
                                            color: theme.palette.background.freshLight,
                                            fontWeight: "700",
                                            fontSize: 16,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexGrow: 1,
                                        })}
                                    >
                                        <span>Use DONO Bucks</span>
                                        <span>
                                            {bucksBalance?.availableBalance?.symbol ?? ""}
                                            {bucksBalance?.availableBalance?.value ?? 0}
                                        </span>
                                    </Typography>
                                }
                            />
                        )}
                        {!!paymentMethods.length && (
                            <RadioGroup
                                aria-labelledby="payment-methods-radio-button"
                                value={selectedPaymentMethod?.method}
                                onChange={onPaymentMethodChange}
                                name="radio-buttons-group"
                            >
                                {paymentMethods
                                    ?.filter((pm) => pm?.method !== "applePay" && pm?.method !== "googlePay")
                                    .map((item) => (
                                        <FormControlLabel
                                            checked={
                                                !disablePaymentMethods && item.method === selectedPaymentMethod?.method
                                            }
                                            key={item.method}
                                            value={item.method}
                                            control={<Radio />}
                                            disabled={disablePaymentMethods}
                                            disableTypography
                                            label={
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    flexGrow={1}
                                                    sx={{
                                                        ...(disablePaymentMethods && {
                                                            "&, & *": { color: "#707070" },
                                                        }),
                                                    }}
                                                >
                                                    {capitalize(item.displayName)}
                                                    <Typography
                                                        ml={1}
                                                        variant="label"
                                                        sx={{
                                                            ...(disablePaymentMethods && { color: "#707070" }),
                                                        }}
                                                    >
                                                        {item.percent ? <>(Fee: {item.percent}%)</> : <>(No Fee)</>}
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    ))}
                            </RadioGroup>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <BillContainer
                        flex="1"
                        sx={{
                            ...(disablePaymentMethods && {
                                background: "#eaeaea",
                            }),
                        }}
                    >
                        <Typography
                            variant="subTitle"
                            display={"block"}
                            fontWeight={"700"}
                            mb="24px"
                            color={(theme) => (disablePaymentMethods ? "#707070" : theme.palette.text.primary)}
                        >
                            Payment Summary{" "}
                        </Typography>
                        <Box display={"flex"} justifyContent="space-between">
                            <Box flex={1}>
                                {billDetails.map(
                                    (row) =>
                                        !row.hidden && (
                                            <Box mb={1} key={row.label}>
                                                <Typography
                                                    variant="caption"
                                                    sx={(theme) => ({
                                                        color: disablePaymentMethods
                                                            ? "#707070"
                                                            : row.label === "DONO Bucks"
                                                            ? theme.palette.text.fresh
                                                            : theme.palette.text.primary,
                                                    })}
                                                >
                                                    {row.label}
                                                </Typography>
                                            </Box>
                                        ),
                                )}
                                <Divider sx={{ mt: 3, borderColor: "#CED1E1" }} />
                                <Box mb={1} mt={3}>
                                    <Typography
                                        variant="subTitle"
                                        fontWeight={"700"}
                                        color={(theme) =>
                                            disablePaymentMethods ? "#707070" : theme.palette.text.primary
                                        }
                                    >
                                        Payment Total
                                    </Typography>
                                </Box>
                            </Box>
                            <Box flexBasis="20%">
                                {billDetails.map(
                                    (row) =>
                                        !row.hidden && (
                                            <Box mb={1} key={row.label}>
                                                <Typography
                                                    variant="caption"
                                                    sx={(theme) => ({
                                                        color: disablePaymentMethods
                                                            ? "#707070"
                                                            : row.label === "DONO Bucks"
                                                            ? theme.palette.text.fresh
                                                            : theme.palette.text.primary,
                                                    })}
                                                >
                                                    ${row.value}
                                                </Typography>
                                            </Box>
                                        ),
                                )}
                                <Divider sx={{ mt: 3, borderColor: "#CED1E1" }} />

                                <Box mb={1} mt={3}>
                                    <Typography
                                        variant="subTitle"
                                        fontWeight={"700"}
                                        sx={(theme) => ({
                                            color: disablePaymentMethods ? "#707070" : theme.palette.text.primary,
                                        })}
                                    >
                                        ${selectedPaymentMethod?.includeFeeAmount?.value}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </BillContainer>
                </Grid>
            </Grid>
            <Box mt={8} display="flex" justifyContent="space-between">
                <StepBackButton onClick={onPrevButtonClick} />
                <Button
                    isLoading={isSubmitting}
                    disabled={!selectedPaymentMethod}
                    onClick={onSubmitButtonClick}
                    fullWidth={false}
                    sx={{ display: "flex", alignItems: "center", px: { xs: "25px", sm: "50px" }, py: "13px" }}
                >
                    Submit & Pay
                </Button>
            </Box>
        </StepContentContainer>
    );
};
