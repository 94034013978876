import React, { useState, useEffect } from "react";

interface CountdownProps {
    endDate: string;
    placeholder?: string | null;
}

export const Countdown = ({ endDate, placeholder = " - " }: CountdownProps) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(endDate));
        }, 1000);
        return () => clearInterval(timer);
    }, [endDate]);

    function calculateTimeLeft(endDate: string): { days: number; hours: number; minutes: number; seconds: number } {
        const difference = +new Date(endDate) - +new Date();
        let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    }

    const { days, hours, minutes, seconds } = timeLeft;

    const daysString = days ? `${days}d` : "";
    const hoursString = hours ? `${hours}h` : "";
    return (
        <span>
            {days || hours || minutes || seconds
                ? ` ${daysString} ${hoursString} ${minutes}m ${seconds}s`
                : placeholder}
        </span>
    );
};
