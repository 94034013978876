export enum EdgeEventMissingFields {
    RegistrationStartDate = "RegistrationStartDate",
    RegistrationEndDate = "RegistrationEndDate",
    DrawingDate = "DrawingDate",
    TermsAndCondition = "TermsAndCondition",
    LeadFieldData = "LeadFieldData",
    WinnerMedia = "WinnerMedia",
    LoserMedia = "LoserMedia",
    WinnerMessage = "WinnerMessage",
    LoserMessage = "LoserMessage",
    OnsiteContact = "OnsiteContact",
}
