import { OnsiteContactFormFieldsModel } from "@dono-business/shared/models";
import { useForm } from "react-hook-form";

export const useOnsiteContactForm = ({ defaultValues }: { defaultValues?: OnsiteContactFormFieldsModel }) => {
    const formOptions = useForm<OnsiteContactFormFieldsModel>({
        mode: "all",
        defaultValues: defaultValues ?? { firstName: "", lastName: "", phoneNumber: "" },
    });
    return formOptions;
};
