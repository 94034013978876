import React, { SVGProps } from "react";

 
export const CoinsIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.1248 12.184C22.6496 11.5888 20.5536 11.2016 18.2016 11.2016V9.60156C20.7088 9.60156 23.0144 10.0112 24.7232 10.7008C25.576 11.0464 26.32 11.4752 26.8624 11.9952C27.4064 12.5152 27.8016 13.1792 27.8016 13.9568C27.8016 14.736 27.408 15.3984 26.8624 15.92C26.3184 16.4384 25.576 16.8688 24.7232 17.2128C23.0144 17.904 20.7072 18.312 18.2016 18.312C15.6944 18.312 13.3888 17.904 11.68 17.2128C10.8272 16.8688 10.0832 16.4384 9.54076 15.92C8.99676 15.3984 8.60156 14.736 8.60156 13.9568H10.2016C10.2016 14.16 10.3008 14.432 10.6464 14.7632C10.9936 15.0944 11.5392 15.4304 12.2784 15.7296C13.7552 16.3264 15.848 16.712 18.2016 16.712C20.5536 16.712 22.6496 16.3264 24.1248 15.7296C24.864 15.4304 25.4096 15.0944 25.7568 14.7632C26.1024 14.432 26.2016 14.16 26.2016 13.9568C26.2016 13.7536 26.1024 13.4816 25.7568 13.152C25.4096 12.8192 24.864 12.4816 24.1248 12.184Z"
                fill="#B993C2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.40156 13.8672C9.61374 13.8672 9.81722 13.9515 9.96725 14.1015C10.1173 14.2515 10.2016 14.455 10.2016 14.6672V19.6432C10.2016 19.848 10.3008 20.12 10.6464 20.4496C10.9936 20.7824 11.5392 21.1184 12.2784 21.4176C13.7552 22.0128 15.848 22.4 18.2016 22.4C20.5536 22.4 22.6496 22.0128 24.1248 21.4176C24.864 21.1184 25.4096 20.7824 25.7568 20.4496C26.1024 20.12 26.2016 19.848 26.2016 19.6448V14.6672C26.2016 14.455 26.2858 14.2515 26.4359 14.1015C26.5859 13.9515 26.7894 13.8672 27.0016 13.8672C27.2137 13.8672 27.4172 13.9515 27.5672 14.1015C27.7173 14.2515 27.8016 14.455 27.8016 14.6672V19.6432C27.8016 20.424 27.408 21.0864 26.8624 21.6064C26.3184 22.1264 25.576 22.5552 24.7232 22.9008C23.0144 23.5904 20.7072 24 18.2016 24C15.6944 24 13.3888 23.5904 11.68 22.9008C10.8272 22.5568 10.0832 22.1264 9.54076 21.6064C8.99676 21.0864 8.60156 20.4224 8.60156 19.6448V14.6672C8.60156 14.455 8.68585 14.2515 8.83588 14.1015C8.98591 13.9515 9.18939 13.8672 9.40156 13.8672Z"
                fill="#B993C2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9216 22.7616V16.8H14.5216V22.7616H12.9216ZM19.9616 22.4V17.6H21.5616V22.4H19.9616ZM2.64636 3.5504C2.30076 3.88 2.20156 4.152 2.20156 4.3552C2.20156 4.5584 2.30076 4.8304 2.64636 5.1616C2.99356 5.4928 3.53916 5.8288 4.27836 6.128C5.75516 6.7248 7.84796 7.1104 10.2016 7.1104C12.5536 7.1104 14.6496 6.7248 16.1248 6.128C16.864 5.8288 17.4096 5.4928 17.7568 5.1616C18.1024 4.8304 18.2016 4.5584 18.2016 4.3552C18.2016 4.152 18.1024 3.88 17.7568 3.5504C17.4096 3.2176 16.864 2.8816 16.1248 2.5824C14.648 1.9872 12.5536 1.6 10.2016 1.6C7.84956 1.6 5.75356 1.9872 4.27836 2.5824C3.53916 2.8816 2.99356 3.2176 2.64636 3.5504ZM3.67996 1.0992C5.38876 0.4096 7.69596 0 10.2016 0C12.7088 0 15.0144 0.4096 16.7232 1.0992C17.576 1.4448 18.32 1.8736 18.8624 2.3936C19.4064 2.9136 19.8016 3.5776 19.8016 4.3552C19.8016 5.1344 19.408 5.7968 18.8624 6.3184C18.3184 6.8368 17.576 7.2672 16.7232 7.6112C15.0144 8.3024 12.7072 8.7104 10.2016 8.7104C7.69436 8.7104 5.38876 8.3024 3.67996 7.6112C2.82716 7.2672 2.08316 6.8368 1.54076 6.3184C0.996763 5.7968 0.601562 5.1344 0.601562 4.3552C0.601562 3.5776 0.995163 2.9152 1.54076 2.3936C2.08476 1.8736 2.82716 1.4448 3.67996 1.0992Z"
                fill="#B993C2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.40156 4.26562C1.61374 4.26562 1.81722 4.34991 1.96725 4.49994C2.11728 4.64997 2.20156 4.85345 2.20156 5.06563V10.0416C2.20156 10.2464 2.30076 10.5184 2.64636 10.848C2.99356 11.1808 3.53916 11.5168 4.27836 11.816C5.75516 12.4112 7.84796 12.7984 10.2016 12.7984C12.5536 12.7984 14.6496 12.4112 16.1248 11.816C16.864 11.5168 17.4096 11.1808 17.7568 10.848C18.1024 10.5184 18.2016 10.2464 18.2016 10.0432V5.06563C18.2016 4.85345 18.2858 4.64997 18.4359 4.49994C18.5859 4.34991 18.7894 4.26562 19.0016 4.26562C19.2137 4.26562 19.4172 4.34991 19.5672 4.49994C19.7173 4.64997 19.8016 4.85345 19.8016 5.06563V10.0416C19.8016 10.8224 19.408 11.4848 18.8624 12.0048C18.3184 12.5248 17.576 12.9552 16.7232 13.2992C15.0144 13.9888 12.7072 14.3984 10.2016 14.3984C7.69436 14.3984 5.38876 13.9888 3.67996 13.2992C2.82716 12.9536 2.08316 12.5248 1.54076 12.0048C0.996762 11.4864 0.601562 10.8208 0.601562 10.0432V5.06563C0.601562 4.85345 0.685848 4.64997 0.835877 4.49994C0.985906 4.34991 1.18939 4.26562 1.40156 4.26562Z"
                fill="#B993C2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.92188 12.7942V7.375H6.52187V12.7942H4.92188ZM11.9619 12.7942V7.9942H13.5619V12.7942H11.9619Z"
                fill="#B993C2"
            />
        </svg>
    );
};
