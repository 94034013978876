import React from "react";

export const EventCopyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 23 22" fill="none">
            <path
                d="M12.3644 12.833H14.1978V10.083H16.9478V8.24967H14.1978V5.49967H12.3644V8.24967H9.61442V10.083H12.3644V12.833Z"
                fill="white"
            />
            <path
                d="M18.7809 1.83333H7.78092C6.76984 1.83333 5.94759 2.65558 5.94759 3.66667V14.6667C5.94759 15.6778 6.76984 16.5 7.78092 16.5H18.7809C19.792 16.5 20.6143 15.6778 20.6143 14.6667V3.66667C20.6143 2.65558 19.792 1.83333 18.7809 1.83333ZM18.7809 14.6667H7.78092L7.77909 3.66667H18.7809V14.6667Z"
                fill="white"
            />
            <path
                d="M4.11426 20.167H15.1143V18.3337H4.11426V7.33366H2.28092V18.3337C2.28092 19.3447 3.10318 20.167 4.11426 20.167Z"
                fill="white"
            />
        </svg>
    );
};
