import { GiftFormFieldsModel, RegistrationFieldFormFieldsModel } from "@dono-business/shared/models/";
import { useForm } from "react-hook-form";

export const useGiftsForm = ({ defaultValues }: { defaultValues?: GiftFormFieldsModel }) => {
    const formOptions = useForm<GiftFormFieldsModel>({
        mode: "all",
        defaultValues: defaultValues ?? { name: "", quantity: "" },
    });
    return formOptions;
};
