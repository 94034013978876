import {
    EdgeEventGiftValueType,
    EdgeEventPrizeData,
    EventLeadFieldData,
    MediaFileData,
} from "@dono-business/shared/models";
import { EdgeLogoContentModel } from "@dono-business/shared/models/edge-logo-content-model";
import { GiftContentModel } from "@dono-business/shared/models/gift-content-model";
import { GiftWithId, RegistrationField } from "./types";
import { convertContentToMediaFileData } from "@dono-business/shared/utils/other";
import { uuidv4 } from "@firebase/util";

export const convertGiftMediaContentToMediaFileData = (fileName: string, giftMediaContent?: GiftContentModel) =>
    convertContentToMediaFileData(fileName, {
        id: giftMediaContent?.giftContentId,
        type: giftMediaContent?.giftContentType,
        value: giftMediaContent?.giftContentValue,
    });
export const convertEdgeEventMediaContentToMediaFileData = (
    fileName: string,
    edgeEventMediaContent?: EdgeLogoContentModel,
): MediaFileData | undefined =>
    convertContentToMediaFileData(fileName, {
        id: edgeEventMediaContent?.logoContentId,
        type: edgeEventMediaContent?.logoContentType,
        value: edgeEventMediaContent?.logoContentValue,
    });

export const convertRegistrationFieldsToLeadFieldData: (
    registrationFields: RegistrationField[],
) => EventLeadFieldData[] = (registrationFields) =>
    registrationFields.map((field) => ({ name: field.name, required: true }));

export const convertEdgeEventPrizeDataToGift = (edgeEventPrizeData: EdgeEventPrizeData): GiftWithId[] => {
    const converted =
        edgeEventPrizeData.map((prize) =>
            prize.type === EdgeEventGiftValueType.DonoGift
                ? prize.amount
                    ? {
                          value: prize.amount.value.toString(),
                          quantity: prize.quantity.toString(),
                          type: prize.type,
                          id: uuidv4(),
                      }
                    : null
                : {
                      value: prize.name,
                      quantity: prize.quantity.toString(),
                      type: prize.type,
                      id: uuidv4(),
                  },
        ) ?? [];
    const result = converted.filter((prize) => prize !== null) as GiftWithId[];
    return result;
};
export const calculateAmountByGifts = (gifts: GiftWithId[]): number =>
    gifts.reduce(
        (prev, curr) => (curr.type === EdgeEventGiftValueType.DonoGift ? (prev += +curr.quantity * +curr.value) : prev),
        0,
    );

export const modifyTotalValueErrorMessage = "The total value of gifts should be equal with the previous total.";
