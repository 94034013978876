import React, { useEffect } from "react";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useNavigate } from "react-router";
import Logo from "@dono-business/shared/images/logo.svg";

import { login } from "@dono-business/shared/utils";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import toast from "react-simple-toasts";
import { LoginContainer, LogoContainer } from "./LoginScreen.styles";
import { useAppRequests } from "@dono-business/shared/hooks/";
export const EmailLinkLogin = () => {
    const auth = getAuth();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const emailParam = searchParams.get("email");
    const accessTokenParam = searchParams.get("accessToken");

    const isSignInByRedirection = emailParam && accessTokenParam;
    const { authRequests } = useAppRequests();
    useEffect(() => {
        const signIn = async () => {
            try {
                let email = "",
                    accessToken = "";

                if (isSignInByRedirection) {
                    email = emailParam;
                    accessToken = accessTokenParam;
                } else if (isSignInWithEmailLink(auth, window.location.href)) {
                    const emailFromLocalStorage = window?.localStorage?.getItem("emailForSignIn") || "";
                    const result = await signInWithEmailLink(auth, emailFromLocalStorage, window.location.href);
                    ({ email, accessToken } = result.user as never);
                    window.localStorage.removeItem("emailForSignIn");
                }

                const response = await authRequests.emailLogin(email, accessToken);
                const { id, phone, xtoken } = response.data;

                login(id, phone, xtoken, email);

                navigate("/");
            } catch (error: any) {
                toast(error.message);

                navigate("/login");
            }
        };
        signIn();
    });

    return (
        <LoginContainer>
            <LogoContainer>
                <img src={Logo} alt="DONO" />
            </LogoContainer>
            <Box display="flex" alignItems="center" justifyContent={"center"}>
                <CircularProgress size={20} color="secondary" />
                <Typography component={"div"} ml={2}>
                    Logging you in...
                </Typography>
            </Box>
        </LoginContainer>
    );
};
