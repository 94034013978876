import { Countdown } from "@dono-business/shared/components";
import { EdgeEventModel } from "@dono-business/shared/models";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { MdOutlineHourglassFull } from "react-icons/md";
import dayjs from "dayjs";
import { ModalCaption, ModalSubtitle, ModalTitle } from "./styles";
import { genericDateAndTimeFormatter } from "@dono-business/shared/utils";

interface TimeModalContentProps {
    event?: EdgeEventModel;
}
export const TimeModalContent = ({ event }: TimeModalContentProps) => {
    const hasRegistrationEnded = dayjs(event?.registrationEndDate).isBefore(dayjs());

    return (
        <Box pl={2}>
            <ModalTitle display="block">{event?.name} Raffle Time</ModalTitle>
            <ModalSubtitle display="block" mt={3}>
                Registration Time
                <Box ml={1}>
                    <ModalCaption display="block" mt={1}>
                        From:{" "}
                        {event?.registrationStartDate && genericDateAndTimeFormatter(event?.registrationStartDate)}
                    </ModalCaption>
                    <ModalCaption display="block" mt={0.5}>
                        To: {event?.registrationEndDate && genericDateAndTimeFormatter(event?.registrationEndDate)}
                    </ModalCaption>
                    <Typography variant="link" display="flex" alignItems="center" mt={1} flexWrap="wrap">
                        <MdOutlineHourglassFull style={{ marginRight: "4px", marginLeft: "-3px" }} />
                        {event?.status === "ended" ? (
                            "Ended"
                        ) : hasRegistrationEnded ? (
                            "Registration period is over."
                        ) : (
                            <>
                                <Box display="inline" mr={1}>
                                    Registration time remaining:
                                </Box>
                                {event?.registrationEndDate && <Countdown endDate={event?.registrationEndDate} />}
                            </>
                        )}
                    </Typography>
                </Box>
            </ModalSubtitle>
            <ModalSubtitle display="block" mt={2} mb={5}>
                Draw Time
                <Box ml={1}>
                    <ModalCaption display="block" mt={1}>
                        At: {event?.drawingDate && genericDateAndTimeFormatter(event?.drawingDate)}
                    </ModalCaption>
                </Box>
            </ModalSubtitle>
        </Box>
    );
};
