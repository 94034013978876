import { RegistrationFieldFormFieldsModel } from "@dono-business/shared/models";
import React from "react";
import { useRegistrationFieldsForm } from "./useRegistrationFieldsForm";
import { FormControl, Grid, TextField, capitalize } from "@mui/material";
import { Controller } from "react-hook-form";
import { validateUSPhoneNumber } from "@dono-business/shared/utils";
import {
    FormButtonsContainer,
    FormGridContainer,
    FormTitle,
    SaveButton,
    SmallButton,
    UnsavedItemDialog,
    useUnsavedItem,
} from "../../../components/list-utils";

type Props = {
    mode: "add" | "edit";
    defaultValues?: RegistrationFieldFormFieldsModel;
    onSubmit: (values: RegistrationFieldFormFieldsModel) => void;
    onCancel: () => void;
    nonAllowedFieldNames: string[];
};

export const RegistrationFieldsForm = ({ defaultValues, onSubmit, onCancel, nonAllowedFieldNames, mode }: Props) => {
    const {
        control,
        handleSubmit,
        formState: { isValid },
        trigger,
    } = useRegistrationFieldsForm({ defaultValues });
    const handleFormSubmit = (data: RegistrationFieldFormFieldsModel) => {
        onSubmit(data);
    };
    const handleCancelButtonClick = () => {
        onCancel();
    };
    const isFieldAllowed = (name: string) =>
        nonAllowedFieldNames.find((_name) => _name.toLowerCase() === name.toLowerCase()) === undefined;
    const { handleFormBlur, handleFormClick, unsavedDialogOpen, toggleUnsavedDialogOpen } = useUnsavedItem({
        isFormValid: async () => await trigger(),
    });
    return (
        <>
            <FormGridContainer
                onSubmit={handleSubmit(handleFormSubmit)}
                onClickAway={handleFormBlur}
                onClick={handleFormClick}
            >
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: "35px",
                    }}
                >
                    <FormTitle>{capitalize(mode)} Custom Field</FormTitle>
                </Grid>

                <Grid item xs={12} mb="24px">
                    {" "}
                    <FormControl fullWidth>
                        <Controller
                            control={control}
                            name={"name"}
                            rules={{
                                required: "Field name is mandatory",
                                validate: {
                                    isAllowed: (value) => isFieldAllowed(value) || "This field name is already added",
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    placeholder="Enter Field Name"
                                    label="Data Field"
                                    autoComplete="off"
                                    error={Boolean(error)}
                                    helperText={error?.message ?? ""}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <FormButtonsContainer>
                    <SmallButton variant="outlined" onClick={handleCancelButtonClick}>
                        Cancel
                    </SmallButton>
                    <SaveButton variant="contained" type="submit" disabled={!isValid}>
                        Save
                    </SaveButton>
                </FormButtonsContainer>
            </FormGridContainer>
            <UnsavedItemDialog
                title={"Unsaved Field"}
                isOpen={unsavedDialogOpen}
                onClose={() => toggleUnsavedDialogOpen(false)}
                // eslint-disable-next-line quotes
                text={'In order to save the field info, please click on the "Save" button.'}
            />
        </>
    );
};
