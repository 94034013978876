import React, { SVGProps } from "react";
export const StepThreeEdgeGrayIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="119" height="120" viewBox="0 0 119 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <g filter="url(#filter0_d_11_1237)">
                    <path
                        d="M91 14.0039H28C21.3726 14.0039 16 19.3765 16 26.0039V89.0039C16 95.6313 21.3726 101.004 28 101.004H91C97.6274 101.004 103 95.6313 103 89.0039V26.0039C103 19.3765 97.6274 14.0039 91 14.0039Z"
                        fill="url(#paint0_linear_11_1237)"
                    />
                    <path
                        d="M91 14.5039H28C21.6487 14.5039 16.5 19.6526 16.5 26.0039V89.0039C16.5 95.3552 21.6487 100.504 28 100.504H91C97.3513 100.504 102.5 95.3552 102.5 89.0039V26.0039C102.5 19.6526 97.3513 14.5039 91 14.5039Z"
                        stroke="white"
                        strokeOpacity="0.01"
                    />
                </g>
            </g>
            <path
                d="M43.484 54.12H45.592C46.952 54.12 48.2667 54.0067 49.536 53.78C50.8507 53.508 52.0067 53.0773 53.004 52.488C54.0013 51.8533 54.7947 51.0147 55.384 49.972C56.0187 48.9293 56.336 47.6373 56.336 46.096C56.336 44.872 56.1093 43.784 55.656 42.832C55.2027 41.8347 54.568 40.996 53.752 40.316C52.9813 39.636 52.0747 39.1147 51.032 38.752C50.0347 38.344 48.9693 38.14 47.836 38.14C45.796 38.14 44.0507 38.6613 42.6 39.704C41.1947 40.7467 40.1293 42.1973 39.404 44.056L33.42 41.948C34.5987 39.0467 36.48 36.78 39.064 35.148C41.6933 33.4707 44.708 32.632 48.108 32.632C50.1027 32.632 51.984 32.9267 53.752 33.516C55.5653 34.06 57.152 34.876 58.512 35.964C59.872 37.052 60.9373 38.412 61.708 40.044C62.524 41.676 62.932 43.5573 62.932 45.688C62.932 47.048 62.7053 48.3173 62.252 49.496C61.844 50.6747 61.2547 51.74 60.484 52.692C59.7587 53.644 58.8747 54.46 57.832 55.14C56.7893 55.82 55.656 56.3187 54.432 56.636V56.772C55.8373 57.044 57.1293 57.52 58.308 58.2C59.532 58.88 60.5747 59.7413 61.436 60.784C62.2973 61.7813 62.9773 62.9373 63.476 64.252C63.9747 65.5667 64.224 66.9947 64.224 68.536C64.224 70.9387 63.7707 73.0693 62.864 74.928C61.9573 76.7413 60.756 78.26 59.26 79.484C57.764 80.708 56.0413 81.6373 54.092 82.272C52.1427 82.9067 50.148 83.224 48.108 83.224C44.3 83.224 40.9453 82.3627 38.044 80.64C35.1427 78.9173 33.0347 76.2427 31.72 72.616L37.84 70.576C38.5653 72.6613 39.7893 74.3613 41.512 75.676C43.28 76.9907 45.4333 77.648 47.972 77.648C49.196 77.648 50.3747 77.4667 51.508 77.104C52.6867 76.696 53.7293 76.1067 54.636 75.336C55.5427 74.5653 56.268 73.6133 56.812 72.48C57.356 71.3467 57.628 70.0093 57.628 68.468C57.628 66.7907 57.2653 65.3853 56.54 64.252C55.8147 63.1187 54.8627 62.212 53.684 61.532C52.5507 60.8067 51.2587 60.308 49.808 60.036C48.3573 59.7187 46.9293 59.56 45.524 59.56H43.484V54.12Z"
                fill="#0C2145"
            />
            <path
                d="M67.6161 88.8688L53.7241 74.9778C52.8891 74.1428 51.3751 71.1568 53.7241 68.9328C54.5467 68.1719 55.626 67.7492 56.7466 67.7492C57.8671 67.7492 58.9465 68.1719 59.7691 68.9328L69.1271 78.2908L89.1711 58.2468C90.0061 57.4118 93.1331 56.2618 95.2161 58.2468C97.2991 60.2318 96.0511 63.4568 95.2161 64.2918L70.6381 88.8688C70.2373 89.2695 69.6938 89.4946 69.1271 89.4946C68.5604 89.4946 68.0168 89.2695 67.6161 88.8688Z"
                fill="#D1D5DE"
                stroke="white"
            />
            <path
                d="M81.5985 75.175L83.5982 73.1753C83.7358 73.0376 83.8132 72.8509 83.8132 72.6562C83.8132 72.4616 83.7358 72.2749 83.5982 72.1372C83.4605 71.9996 83.2738 71.9223 83.0792 71.9223C82.8845 71.9223 82.6978 71.9996 82.5601 72.1372L80.5604 74.1369C80.4228 74.2746 80.3455 74.4613 80.3455 74.6559C80.3455 74.8506 80.4228 75.0373 80.5604 75.175C80.6981 75.3126 80.8848 75.3899 81.0795 75.3899C81.2741 75.3899 81.4608 75.3126 81.5985 75.175V75.175Z"
                fill="white"
            />
            <path
                d="M72.0754 84.6981L78.981 77.7925C79.1186 77.6548 79.196 77.4681 79.196 77.2734C79.196 77.0788 79.1186 76.8921 78.981 76.7544C78.8433 76.6168 78.6566 76.5394 78.462 76.5394C78.2673 76.5394 78.0806 76.6168 77.943 76.7544L71.0373 83.66C70.8997 83.7977 70.8224 83.9844 70.8224 84.179C70.8224 84.3737 70.8997 84.5604 71.0373 84.6981C71.175 84.8357 71.3617 84.913 71.5564 84.913C71.751 84.913 71.9377 84.8357 72.0754 84.6981V84.6981Z"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_d_11_1237"
                    x="0"
                    y="0.00390625"
                    width="119"
                    height="119"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.239216 0 0 0 0 0.121569 0 0 0 0 0.262745 0 0 0 0.2 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11_1237" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11_1237" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_11_1237"
                    x1="94.3"
                    y1="-13.8361"
                    x2="24.613"
                    y2="103.266"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C6DA" />
                    <stop offset="1" stopColor="#0C2145" />
                </linearGradient>
            </defs>
        </svg>
    );
};
