import { useSmallScreenSize } from "@dono-business/shared/hooks";
import {
    StepOneIcon,
    StepThreeEdgeColoredIcon,
    StepThreeEdgeGrayIcon,
    StepTwoEdgeIcon,
    StepTwoEdgeColoredIcon,
} from "@dono-business/shared/icons";
import { Step, StepContentProps, Stepper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { CreateEdgeEventContext } from "./CreateEdgeEventProvider";

import { Label, StyledStepConnector, StyledStepContent, StyledStepLabel } from "./CreateEdgeEventScreen.styles";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";

export const Steps = () => {
    const isSmallScreen = useSmallScreenSize();
    const { activeStep, mode } = useContext(CreateEdgeEventContext) ?? {};
    const isActiveStepNumber = typeof activeStep === "number";
    const scrollToTopOfStep = (element: HTMLElement) =>
        element.closest(".MuiStep-root")?.scrollIntoView({ behavior: "smooth" });
    const stepContentProps: StepContentProps = {
        transitionDuration: 0,
        TransitionProps: {
            unmountOnExit: false,
            onEntered: scrollToTopOfStep,
        },
    };
    return (
        <>
            {isSmallScreen && (
                <Typography variant="screenTitle">{mode === "modify" ? "Edit" : "Create"} Event</Typography>
            )}

            {mode === "create" || mode === "copy" ? (
                <Stepper activeStep={activeStep} orientation="vertical" connector={<StyledStepConnector />}>
                    <Step>
                        <StyledStepLabel icon={<StepOneIcon />}>
                            <Label>Register Edge Event</Label>
                        </StyledStepLabel>

                        <StyledStepContent {...stepContentProps}>
                            <Step1 />
                        </StyledStepContent>
                    </Step>
                    <Step>
                        <StyledStepLabel
                            icon={
                                isActiveStepNumber && activeStep < 1 ? <StepTwoEdgeIcon /> : <StepTwoEdgeColoredIcon />
                            }
                        >
                            <Label>Customize Your Giveaway</Label>
                        </StyledStepLabel>
                        <StyledStepContent {...stepContentProps}>
                            <Step2 />
                        </StyledStepContent>
                    </Step>

                    <Step>
                        <StyledStepLabel
                            icon={
                                isActiveStepNumber && activeStep < 2 ? (
                                    <StepThreeEdgeGrayIcon />
                                ) : (
                                    <StepThreeEdgeColoredIcon />
                                )
                            }
                        >
                            <Label>Review & Pay</Label>
                        </StyledStepLabel>
                        <StyledStepContent {...stepContentProps}>
                            <Step3 />
                        </StyledStepContent>
                    </Step>
                </Stepper>
            ) : (
                <Stepper activeStep={activeStep} orientation="vertical" connector={<StyledStepConnector />}>
                    <Step>
                        <StyledStepLabel icon={<StepOneIcon />}>
                            <Label>Register Edge Event</Label>
                        </StyledStepLabel>

                        <StyledStepContent {...stepContentProps}>
                            <Step1 />
                        </StyledStepContent>
                    </Step>
                    <Step>
                        <StyledStepLabel
                            icon={
                                isActiveStepNumber && activeStep < 1 ? <StepTwoEdgeIcon /> : <StepTwoEdgeColoredIcon />
                            }
                        >
                            <Label>Customize Your Giveaway</Label>
                        </StyledStepLabel>
                        <StyledStepContent {...stepContentProps}>
                            <Step2 />
                        </StyledStepContent>
                    </Step>
                </Stepper>
            )}
        </>
    );
};
