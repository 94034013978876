import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { ConfigTitle } from "./styles";
import { Gifts } from "./Gifts/";
import { CreateEdgeEventContext } from "./CreateEdgeEventProvider";

export const GiftsListConfig = () => {
    const { giftsListError } = useContext(CreateEdgeEventContext) ?? {};
    return (
        <Box>
            <ConfigTitle mb={2} display="block">
                Giveaway Prizes
            </ConfigTitle>
            <Typography display="block">
                List your prizes. You must list at least one prize. Once the drawing starts, you won’t be able to add or
                edit prizes.
            </Typography>
            <Gifts />
            {giftsListError && (
                <Typography mt={3} color="error" display="inline-block">
                    {giftsListError}
                </Typography>
            )}
        </Box>
    );
};
