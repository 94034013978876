import { TypographyOptions } from "@mui/material/styles/createTypography";
import { CSSProperties } from "react";
import { lightColors } from "./colors";

declare module "@mui/material/styles" {
    export interface TypographyVariants {
        title?: CSSProperties;
        subTitle?: CSSProperties;
        link?: CSSProperties;
        label?: CSSProperties;
        boldLabel?: CSSProperties;
        screenTitle?: CSSProperties;
        sectionTitle?: CSSProperties;
    }

    export interface TypographyVariantsOptions {
        title?: CSSProperties;
        subTitle?: CSSProperties;
        link?: CSSProperties;
        label?: CSSProperties;
        boldLabel?: CSSProperties;
        screenTitle?: CSSProperties;
        sectionTitle?: CSSProperties;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        title: true;
        subTitle: true;
        link: true;
        label: true;
        boldLabel: true;
        screenTitle: true;
        sectionTitle: true;
    }
}
export const fontFamily = "Roboto";

const typographyOption: TypographyOptions = {
    fontFamily,
    allVariants: {
        display: "inline",
    },
    title: {
        fontFamily,
        fontWeight: 600,
        fontSize: 32,
        color: lightColors.neutral.grey.darkest,
    },
    subTitle: {
        fontFamily,
        fontWeight: 600,
        fontSize: 16,
        color: lightColors.blue.light,
    },
    caption: {
        fontFamily,
        fontWeight: 400,
        fontSize: 16,
        color: lightColors.blue.main,
    },
    h3: {
        fontFamily,
        fontWeight: 600,
        fontSize: 24,
        color: lightColors.blue.main,
    },
    link: {
        fontFamily,
        fontWeight: 500,
        fontSize: 14,
        color: lightColors.purple.main,
    },
    label: {
        fontFamily,
        fontWeight: 400,
        fontSize: 14,
        color: lightColors.purple.cloudy,
    },
    boldLabel: { fontSize: 20, fontWeight: "700", color: lightColors.blue.main },
    screenTitle: {
        fontSize: 18,
        fontWeight: 600,
        color: lightColors.blue.main,
    },
    sectionTitle: { fontSize: 24, fontWeight: "700", color: lightColors.blue.main },
};

export const typography = typographyOption;
