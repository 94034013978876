import { Grid } from "@mui/material";
import React from "react";
import { Outlet } from "react-router";

export const LayoutBody = () => {
    return (
        <Grid container columns={24} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={22} sm={21} lg={18} mt={5} mb={6}>
                <Outlet />
            </Grid>
        </Grid>
    );
};
