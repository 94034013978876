import React, { SVGProps } from "react";

export const EventModifyIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 25 25" fill="none" {...props}>
            <path
                d="M12.4653 3.57178H5.46533C4.9349 3.57178 4.42619 3.78249 4.05112 4.15756C3.67605 4.53264 3.46533 5.04134 3.46533 5.57178V19.5718C3.46533 20.1022 3.67605 20.6109 4.05112 20.986C4.42619 21.3611 4.9349 21.5718 5.46533 21.5718H19.4653C19.9958 21.5718 20.5045 21.3611 20.8795 20.986C21.2546 20.6109 21.4653 20.1022 21.4653 19.5718V12.5718"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.8403 3.197C19.2382 2.79918 19.7777 2.57568 20.3403 2.57568C20.9029 2.57568 21.4425 2.79918 21.8403 3.197C22.2382 3.59483 22.4617 4.13439 22.4617 4.697C22.4617 5.25961 22.2382 5.79918 21.8403 6.197L12.4653 15.572L8.46533 16.572L9.46533 12.572L18.8403 3.197Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
