import { Divider } from "@mui/material";
import React from "react";
import { Payment } from "./Payment";
import { Review } from "./Review";

export const Step3 = () => {
    return (
        <>
            <Review />
            <Divider sx={{ my: "47px" }} />
            <Payment />
        </>
    );
};
