/* eslint-disable quotes */
import { ExportToCsv } from "export-to-csv-fix-source-map";
import { fileSave } from "browser-fs-access";
import { flatten } from "./export-helper";

const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
};

export const downloadCSVFile = async (dataObjToWrite: object[] | undefined, filename: string) => {
    if (dataObjToWrite) {
        const csvExporter = new ExportToCsv(options);
        const flatData = dataObjToWrite.map((item) => flatten(item));
        const file = csvExporter.generateCsv(flatData, true).replaceAll("undefined", "-");
        const blob = new Blob([file], {
            type: "text/csv",
        });

        await fileSave(blob, {
            fileName: filename,
            extensions: [".csv"],
        });
    }
};
