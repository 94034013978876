import axios from "axios";
export const authRequests = (baseUrl: string) => ({
    login: async (phone: string, firebaseToken: string) => {
        const body = {
            phone: phone,
        };

        return axios.post(baseUrl + "/user", body, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "firebase-default-token": firebaseToken,
            },
        });
    },

    emailLogin: async (email: string, firebaseToken: string) => {
        return axios.post(
            baseUrl + "/user",
            { email },
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "firebase-default-token": firebaseToken,
                },
            },
        );
    },
});
