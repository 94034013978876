import React from "react";

export const CheckCircleIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.1636" cy="10.4971" r="9" stroke="#B993C2" strokeWidth="2" />
            <path
                d="M6.12252 11.7119L6.29208 11.5282L6.12252 11.7119L8.22215 13.65C8.51945 13.9244 8.98191 13.906 9.25655 13.6089L14.1657 8.29807C14.2975 8.15552 14.3672 7.96647 14.3596 7.77249C14.3519 7.57851 14.2674 7.39557 14.1247 7.26391C13.9821 7.13225 13.793 7.06263 13.599 7.07033C13.4051 7.07802 13.2222 7.16235 13.0904 7.30484C13.0904 7.30486 13.0904 7.30488 13.0904 7.3049C13.0903 7.30491 13.0903 7.30492 13.0903 7.30493L8.67542 12.0762L7.1159 10.6367C6.97326 10.5051 6.78416 10.4355 6.59021 10.4431C6.39626 10.4508 6.21326 10.5352 6.0815 10.6778C5.94973 10.8203 5.87999 11.0094 5.88769 11.2033C5.8915 11.2994 5.9142 11.3937 5.9545 11.481C5.99479 11.5683 6.05189 11.6467 6.12252 11.7119Z"
                fill="#B993C2"
                stroke="#B993C2"
                strokeWidth="0.5"
            />
        </svg>
    );
};
