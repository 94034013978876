import { styled } from "@mui/material/styles";
import BgImage from "@dono-business/shared/images/login-bg.svg";

export const LoginContainer = styled("div")({
    backgroundImage: `url(${BgImage})`,
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "1300px",
    backgroundPosition: "center bottom",
    textAlign: "center",
});

export const LogoContainer = styled("div")({
    padding: "60px 0 67px",
    "& img": {
        width: "110px",
        height: "auto",
    },
});
