import { CustomDataGrid } from "@dono-business/shared/components";
import { CoinsIcon } from "@dono-business/shared/icons";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { useBucksTransactions } from "./useBucksTransactions";
import { useBucksTransactionsGridColumns } from "./useBucksTransactionsGridColumns";
import { uuidv4 } from "@firebase/util";
import { useBucksBalance } from "./useBucksBalance";
import { formatNumberToCurrency } from "@dono-business/shared/utils";
import { BucksBalanceModel, BucksTransactionModel } from "packages/shared/models";

export const BucksScreen = () => {
    const isUser = true;
    const bucksTransactionsColumns = useBucksTransactionsGridColumns();
    const { bucksTransactions, loading, pageNumber, setPageNumber, pageSize, totalItems } = useBucksTransactions();
    const { bucksBalance } = useBucksBalance();

    return (
        <Grid container>
            <Grid xs={12} item mb={4}>
                <Typography variant="sectionTitle">DONO Bucks</Typography>{" "}
            </Grid>
            <Grid
                xs={12}
                item
                container
                padding={"24px 35px"}
                justifyContent="space-between"
                border={"1px solid #B993C299"}
                borderRadius={"8px"}
                gap=".6rem"
                mb={isUser ? 7.5 : 2.5}
            >
                <Grid item xs={"auto"} display="flex" alignItems={"center"} gap={1}>
                    <CoinsIcon />
                    <Typography variant="boldLabel">Total Balance </Typography>
                    <Typography variant="boldLabel">
                        {formatNumberToCurrency(bucksBalance?.availableBalance?.value ?? 0)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid xs={12} item mb={5}>
                <Typography variant="sectionTitle">DONO Bucks History</Typography>{" "}
            </Grid>
            <Grid xs={12} item>
                <CustomDataGrid
                    getRowId={(r) => r.id}
                    rows={bucksTransactions.map((p) => ({ ...p, id: uuidv4() }))}
                    columns={bucksTransactionsColumns}
                    paginationMode="server"
                    paginationModel={{ page: pageNumber, pageSize }}
                    onPaginationModelChange={({ page }) => setPageNumber(page)}
                    autoHeight
                    rowCount={totalItems}
                    disableRowSelectionOnClick
                    loading={loading}
                />
            </Grid>
            {/* <Box
                sx={{
                    borderRadius: "8px",
                    border: "1px dashed rgba(185, 147, 194, 0.60)",
                    height: "110px",
                    px: "35px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box display={"flex"} alignItems="center" gap={"67.5px"}>
                    <BoldText whiteSpace={"nowrap"}>Add DONO Bucks</BoldText>
                    <TextField
                        label={"Amount"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography sx={{ color: "#707070", fontSize: "14px" }}>$</Typography>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Button sx={{ padding: "13px 80px", width: "auto" }}>Pay</Button>
            </Box> */}
        </Grid>
    );
};
