import { ButtonProps } from "@mui/material";
import React from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { WideButtonWithIcon } from "./styles";

export const DownloadButton = (props: ButtonProps) => {
    return (
        <WideButtonWithIcon {...props}>
            <MdOutlineFileDownload fontSize={"18"} />
            Download
        </WideButtonWithIcon>
    );
};
