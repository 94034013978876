import {
    CampaignModel,
    CampaignReportModel,
    CompanyGifteeModel,
    CompanyUserModel,
    GiftModel,
    ParsedGiftModel,
    PaymentMethodModel,
    ReportGiftModel,
} from "@dono-business/shared/models";
import { AxiosInstance } from "axios";

type BatchGiftResponse = GiftModel & {
    error: {
        message: string;
    };
};
export const giftRequests = (baseUrl: string, customAxios: AxiosInstance) => ({
    parseBatchFile: (xlsxFile: File) => {
        const formData = new FormData();
        formData.append("file", xlsxFile);

        return customAxios.post<ParsedGiftModel[]>(`${baseUrl}/management/batch-gift/parse`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    createBatch: (gifts: GiftModel[]) => {
        return customAxios.post<BatchGiftResponse[]>(`${baseUrl}/management/batch-gift`, gifts);
    },
    payBatch: (giftIds: number[], paymentClient: string, paymentProvider = "Stripe") => {
        const data = {
            giftIds,
            paymentClient,
            paymentProvider,
        };

        return customAxios.post(`${baseUrl}/management/batch-gift/payment/prepare`, data);
    },
    createStripeCheckoutSession: (
        giftIds: number[],
        paymentClient: string,
        successURL: string,
        cancelURL: string,
        useBucksBalance: boolean,
        paymentProvider = "Stripe",
    ) => {
        const data = {
            giftIds,
            paymentClient,
            successURL,
            cancelURL,
            useBucksBalance,
            paymentProvider,
        };
        return customAxios.post(`${baseUrl}/management/batch-gift/payment/checkout-session`, data);
    },
    getPaymentMethods: (amount: number, useBucksBalance: boolean) => {
        return customAxios.get<PaymentMethodModel[]>(`${baseUrl}/gift/payment/methods`, {
            params: {
                amount,
                useBucksBalance,
            },
        });
    },
    getStripeConfig: () => {
        return customAxios.get(`${baseUrl}/gift/payment/config`);
    },
    // getReportGifts: (params?: ReportGiftsRequestParamsModel) => {
    //     return customAxios.get<{
    //         items: ReportGiftModel[];
    //         pagination: PaginationModel;
    //     }>(`${baseUrl}/gift/report`, {
    //         params,
    //     });
    // },
    getCompanyUsers: () => {
        return customAxios.get<CompanyUserModel[]>(`${baseUrl}/company/user`);
    },
    getCompanyGifteesWithQuery: (query: string) => {
        return customAxios.get<CompanyGifteeModel[]>(`${baseUrl}/company/giftees/${query}`);
    },
    getCompanyCampaigns: () => {
        return customAxios.get<CampaignModel[]>(`${baseUrl}/company/campaign`);
    },
    getCampaignsReportList: () => {
        return customAxios.get<{ items: CampaignReportModel[] }>(`${baseUrl}/company/campaign/report`);
    },
    getAllCampaignGifts: (campaignIds: number[]) => {
        return customAxios.post<{ items: ReportGiftModel[] }>(`${baseUrl}/company/campaign/gifts`, campaignIds);
    },
    getAllOccasions: () => {
        return customAxios.get<string[]>(`${baseUrl}/gift/occasions`);
    },
});
