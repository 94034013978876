import React, { SVGProps } from "react";

export const StepTwoEdgeIcon = () => (
    <svg width="119" height="120" viewBox="0 0 119 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
            <g filter="url(#filter0_d_549_6458)">
                <path
                    d="M91 14.0059H28C21.3726 14.0059 16 19.3784 16 26.0059V89.0059C16 95.6333 21.3726 101.006 28 101.006H91C97.6274 101.006 103 95.6333 103 89.0059V26.0059C103 19.3784 97.6274 14.0059 91 14.0059Z"
                    fill="url(#paint0_linear_549_6458)"
                />
                <path
                    d="M91 14.5059H28C21.6487 14.5059 16.5 19.6546 16.5 26.0059V89.0059C16.5 95.3571 21.6487 100.506 28 100.506H91C97.3513 100.506 102.5 95.3571 102.5 89.0059V26.0059C102.5 19.6546 97.3513 14.5059 91 14.5059Z"
                    stroke="white"
                    strokeOpacity="0.01"
                />
            </g>
        </g>
        <path
            d="M64.496 83.0009H33.08V76.6769L52.256 57.6369C53.7067 56.1409 54.9533 54.5769 55.996 52.9449C57.0387 51.3129 57.56 49.4769 57.56 47.4369C57.56 46.1675 57.3333 45.0342 56.88 44.0369C56.4267 42.9942 55.8147 42.1102 55.044 41.3849C54.2733 40.6595 53.3667 40.1155 52.324 39.7529C51.2813 39.3449 50.1707 39.1409 48.992 39.1409C46.544 39.1409 44.5267 39.9342 42.94 41.5209C41.3533 43.0622 40.356 45.0569 39.948 47.5049L33.488 46.4169C33.76 44.6035 34.3267 42.9262 35.188 41.3849C36.0947 39.7982 37.228 38.4382 38.588 37.3049C39.948 36.1262 41.512 35.2195 43.28 34.5849C45.0933 33.9502 47.02 33.6329 49.06 33.6329C51.0547 33.6329 52.9587 33.9275 54.772 34.5169C56.6307 35.1062 58.2627 35.9675 59.668 37.1009C61.0733 38.2342 62.184 39.6622 63 41.3849C63.8613 43.1075 64.292 45.0795 64.292 47.3009C64.292 48.8422 64.088 50.2702 63.68 51.5849C63.272 52.8995 62.7053 54.1462 61.98 55.3249C61.3 56.5035 60.484 57.6369 59.532 58.7249C58.58 59.7675 57.5827 60.8102 56.54 61.8529L40.9 77.0849H64.496V83.0009Z"
            fill="#0C2145"
        />
        <path
            d="M79.5723 91.102H72.5723C72.1722 91.102 71.8123 90.842 71.6923 90.4619L70.0925 85.3819L66.6923 83.4218L61.4923 84.5619C61.0921 84.6419 60.6923 84.462 60.4923 84.1218L57.0123 78.0819C56.8123 77.7418 56.8525 77.3018 57.1323 77.0018L60.7124 73.0819V69.1419L57.1122 65.2019C56.8321 64.902 56.7922 64.462 56.9923 64.1218L60.4923 58.0618C60.6923 57.7217 61.0921 57.5418 61.4923 57.6218L66.6923 58.7619L70.0925 56.8018L71.6923 51.7218C71.8123 51.3621 72.1722 51.1021 72.5723 51.1021H79.5523C79.9524 51.1021 80.3123 51.3621 80.4323 51.7421L82.0321 56.8222L85.4323 58.7823L90.6323 57.6422C91.0325 57.5621 91.4323 57.7421 91.6323 58.0822L95.1323 64.1422C95.3324 64.4823 95.2922 64.9223 95.0124 65.2223L91.4323 69.1422V73.0621L95.0124 76.9821C95.2925 77.282 95.3324 77.722 95.1323 78.0621L91.6323 84.1221C91.4323 84.4623 91.0325 84.6422 90.6323 84.5622L85.4323 83.4221L82.0321 85.3822L80.4323 90.4622C80.3324 90.842 79.9724 91.102 79.5723 91.102Z"
            fill="#D2D5DF"
        />
        <path
            d="M79.5723 91.102H72.5723C72.1722 91.102 71.8123 90.8419 71.6923 90.4619L70.0925 85.3818L66.6923 83.4218L61.4923 84.5619C61.0921 84.6419 60.6923 84.462 60.4923 84.1218L57.0123 78.0819C56.8123 77.7418 56.8525 77.3018 57.1323 77.0018L60.7124 73.0819V69.1419L57.1122 65.2019C56.8321 64.902 56.7922 64.462 56.9923 64.1218L60.4923 58.0618C60.6923 57.7217 61.0921 57.5418 61.4923 57.6218L66.6923 58.7619L70.0925 56.8018L71.6923 51.7218C71.8123 51.3621 72.1722 51.1021 72.5723 51.1021H79.5523C79.9524 51.1021 80.3123 51.3621 80.4323 51.7421L82.0321 56.8222L85.4323 58.7823L90.6323 57.6422C91.0325 57.5621 91.4323 57.7421 91.6323 58.0822L95.1323 64.1422C95.3324 64.4823 95.2922 64.9223 95.0124 65.2223L91.4323 69.1422V73.0621L95.0124 76.9821C95.2925 77.282 95.3324 77.722 95.1323 78.0621L91.6323 84.1221C91.4323 84.4622 91.0325 84.6422 90.6323 84.5621L85.4323 83.4221L82.0321 85.3821L80.4323 90.4622C80.3324 90.842 79.9724 91.102 79.5723 91.102ZM73.2523 89.2618H78.8924L80.4125 84.4817C80.4725 84.2618 80.6323 84.0816 80.8324 83.9616L84.8525 81.6416C85.0525 81.5216 85.2925 81.4817 85.5124 81.5417L90.3924 82.6218L93.2124 77.7418L89.8324 74.0417C89.6725 73.8819 89.5924 73.6416 89.5924 73.4218V68.782C89.5924 68.5621 89.6725 68.3219 89.8324 68.162L93.2124 64.4619L90.3924 59.5819L85.5124 60.662C85.2925 60.7019 85.0523 60.6821 84.8525 60.5621L80.8324 58.2421C80.6323 58.1221 80.4923 57.9422 80.4125 57.722L78.8924 52.9419L73.2523 52.9422L71.7323 57.7223C71.6723 57.9422 71.5124 58.1224 71.3123 58.2424L67.3123 60.5622C67.1123 60.6821 66.8723 60.722 66.6525 60.6621L61.7725 59.582L58.9323 64.462L62.3123 68.1621C62.4722 68.3219 62.5523 68.5622 62.5523 68.782V73.4221C62.5523 73.642 62.4722 73.8822 62.3123 74.0421L58.9323 77.7422L61.7523 82.6222L66.6323 81.5421C66.8522 81.5022 67.0924 81.522 67.2922 81.642L71.3123 83.962C71.5123 84.0819 71.6524 84.2619 71.7322 84.482L73.2523 89.2618Z"
            fill="white"
        />
        <path
            d="M76.0716 79.382C65.1116 79.0419 65.1116 63.142 76.0716 62.802C87.0316 63.1619 87.0316 79.042 76.0716 79.382ZM76.0716 64.662C67.5516 64.9421 67.5516 77.282 76.0716 77.562C84.5916 77.2819 84.5916 64.922 76.0716 64.662Z"
            fill="white"
        />
        <path
            d="M84.8128 63.2819C84.8128 64.482 82.9727 64.482 82.9727 63.2819C82.9729 62.0818 84.8128 62.0818 84.8128 63.2819Z"
            fill="white"
        />
        <path
            d="M87.1332 72.022C86.6332 72.022 86.2133 71.602 86.2133 71.102C86.2133 69.422 85.7934 67.782 85.0132 66.3219C84.7732 65.8819 84.9331 65.3219 85.3932 65.082C87.3533 64.2418 88.0933 69.8018 88.0732 71.1219C88.0531 71.6021 87.6332 72.022 87.1332 72.022L87.1332 72.022Z"
            fill="white"
        />
        <defs>
            <filter
                id="filter0_d_549_6458"
                x="0"
                y="0.00585938"
                width="119"
                height="119"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="8" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.239216 0 0 0 0 0.121569 0 0 0 0 0.262745 0 0 0 0.2 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_549_6458" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_549_6458" result="shape" />
            </filter>
            <linearGradient
                id="paint0_linear_549_6458"
                x1="69.331"
                y1="19.6609"
                x2="9.73599"
                y2="134.414"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#C2C6DA" />
                <stop offset="1" stopColor="#0C2145" />
            </linearGradient>
        </defs>
    </svg>
);
