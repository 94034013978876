import { IconButton, Typography, alpha } from "@mui/material";
import { Box, styled } from "@mui/material";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import {
    DataGrid,
    DataGridProps,
    GridPagination,
    GridValidRowModel,
    gridPageCountSelector,
    gridRowCountSelector,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid";
import { useSmallScreenSize } from "@dono-business/shared/hooks";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

import React, { forwardRef, useEffect, useRef, useState } from "react";
const StyledDataGrid = styled(DataGrid)<{ showPointerOnHover?: boolean }>(({ theme, showPointerOnHover }) => ({
    border: "none",
    "--DataGrid-rowBorderColor": "#e9e9e9",
    "& .MuiDataGrid-row:hover": {
        background: "#f9f9f9",
        "& .MuiDataGrid-cell, & .MuiDataGrid-cell *": {
            color: theme.palette.text.darker,
            ...(showPointerOnHover && { cursor: "pointer" }),
        },
    },
    "& .MuiDataGrid-row.Mui-selected": {
        background: alpha("#d9d9d9", 0.15),
    },
    "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 800,
        color: theme.palette.text.darker,
        lineHeight: 1.5,
    },

    "& .MuiDataGrid-main": {
        borderBottom: "1px solid var(--DataGrid-rowBorderColor)",
        borderTop: "1px solid var(--DataGrid-rowBorderColor)",
    },

    "& .MuiDataGrid-cell": {
        color: theme.palette.text.fresh,
        fontWeight: "800",
        fontSize: "12px",
        // lineHeight: 1.5,
    },
    "& .MuiDataGrid-cell:focus": { outline: "none" },
    "& .MuiTablePagination-displayedRows": {
        fontWeight: 800,
        color: theme.palette.text.darker,
        lineHeight: 1.5,
    },
    "& .MuiTablePagination-actions": {
        "button:not(.Mui-disabled) svg": { fill: theme.palette.text.darker },
    },
    [theme.breakpoints.down("sm")]: {
        ".MuiDataGrid-footerContainer": {
            justifyContent: "center",
            height: "94px",
            "& svg": { width: "48px", height: "48px" },
        },
        ".MuiIconButton-root": { padding: 0 },
        ".MuiTablePagination-displayedRows": {
            fontSize: "16px",
            lineHeight: 1.5,
            minWidth: "130px",
            textAlign: "center",
        },
        ".MuiToolbar-root": { padding: 0 },
        ".MuiToolbar-root > .MuiTablePagination-displayedRows": { display: "none" },
        ".MuiToolbar-root .MuiTablePagination-actions": { marginLeft: 0 },
    },
})) as unknown as typeof DataGrid;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomDataGrid = (function _dataGrid<R extends GridValidRowModel = any>() {
    return forwardRef<HTMLDivElement, DataGridProps<R> & { showPointerOnHover?: boolean }>(function CustomDataGrid(
        props,
        ref,
    ) {
        const rowSelection = props.rowSelection ?? true;
        const [selectedRowId, setSelectedRowId] = useState<(number | string)[]>([]);
        const gridRef = ref && typeof ref === "object" ? ref : useRef<HTMLDivElement | null>(null);
        useEffect(() => {
            const handleKeyUp = (e: KeyboardEvent) => {
                if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                    if (!rowSelection) return;
                    const rowItem = getRowFromKeyboardEvent(e);
                    const id = rowItem && props.getRowId?.(rowItem);
                    if (id !== undefined) {
                        setSelectedRowId([id]);
                    }
                }
            };
            const getRowFromKeyboardEvent = (e: KeyboardEvent) => {
                if (e.target instanceof HTMLElement) {
                    const rowElement = e.target.closest(".MuiDataGrid-row");
                    if (rowElement) {
                        const rowId = rowElement.getAttribute("data-id");

                        if (rowId !== null) {
                            const rowItem = props.rows?.find(
                                (row) => props.getRowId?.(row) === rowId || props.getRowId?.(row) === +rowId,
                            );
                            return rowItem ?? undefined;
                        }
                    }
                }
            };
            gridRef.current?.addEventListener("keyup", handleKeyUp);
            return () => gridRef.current?.removeEventListener("keyup", handleKeyUp);
        }, [props]);
        return (
            <StyledDataGrid
                ref={gridRef}
                columnHeaderHeight={50}
                rowHeight={62}
                rowSelection={rowSelection}
                rowSelectionModel={selectedRowId}
                onRowSelectionModelChange={setSelectedRowId}
                slots={{ pagination: CustomPagination }}
                {...props}
            />
        );
    });
})();
const CustomPagination = () => {
    const isSmallScreen = useSmallScreenSize();
    return isSmallScreen ? (
        <GridPagination
            ActionsComponent={(props: TablePaginationActionsProps) => {
                const apiRef = useGridApiContext();
                const pageCount = useGridSelector(apiRef, gridPageCountSelector);
                const rowCount = useGridSelector(apiRef, gridRowCountSelector);
                const firstDisplayedRowNumber = Math.min(props.page * props.rowsPerPage + 1, rowCount);
                const lastDisplayedRowNumber = Math.min(props.page * props.rowsPerPage + props.rowsPerPage, rowCount);
                return (
                    <Box display="flex" alignItems="center" className="MuiTablePagination-actions">
                        <IconButton onClick={(e) => props.onPageChange(e, props.page - 1)} disabled={props.page === 0}>
                            <KeyboardArrowLeft />
                        </IconButton>
                        <Typography className="MuiTablePagination-displayedRows" sx={{ textWrap: "nowrap" }}>
                            {`${firstDisplayedRowNumber} -
                            ${lastDisplayedRowNumber} of ${rowCount}`}
                        </Typography>
                        <IconButton
                            onClick={(e) => props.onPageChange(e, props.page + 1)}
                            disabled={props.page === pageCount - 1}
                        >
                            <KeyboardArrowRight />
                        </IconButton>
                    </Box>
                );
            }}
        />
    ) : (
        <GridPagination />
    );
};
