import React from "react";

export const ShareIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 25" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.4671 17.3559C16.9971 16.8559 17.7071 16.5459 18.4971 16.5459C20.1571 16.5459 21.4971 17.8859 21.4971 19.5459C21.4971 21.2059 20.1571 22.5459 18.4971 22.5459C16.8371 22.5459 15.4971 21.2059 15.4971 19.5459C15.4971 19.3059 15.5371 19.0759 15.5871 18.8559L8.53707 14.7359C7.99707 15.2359 7.28707 15.5459 6.49707 15.5459C4.83707 15.5459 3.49707 14.2059 3.49707 12.5459C3.49707 10.8859 4.83707 9.5459 6.49707 9.5459C7.28707 9.5459 7.99707 9.8559 8.53707 10.3559L15.5871 6.2459C15.5371 6.0259 15.4971 5.7859 15.4971 5.5459C15.4971 3.8859 16.8371 2.5459 18.4971 2.5459C20.1571 2.5459 21.4971 3.8859 21.4971 5.5459C21.4971 7.2059 20.1571 8.5459 18.4971 8.5459C17.7071 8.5459 16.9871 8.2359 16.4571 7.7359L9.40707 11.8459C9.45707 12.0759 9.49707 12.3059 9.49707 12.5459C9.49707 12.7859 9.45707 13.0159 9.40707 13.2459L16.4671 17.3559ZM19.4971 5.5459C19.4971 4.9959 19.0471 4.5459 18.4971 4.5459C17.9471 4.5459 17.4971 4.9959 17.4971 5.5459C17.4971 6.0959 17.9471 6.5459 18.4971 6.5459C19.0471 6.5459 19.4971 6.0959 19.4971 5.5459ZM6.49707 13.5459C5.94707 13.5459 5.49707 13.0959 5.49707 12.5459C5.49707 11.9959 5.94707 11.5459 6.49707 11.5459C7.04707 11.5459 7.49707 11.9959 7.49707 12.5459C7.49707 13.0959 7.04707 13.5459 6.49707 13.5459ZM17.4971 19.5459C17.4971 20.0959 17.9471 20.5459 18.4971 20.5459C19.0471 20.5459 19.4971 20.0959 19.4971 19.5459C19.4971 18.9959 19.0471 18.5459 18.4971 18.5459C17.9471 18.5459 17.4971 18.9959 17.4971 19.5459Z"
                fill="white"
            />
        </svg>
    );
};
