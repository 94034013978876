import { Box, Typography } from "@mui/material";
import React from "react";
import { ConfigTitle } from "./styles";
import { RegistrationFields } from "./RegistrationFields";

export const EventRegistrationFields = () => {
    return (
        <Box>
            <ConfigTitle mb={2} display="block">
                Data Collection
            </ConfigTitle>
            <Typography mb={3} display="block">
                You may add more fields. Fields marked * are required.
            </Typography>
            <RegistrationFields />
        </Box>
    );
};
