import { BucksBalanceModel } from "@dono-business/shared/models/bucks-balance-model";
import { useAppRequests } from "@dono-business/shared/hooks";
import { useEffect, useState } from "react";

export const useBucksBalance = () => {
    const { bucksRequests } = useAppRequests();

    const [bucksBalance, setBucksBalance] = useState<BucksBalanceModel | null>(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await bucksRequests.getUserBucksBalance();
                setBucksBalance(response?.data ?? null);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { bucksBalance, loading };
};
