/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { InstructionTooltip } from "../InstructionTooltip";
import { RegistrationField } from "../types";

import {
    ItemDeleteSnack,
    ItemEditSnack,
    ItemSaveSnack,
    NewItemButton,
    SavedItemLabel,
    SavedItemValue,
} from "../../../components/list-utils/components";
import { SavedItem } from "components/list-utils/";
import { PersonIcon } from "@dono-business/shared/icons";
import { getNumberInNationalFormat } from "@dono-business/shared/utils";
import { RegistrationFieldsContext } from "./Context";
import { CreateEdgeEventContext } from "../CreateEdgeEventProvider";
import { RegistrationFieldsForm } from "./RegistrationFieldsForm";
import { Circle } from "@mui/icons-material";

export const RegistrationFieldsContent = () => {
    const { registrationFields, defaultRegistrationFieldsNames } = useContext(CreateEdgeEventContext)!;
    const {
        handleItemDelete: handleDeleteRegistrationField,
        handleSelectItemForEdit: handleSelectRegistrationFieldForEdit,
        handleItemEditSave: handleRegistrationFieldEditSave,
        handleItemEditCancel: handleRegistrationFieldEditCancel,
        selectedItem: currentlyEditingRegistrationField,
        handleNewItemCancel: handleNewRegistrationFieldCancel,
        handleNewItemSave: handleNewRegistrationFieldSave,
        handleItemDeleteUndo: handleRegistrationFieldDeleteUndo,
        handleNewItemButtonClick: handleNewRegistrationFieldButtonClick,
        handleDeleteSnackClose,
        handleEditSnackClose,
        handleSaveSnackClose,
        isDeleteSnackShowing,
        isEditSnackShowing,
        isSaveSnackShowing,
        isNewItemFormShowing: isNewRegistrationFieldFormShowing,
    } = useContext(RegistrationFieldsContext)!;
    const isFieldEditable = (name: string) => !defaultRegistrationFieldsNames?.includes(name);

    return (
        <Grid container>
            <Grid item container xs={12} mb={"21px"}>
                {registrationFields?.map((field) => (
                    <SavedItem<RegistrationField>
                        key={field.id}
                        item={field}
                        containerSx={{ "&:hover p": { fontWeight: "700" } }}
                        onDeleteButtonClick={handleDeleteRegistrationField}
                        onEditButtonClick={handleSelectRegistrationFieldForEdit}
                        renderItem={({ name }) => (
                            <Grid container height={"34px"} px="10px">
                                <Grid
                                    pr={"9px"}
                                    item
                                    xs={"auto"}
                                    alignSelf="center"
                                    display="flex"
                                    alignItems={"center"}
                                >
                                    {" "}
                                    <Circle sx={{ fontSize: "6px" }} />
                                </Grid>
                                <Grid item xs alignSelf="center">
                                    <SavedItemValue>
                                        {name}
                                        {isFieldEditable(name) === false ? "*" : ""}
                                    </SavedItemValue>
                                </Grid>
                            </Grid>
                        )}
                        renderEditMode={({ name, id }) => (
                            <Grid container>
                                <Grid item maxWidth={"800px"}>
                                    <RegistrationFieldsForm
                                        mode="edit"
                                        onSubmit={(data) => handleRegistrationFieldEditSave({ ...data, id })}
                                        onCancel={handleRegistrationFieldEditCancel}
                                        defaultValues={{ name }}
                                        nonAllowedFieldNames={registrationFields
                                            .map((field) => field.name)
                                            .filter((_name) => name !== _name)}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        isEditing={currentlyEditingRegistrationField?.id === field.id}
                        disableDelete={!isFieldEditable(field.name) || isDeleteSnackShowing}
                        disableEdit={!isFieldEditable(field.name)}
                    />
                ))}
            </Grid>
            {isNewRegistrationFieldFormShowing ? (
                <Grid item maxWidth={"800px"}>
                    <RegistrationFieldsForm
                        mode="add"
                        onSubmit={handleNewRegistrationFieldSave}
                        onCancel={handleNewRegistrationFieldCancel}
                        nonAllowedFieldNames={registrationFields.map((field) => field.name)}
                    />{" "}
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <NewItemButton
                        sx={{ width: "196px" }}
                        onClick={handleNewRegistrationFieldButtonClick}
                        disabled={isNewRegistrationFieldFormShowing}
                    >
                        + Add Custom Field
                    </NewItemButton>
                </Grid>
            )}

            <ItemDeleteSnack
                isShowing={isDeleteSnackShowing}
                handleClose={handleDeleteSnackClose}
                handleUndo={handleRegistrationFieldDeleteUndo}
                message="Field removed."
            />
            <ItemEditSnack isShowing={isEditSnackShowing} handleClose={handleEditSnackClose} message="Field updated." />
            <ItemSaveSnack
                isShowing={isSaveSnackShowing}
                handleClose={handleSaveSnackClose}
                message="Field successfully added!"
            />
        </Grid>
    );
};
