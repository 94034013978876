import { EdgeEventGiftValueType, EdgeEventModel } from "@dono-business/shared/models";
import { Box } from "@mui/system";
import React from "react";

import GiftIcon from "../../../../assets/images/gift-icon.svg";
import { ModalMessage, ModalSubtitle, ModalTitle } from "./styles";

interface PrizeModalContentProps {
    event?: EdgeEventModel;
}
export const PrizeModalContent = ({ event }: PrizeModalContentProps) => {
    return (
        <Box pl={2}>
            <ModalTitle display="block">{event?.name} Prizes</ModalTitle>
            <ModalMessage mt={0.5} mb={3} display="block">
                Good Luck!
            </ModalMessage>

            <Box mb={2}>
                {event?.edgeEventPrizeData?.map((item, index) =>
                    item ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={1}
                            mb={2}
                            key={index}
                        >
                            <Box display="flex" alignItems="center" gap={1} minWidth={"40%"}>
                                <img src={GiftIcon} alt="GIFT" />
                                <ModalSubtitle ml={1}>
                                    {item.quantity} {item.quantity > 1 ? "Gifts" : "Gift"}
                                </ModalSubtitle>
                            </Box>
                            <ModalSubtitle>
                                {item.type === EdgeEventGiftValueType.DonoGift ? (
                                    item.amount ? (
                                        <>
                                            {item.amount.symbol}
                                            {item.amount.value}
                                        </>
                                    ) : null
                                ) : (
                                    item.name
                                )}
                            </ModalSubtitle>
                        </Box>
                    ) : null,
                )}
            </Box>
        </Box>
    );
};
