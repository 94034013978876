import { capitalize, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo, useState } from "react";
import { useReportEvents } from "./useReportEvents";
import calendarStar from "../../../assets/images/calendar-star.svg";
import crossCircle from "../../../assets/images/cross-circle.svg";
import doubleTicks from "../../../assets/images/double-ticks.svg";
import playCircle from "../../../assets/images/play-circle.svg";
import { useGridApiRef } from "@mui/x-data-grid";
import { ExportButton, CustomDataGrid } from "@dono-business/shared/components";
import { useReportEventsGridColumns } from "./useReportEventsGridColumns";
import { EventStatus } from "@dono-business/shared/models";
import { SearchBar } from "@dono-business/shared/components/SearchBar";
import { debounce } from "@mui/material/utils";
import { useNavigate } from "react-router";
export const ReportScreen = () => {
    const { reportEvents, loading } = useReportEvents();
    const reportEventsGridColumns = useReportEventsGridColumns();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const statusesData = [
        { status: EventStatus.Upcoming, icon: calendarStar },
        { status: EventStatus.Running, icon: playCircle },
        { status: EventStatus.Ended, icon: doubleTicks },
        { status: EventStatus.Canceled, icon: crossCircle },
    ];
    const apiRef = useGridApiRef();

    const updateSearchValue = useMemo(() => {
        return debounce((newValue: string) => {
            apiRef.current.setQuickFilterValues(newValue.split(" ").filter((word) => word !== ""));
        }, 500);
    }, [apiRef]);

    const handleSearchValueChange = (newValue: string) => {
        setSearchValue(newValue);
        updateSearchValue(newValue);
    };

    const reportEventsGridData = reportEvents;

    const navigateToEventDetail = (eventId: number | string) => navigate(`/report/events/${eventId}`);
    return (
        <Grid container>
            <Grid xs={12} item display="flex" justifyContent="space-between" alignItems={"center"} mb={"92px"}>
                <Typography
                    sx={(theme) => ({
                        color: theme.palette.text.darker,
                        lineHeight: 1.5,
                        fontWeight: "800",
                        fontSize: "20px",
                    })}
                >
                    Event Reports
                </Typography>
                <Typography
                    sx={(theme) => ({
                        color: theme.palette.text.darker,
                        lineHeight: 1.5,
                        fontWeight: "800",
                        fontSize: "16px",
                    })}
                >
                    Total Events Created: {reportEvents?.length ?? "-"}
                </Typography>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end" mb="30px" alignItems={"center"} gap={"32px"}>
                <SearchBar
                    value={searchValue}
                    onValueChange={handleSearchValueChange}
                    placeholder="Search in reports"
                />
                <ExportButton
                    onClick={() =>
                        apiRef.current.exportDataAsCsv({ fileName: `edge_events_report_${new Date().getTime()}` })
                    }
                />
            </Grid>
            <Grid item xs={12} mb={{ xs: "40px", md: "70px" }}>
                <CustomDataGrid
                    getRowId={(r) => r.id}
                    rows={reportEventsGridData}
                    columns={reportEventsGridColumns}
                    initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
                    autoHeight
                    loading={loading}
                    apiRef={apiRef}
                    onRowClick={(params) => navigateToEventDetail(params.id)}
                    onCellKeyDown={(params, event) => event.key === "Enter" && navigateToEventDetail(params.id)}
                    showPointerOnHover
                />
            </Grid>
            <Grid item container xs={12} spacing={2.5}>
                {statusesData.map(({ status, icon }) => (
                    <Grid item xs={12} md={6} key={status}>
                        <Box
                            py={3}
                            px={5}
                            sx={{
                                background: `url(${icon})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "left 3% bottom 15%",
                                border: "1px solid rgba(185, 147, 194, 0.6)",
                                borderRadius: "8px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    fontSize: 20,
                                    lineHeight: "27px",
                                    marginBottom: 2.5,
                                    color: "#0C2145",
                                    display: "block",
                                }}
                            >
                                {capitalize(status)}
                            </Typography>
                            <Box
                                sx={{
                                    marginBottom: 2.5,
                                    color: "#0C2145",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography sx={{ fontWeight: 600, fontSize: 16, lineHeight: "21px" }}>
                                    Participants
                                </Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: 26, lineHeight: "35px" }}>
                                    {reportEvents.reduce(
                                        (prev, curr) => (curr.status === status ? prev + curr.registeredCount : prev),
                                        0,
                                    )}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    color: "#0C2145",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography sx={{ fontWeight: 600, fontSize: 16, lineHeight: "21px" }}>
                                    Amount
                                </Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: 26, lineHeight: "35px" }}>
                                    $
                                    {reportEvents.reduce(
                                        (prev, curr) => (curr.status === status ? prev + curr.amount.value : prev),
                                        0,
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};
