import * as React from "react";
import { SVGProps } from "react";

export const BackIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={8} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="m2.263 6.996 4.969-4.965A.934.934 0 0 0 6.568.434a.946.946 0 0 0-.665.272L.273 6.332a.937.937 0 0 0-.027 1.294l5.653 5.665a.939.939 0 0 0 1.329-1.325l-4.965-4.97Z"
            fill="#0C2145"
        />
    </svg>
);
