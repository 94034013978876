import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
export const phoneUtil = PhoneNumberUtil.getInstance();
export const formatToInternational = (enteredPhone: string) => {
    let formattedNumber;
    try {
        const phoneNumberWithoutNonStandardCountryCode = removeNonStandardCountryCode(enteredPhone);
        const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumberWithoutNonStandardCountryCode, "US");
        formattedNumber = phoneUtil.format(parsedNumber, PhoneNumberFormat.E164);
    } catch (err) {
        formattedNumber = enteredPhone;
    }
    return formattedNumber;
};

export const validateUSPhoneNumber = (enteredPhone: string) => {
    try {
        const phoneNumberWithoutNonStandardCountryCode = removeNonStandardCountryCode(enteredPhone);
        const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumberWithoutNonStandardCountryCode, "US");
        if (!phoneUtil.isValidNumber(parsedNumber)) {
            return false;
        }
    } catch (err) {
        return false;
    }
    return true;
};
// google-libphonenumber doesn't recognize country code with format "001" and doesn't remove them from input
// so we should remove it to prevent bugs.
const removeNonStandardCountryCode = (enteredPhone: string) => {
    if (enteredPhone.startsWith("001")) {
        return enteredPhone.substring(4);
    }
    return enteredPhone;
};

export const getNumberInNationalFormat = (number: string) => {
    const phoneNumber = phoneUtil.parse(number, "US");
    return phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
};
