import { ButtonProps as MuiButtonProps, CircularProgress, Button as MuiButton } from "@mui/material";
import React from "react";

export interface ButtonProps extends MuiButtonProps {
    isLoading?: boolean;
}

export const Button = ({ isLoading, children, ...props }: ButtonProps) => {
    return (
        <MuiButton
            {...props}
            style={
                isLoading
                    ? {
                          display: "flex",
                          alignItems: "center",
                      }
                    : undefined
            }
        >
            {isLoading ? <CircularProgress size={20} color="secondary" /> : children}
        </MuiButton>
    );
};
