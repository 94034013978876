import { SimplePaletteColorOptions } from "@mui/material";
import { PaletteColors } from "../types";

export const lightColors: PaletteColors<SimplePaletteColorOptions> = {
    red: {
        main: "#F21D1D",
        dark: "#D53864",
    },

    purple: {
        main: "#B993C2",
        dark: "#C2C6DA",
        cloudy: "#7389AD",
    },

    blue: {
        dark: "#0B2245",
        light: "#43496A",
        main: "#0C2145",
    },

    green: {
        light: "#22C55E",
        main: "#1AA928",
    },

    orange: {
        light: "#FFF8EE",
        main: "#BF6E0E",
    },
    neutral: {
        grey: {
            main: "#EBEBEB",
            light: "#6F6F6F",
            lighter: "#FAFAFA",
            darker: "#788395",

            darkest: "#151515",
        },
        white: "#FFFFFF",
        black: "#151515",
    },
};

export const darkColors = {
    red: {
        main: "#F21D1D",
        dark: "#D53864",
    },

    purple: {
        main: "#B993C2",
        dark: "#C2C6DA",
        cloudy: "#7389AD",
    },

    blue: {
        dark: "#0B2245",
        light: "#43496A",
        main: "#0C2145",
    },

    green: {
        light: "#22C55E",
        main: "#1AA928",
    },

    orange: {
        light: "#FFF8EE",
        main: "#BF6E0E",
    },
    neutral: {
        grey: {
            main: "#EBEBEB",
            light: "#6F6F6F",
            lighter: "#FAFAFA",
            darker: "#788395",
            darkest: "#151515",
        },
        white: "#FFFFFF",
        black: "#151515",
    },
};
