import React from "react";

export const StarIcon = () => {
    return (
        <svg width="20" height="16" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.0863 0.837891C1.55587 0.837891 1.04716 1.0486 0.67209 1.42368C0.297017 1.79875 0.0863037 2.30746 0.0863037 2.83789V6.83789C0.616737 6.83789 1.12544 7.0486 1.50052 7.42368C1.87559 7.79875 2.0863 8.30746 2.0863 8.83789C2.0863 9.36832 1.87559 9.87703 1.50052 10.2521C1.12544 10.6272 0.616737 10.8379 0.0863037 10.8379V14.8379C0.0863037 15.3683 0.297017 15.877 0.67209 16.2521C1.04716 16.6272 1.55587 16.8379 2.0863 16.8379H18.0863C18.6167 16.8379 19.1254 16.6272 19.5005 16.2521C19.8756 15.877 20.0863 15.3683 20.0863 14.8379V10.8379C19.5559 10.8379 19.0472 10.6272 18.6721 10.2521C18.297 9.87703 18.0863 9.36832 18.0863 8.83789C18.0863 8.30746 18.297 7.79875 18.6721 7.42368C19.0472 7.0486 19.5559 6.83789 20.0863 6.83789V2.83789C20.0863 2.30746 19.8756 1.79875 19.5005 1.42368C19.1254 1.0486 18.6167 0.837891 18.0863 0.837891H2.0863ZM2.0863 2.83789H18.0863V5.37789C16.8463 6.08789 16.0863 7.40789 16.0863 8.83789C16.0863 10.2679 16.8463 11.5879 18.0863 12.2979V14.8379H2.0863V12.2979C3.3263 11.5879 4.0863 10.2679 4.0863 8.83789C4.0863 7.40789 3.3263 6.08789 2.0863 5.37789V2.83789Z"
                fill="#B993C2"
            />
            <path
                d="M9.84854 3.56965C9.92338 3.33933 10.2492 3.33933 10.3241 3.56965L11.3773 6.81104C11.4107 6.91405 11.5067 6.98379 11.615 6.98379H15.0232C15.2654 6.98379 15.3661 7.29369 15.1702 7.43604L12.4129 9.43933C12.3253 9.50299 12.2886 9.61583 12.3221 9.71884L13.3753 12.9602C13.4501 13.1906 13.1865 13.3821 12.9905 13.2397L10.2333 11.2364C10.1456 11.1728 10.027 11.1728 9.93936 11.2364L7.18207 13.2397C6.98614 13.3821 6.72252 13.1906 6.79736 12.9602L7.85055 9.71884C7.88402 9.61583 7.84735 9.50299 7.75973 9.43933L5.00244 7.43604C4.80651 7.29369 4.9072 6.98379 5.14939 6.98379H8.55758C8.66589 6.98379 8.76188 6.91405 8.79535 6.81104L9.84854 3.56965Z"
                fill="#B993C2"
            />
        </svg>
    );
};
