import { Box, Grid, styled, Typography } from "@mui/material";
import { ButtonWithIcon as ButtonWithIconComp } from "@dono-business/shared/components";

export const IconButtonWrapper = styled(ButtonWithIconComp)({
    width: "36px",
    height: "36px",
    padding: 0,
    borderRadius: 4,
});

export const EventTitle = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    color: theme.palette.text.darker,
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "1.35",
    display: "block",
    wordBreak: "break-word",
}));

export const ButtonWithIcon = styled(ButtonWithIconComp)({ gap: "8px", height: "48px" });
export const WideButtonWithIcon = styled(ButtonWithIcon)({ width: "140px" });
export const BlueText = styled(Typography)(({ theme }) => ({
    fontWeight: "500",
    color: theme.palette.text.darker,
    lineHeight: 1.5,
}));
export const ThinBlueText = styled(BlueText)(({ theme }) => ({
    fontWeight: "400",
    lineHeight: 1.35,
}));

export const BoldBlueText = styled(BlueText)(({ theme }) => ({
    fontWeight: "800",
}));
export const BoldBlueTextLarge = styled(BoldBlueText)({
    fontSize: "18px",
});
export const GridItemWithHorizontalPadding = styled(Grid)({
    padding: "0 31px",
});
export const RegistrationKitIconButton = styled(IconButtonWrapper)(({ theme }) => ({
    width: "48px",
    height: "48px",
    [theme.breakpoints.down(380)]: { width: "32px", height: "32px", "& svg": { width: "20px", height: "20px" } },
}));
export const RegistrationKitButtonsWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "16px",
}));
export const RegistrationKitRow = styled(Grid)(({ theme }) => ({
    borderTop: "1px solid #bababa",
    padding: "23px 13px",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(330)]: { flexDirection: "column", gap: "16px" },
}));
