import { Accordion, AccordionSummary, Divider, Typography, Radio, Grid, Box, Button } from "@mui/material";
import { styled } from "@mui/material";
export const ConfigTitle = styled(Typography)({
    fontSize: 16,
    fontWeight: 700,
    color: "#0C2145",
    lineHeight: "1.5",
});

export const ConfigDivider = styled(Divider)({
    background: "#EADFED",
    height: 1,
    margin: "40px 0",
});

export const StyledAccordion = styled(Accordion)({
    boxShadow: "none",
    margin: "0",
    width: "100%",
    "&.MuiAccordion-root:before": { display: "none" },
    "&.Mui-expanded": { margin: 0 },
});

export const StyledAccordionSummary = styled(AccordionSummary)({
    padding: 0,
    minHeight: "auto",
});

export const StyledRadio = styled(Radio)({
    paddingTop: 0,
    paddingBottom: 0,
});

export const GridItemWithDisableOption = styled(Grid, { shouldForwardProp: (prop) => prop !== "disabled" })<{
    disabled?: boolean;
}>((props) => ({
    pointerEvents: props.disabled ? "none" : "initial",
    opacity: props.disabled ? ".4" : "initial",
}));

export const TotalGiftAmountRow = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
        flexDirection: "row",
    },
}));
export const TotalGiftAmountText = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    fontWeight: 600,
    color: "#0C2145",
    [theme.breakpoints.up("md")]: {
        fontSize: 24,
    },
}));
export const PreviousGiftAmountText = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("md")]: {
        fontSize: 20,
    },
}));

export const InstructionTooltipPositioner = styled(Box)({
    position: "absolute",
    right: "-16px",
    top: "20px",
    transform: "translate(100%,0%)",
});
