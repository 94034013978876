import { Countdown } from "@dono-business/shared/components";
import { EdgeEventModel } from "@dono-business/shared/models";
import { Box, Hidden, Typography, alpha } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { useParams } from "react-router";
import dayjs from "dayjs";
import Download from "../../../../assets/images/download.png";
import HourGlass from "../../../../assets/images/hourglass.svg";
import DonoBrand from "../../../../assets/images/dono-brand.svg";
import DonoLogo from "../../../../assets/images/logo.svg";
import { ModalItems, ModalProvider } from "../modals";
import { useAppRequests } from "@dono-business/shared/hooks";

export const EntryLandingScreen = () => {
    const { edgeRequests } = useAppRequests();

    const [event, setEvent] = useState<EdgeEventModel>();
    const { eventUniqueId } = useParams();

    useEffect(() => {
        if (eventUniqueId) {
            edgeRequests.getEdgeEventByUniqueId(eventUniqueId).then((res) => {
                setEvent(res.data);
            });
        }
    }, [eventUniqueId]);

    const downloadQrCode = useCallback(() => {
        const canvas = document.getElementById("canvas") as HTMLCanvasElement;
        const url = canvas?.toDataURL("image/png");
        const link = document.createElement("a");
        link.download = event?.name + ".png";
        link.href = url;
        link.click();
    }, []);
    const hasRegistrationEnded = dayjs(event?.registrationEndDate).isBefore(dayjs());
    const logo = event?.logoContent?.logoContentValue ?? event?.company.logo ?? DonoLogo;

    return (
        <ModalProvider>
            <Box
                height={"80px"}
                display="flex"
                justifyContent={{ xs: "center", md: "flex-end" }}
                alignItems="center"
                bgcolor={(theme) => alpha(theme.palette.primary.main, 0.8)}
                px={{ md: "140px" }}
                gap={"15px"}
            >
                <Typography sx={(theme) => ({ fontSize: "14px", color: theme.palette.text.contrast })}>
                    Built by
                </Typography>
                <Box component="a" display="flex" alignItems="center" gap={"4px"} href={window.location.origin}>
                    <img src={DonoBrand} alt="DONO" />
                </Box>
            </Box>

            <Box display={"flex"} justifyContent="center" py={{ xs: "8px", md: "60px" }} px={1}>
                <Box maxWidth={"900px"}>
                    <Box
                        display={"flex"}
                        alignItems="center"
                        justifyContent={{ xs: "center", md: "flex-start" }}
                        gap="23px"
                        mb={{ xs: "24px", md: "60px" }}
                    >
                        <Box
                            sx={{
                                width: "175px",
                                minWidth: "175px",
                                height: "175px",
                                backgroundColor: "white",
                                borderRadius: "50%",
                                backgroundImage: `url(${logo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            }}
                        />
                        <Box>
                            <Typography
                                sx={(theme) => ({
                                    fontSize: "40px",
                                    fontWeight: "700",
                                    color: theme.palette.text.dark,
                                    mb: "9px",
                                    display: "block",
                                    lineHeight: "normal",
                                    wordBreak: "break-word",
                                })}
                            >
                                {event?.name || "-"}
                            </Typography>
                            <Typography
                                sx={(theme) => ({
                                    fontSize: "40px",
                                    color: theme.palette.text.dark,
                                    display: "block",
                                    lineHeight: "normal",
                                    wordBreak: "break-word",
                                })}
                            >
                                {event?.company?.name || "-"}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: { xs: "24px", md: "60px" },
                            flexDirection: { xs: "column", md: "row" },
                            alignItems: "center",
                        }}
                    >
                        <Box
                            width={"100%"}
                            display={"flex"}
                            flexDirection="column"
                            justifyContent={"space-between"}
                            gap={"16px"}
                        >
                            <Typography
                                sx={(theme) => ({
                                    fontSize: { xs: "24px", md: "40px" },
                                    fontWeight: "600",
                                    color: theme.palette.text.dark,
                                    mb: { md: "40px" },
                                    display: "block",
                                    lineHeight: "normal",
                                    textAlign: { xs: "center" },
                                })}
                            >
                                Scan for a chance to win!
                            </Typography>
                            <Box
                                display={"flex"}
                                gap="24px"
                                mb={{ md: "56px" }}
                                justifyContent={{ xs: "center", md: "flex-start" }}
                            >
                                <Hidden mdDown>
                                    <img src={HourGlass} alt="DONO" />
                                </Hidden>
                                <Box>
                                    <Typography
                                        sx={(theme) => ({
                                            fontSize: "24px",
                                            color: theme.palette.text.dark,
                                            mb: "8px",
                                            display: "block",
                                            lineHeight: "normal",
                                        })}
                                    >
                                        {event?.status === "ended"
                                            ? "Ended"
                                            : hasRegistrationEnded
                                            ? "Registration period is over."
                                            : "Registration Time Remaining:"}
                                    </Typography>
                                    <Typography
                                        sx={(theme) => ({
                                            fontSize: "24px",
                                            color: theme.palette.text.dark,
                                            mb: "8px",
                                            display: "block",
                                            lineHeight: "normal",
                                        })}
                                    >
                                        <Countdown
                                            endDate={
                                                event?.registrationEndDate
                                                    ? new Date(event?.registrationEndDate).toLocaleString()
                                                    : ""
                                            }
                                            placeholder={null}
                                        />
                                    </Typography>
                                </Box>
                            </Box>
                            <Box maxWidth={"345px"}>
                                <ModalItems event={event} showTerms={false} />
                            </Box>
                        </Box>
                        <Box sx={{ cursor: "pointer", order: { xs: -1, md: "unset" } }} onClick={downloadQrCode}>
                            <QRCode
                                value={window.location.origin + "/landing/registration/" + eventUniqueId}
                                size={325}
                                logoImage={Download}
                                logoOpacity={0.95}
                            />
                            <Box display="none">
                                <QRCode
                                    value={window.location.origin + "/landing/registration/" + eventUniqueId}
                                    size={325}
                                    id="canvas"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ModalProvider>
    );
};
