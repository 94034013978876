import React from "react";
export const HeaderMenuIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="42" viewBox="0 0 45 44" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.45257 14.9409C6.90029 14.9409 6.45258 14.4932 6.45258 13.9409V12.4209C6.45258 11.8686 6.90029 11.4209 7.45258 11.4209H38.4526C39.0049 11.4209 39.4526 11.8686 39.4526 12.4209V13.9409C39.4526 14.4932 39.0049 14.9409 38.4526 14.9409H7.45257ZM6.45258 22.7409C6.45258 23.2932 6.90029 23.7409 7.45258 23.7409H38.4526C39.0049 23.7409 39.4526 23.2932 39.4526 22.7409V21.2209C39.4526 20.6686 39.0049 20.2209 38.4526 20.2209H7.45257C6.90029 20.2209 6.45258 20.6686 6.45258 21.2209V22.7409ZM6.45258 31.5409C6.45258 32.0932 6.90029 32.5409 7.45258 32.5409H38.4526C39.0049 32.5409 39.4526 32.0932 39.4526 31.5409V30.0209C39.4526 29.4686 39.0049 29.0209 38.4526 29.0209H7.45257C6.90029 29.0209 6.45258 29.4686 6.45258 30.0209V31.5409Z"
            fill="#0B2245"
        />
    </svg>
);
