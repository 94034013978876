import { Box, styled } from "@mui/material";

export const StyledBox = styled(Box)(({ theme }) => ({
    position: "relative",

    [theme.breakpoints.down("sm")]: {
        border: "none",
    },
}));

export const SpinnerContainer = styled(Box)({
    position: "absolute",
    zIndex: 1,
    width: "100%",
    background: "#ffffffd4",
    height: "100%",
    top: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
});
