import { EdgeEventGiftValueType, EdgeEventUserStatus, EdgeReportEventModel } from "@dono-business/shared/models";
import { capitalize } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { getEdgeUserStatusDisplayValue } from "../utils";
import { GiftStatus } from "@dono-business/shared/models/gift-status";
import { createDropDownFilterOptionsFromEnum } from "@dono-business/shared/utils";

export const useReportEventParticipantsGridColumns = () => {
    const columns: GridColDef<EdgeReportEventModel["items"][number]>[] = useMemo(
        () => [
            {
                field: "firstName",
                headerName: "First Name",
                minWidth: 150,
                flex: 1,
                align: "left",
            },
            {
                field: "lastName",
                headerName: "Last Name",
                minWidth: 150,
                flex: 1,
                align: "left",
            },
            {
                field: "phone",
                headerName: "Mobile Number",
                minWidth: 150,
                flex: 1,
                align: "left",
            },
            {
                field: "email",
                headerName: "Email",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueFormatter(value) {
                    return value ?? "-";
                },
                valueGetter(value, row) {
                    return row.leadData?.find?.(
                        (field: { name: string; value: string }) => field.name?.toLowerCase?.() === "email",
                    )?.value;
                },
            },
            {
                field: "amount",
                headerName: "Giveaway",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueGetter(value, row) {
                    return row.userPrize?.type === EdgeEventGiftValueType.DonoGift
                        ? row.userPrize?.donoValue
                            ? `${row.userPrize?.donoValue?.symbol}${row.userPrize?.donoValue?.value}`
                            : undefined
                        : row.userPrize?.nonDonoGift ?? undefined;
                },
                valueFormatter(value) {
                    return value ?? "-";
                },
            },
            {
                field: "giftStatus",
                headerName: "Gift Status",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueGetter(value, row) {
                    return row.userPrize?.giftStatus;
                },
                valueFormatter(value) {
                    return value ? capitalize(value) : "-";
                },
                type: "singleSelect",
                valueOptions: createDropDownFilterOptionsFromEnum(GiftStatus),
            },
            {
                field: "edgeUserRegistrationStatus",
                headerName: "Winning Status",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueGetter: (value) => (value === EdgeEventUserStatus.Loser ? EdgeEventUserStatus.NonWinner : value),
                valueFormatter(value) {
                    return value ? getEdgeUserStatusDisplayValue(value) : "-";
                },
                type: "singleSelect",
                valueOptions: Object.values(EdgeEventUserStatus)
                    .filter((value) => value !== EdgeEventUserStatus.Loser)
                    .map((value) => ({
                        value,
                        label: getEdgeUserStatusDisplayValue(value),
                    })),
            },
        ],
        [],
    );
    return columns;
};
