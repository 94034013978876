import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import { BoldBlueText, GridItemWithHorizontalPadding, ThinBlueText } from "./styles";
import { MediaPreview } from "screens/create-edge-event/MediaPreview";
import { EventDetailContext } from "../EventDetailProvider";
import { DownloadButton } from "./DownloadButton";
import { downloadMedia } from "./utils";
import { ToTupleUnion } from "./types";
import { convertGiftMediaContentToMediaFileData } from "screens/create-edge-event/helpers";
import { MoreEventOptions } from "./MoreEventOptions";
import { DetailsBoxData } from "screens/event-detail/types";
import { createCompanyLogoPreviewPlaceHolder } from "@dono-business/shared/utils/other";
export const DetailsBoxMobile = () => {
    const { companyLogoFile, eventDetail, detailsBoxData, event } = useContext(EventDetailContext) ?? {};
    const { winnerMediaContent, loserMediaContent } = eventDetail ?? {};
    const handleDownloadLogo = () =>
        companyLogoFile && downloadMedia(companyLogoFile.filesContent[0].content, `${eventDetail?.company.name}-logo`);
    const convertedToKeyValue = Object.entries(detailsBoxData ?? {}) as Array<ToTupleUnion<DetailsBoxData>>;

    const primaryData = convertedToKeyValue.filter(
        (pair) => pair?.[0] !== "Message to Non-Winner(s)" && pair?.[0] !== "Message to Winner(s)",
    );
    const winnerMessageData = convertedToKeyValue.find((pair) => pair?.[0] === "Message to Winner(s)");
    const nonWinnerMessageData = convertedToKeyValue.find((pair) => pair?.[0] === "Message to Non-Winner(s)");
    const [winnerMessageKey, winnerMessageValue] = winnerMessageData ?? [];
    const [nonWinnerMessageKey, nonWinnerMessageValue] = nonWinnerMessageData ?? [];

    return (
        <Grid container direction={"column"}>
            <Grid item container direction="column" alignItems={"center"} pt="41px">
                <GridItemWithHorizontalPadding
                    item
                    container
                    direction="column"
                    alignItems={"center"}
                    gap="16px"
                    mb="40px"
                >
                    <Grid item>
                        <BoldBlueText>Company Logo</BoldBlueText>
                    </Grid>
                    <Grid item>
                        <MediaPreview
                            fileData={companyLogoFile ?? createCompanyLogoPreviewPlaceHolder()}
                            width={228}
                            height={203}
                        />
                    </Grid>
                    <Grid item>
                        <DownloadButton disabled={!companyLogoFile} onClick={handleDownloadLogo} />
                    </Grid>
                </GridItemWithHorizontalPadding>
                <Grid item container direction="column" gap="24px" mb="24px">
                    {primaryData.map((item) => {
                        const [key, value] = item ?? [];
                        return (
                            <GridItemWithHorizontalPadding
                                item
                                key={key}
                                sx={{
                                    ...((key === "Message to Winner(s)" || key === "Message to Non-Winner(s)") && {
                                        borderTop: "1px solid #bababa",
                                    }),
                                }}
                            >
                                <Box>
                                    <BoldBlueText display={"block"} mb={"4px"}>
                                        {key}:
                                    </BoldBlueText>
                                    <ThinBlueText
                                        display={"block"}
                                        sx={{
                                            wordBreak: "break-word",
                                            "& a": {
                                                textDecoration: "underline",
                                                color: (theme) => theme.palette.text.prominent,
                                            },
                                        }}
                                        dangerouslySetInnerHTML={{ __html: value ?? "" }}
                                    />
                                </Box>
                            </GridItemWithHorizontalPadding>
                        );
                    })}
                    <GridItemWithHorizontalPadding item>
                        <Box>
                            <BoldBlueText display={"block"} mb={"4px"}>
                                DONO Gift Card Total:
                            </BoldBlueText>
                            <ThinBlueText display={"block"}>
                                {event?.amount?.symbol}
                                {event?.amount?.value}
                            </ThinBlueText>
                        </Box>
                    </GridItemWithHorizontalPadding>
                </Grid>
                {winnerMessageValue && (
                    <GridItemWithHorizontalPadding
                        item
                        container
                        sx={{ borderTop: "1px solid #bababa", pt: "22px", pb: "40px" }}
                    >
                        <Grid item>
                            <BoldBlueText display={"block"} mb={"4px"}>
                                {winnerMessageKey}:
                            </BoldBlueText>
                            <ThinBlueText
                                display={"block"}
                                sx={{
                                    wordBreak: "break-word",
                                    "& a": {
                                        textDecoration: "underline",
                                        color: (theme) => theme.palette.text.prominent,
                                    },
                                }}
                                dangerouslySetInnerHTML={{ __html: winnerMessageValue ?? "" }}
                            />
                        </Grid>
                        {winnerMediaContent?.giftContentValue && (
                            <Grid item container direction={"column"} gap="16px" alignItems={"center"} mt="40px">
                                <Grid item>
                                    <MediaPreview
                                        width={168}
                                        height={300}
                                        fileData={convertGiftMediaContentToMediaFileData(
                                            "WINNER FILE",
                                            winnerMediaContent,
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <BoldBlueText>For the Winner(s)</BoldBlueText>
                                </Grid>
                                <Grid item>
                                    <DownloadButton
                                        onClick={() =>
                                            downloadMedia(
                                                winnerMediaContent.giftContentValue ?? "",
                                                `${eventDetail?.name ?? ""}-message`,
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </GridItemWithHorizontalPadding>
                )}{" "}
                {nonWinnerMessageValue && (
                    <GridItemWithHorizontalPadding
                        item
                        container
                        sx={{ borderTop: "1px solid #bababa", pt: "22px", pb: "40px" }}
                    >
                        <Grid item>
                            <BoldBlueText display={"block"} mb={"4px"}>
                                {nonWinnerMessageKey}:
                            </BoldBlueText>
                            <ThinBlueText
                                display={"block"}
                                sx={{
                                    wordBreak: "break-word",
                                    "& a": {
                                        textDecoration: "underline",
                                        color: (theme) => theme.palette.text.prominent,
                                    },
                                }}
                                dangerouslySetInnerHTML={{ __html: nonWinnerMessageValue ?? "" }}
                            />
                        </Grid>
                        {loserMediaContent?.giftContentValue && (
                            <Grid item container direction={"column"} gap="16px" alignItems={"center"} mt="40px">
                                <Grid item>
                                    <MediaPreview
                                        width={168}
                                        height={300}
                                        fileData={convertGiftMediaContentToMediaFileData(
                                            "NON-WINNER FILE",
                                            loserMediaContent,
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <BoldBlueText>For the Non-Winner(s)</BoldBlueText>
                                </Grid>
                                <Grid item>
                                    <DownloadButton
                                        onClick={() =>
                                            downloadMedia(
                                                loserMediaContent.giftContentValue ?? "",
                                                `${eventDetail?.name ?? ""}-message`,
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </GridItemWithHorizontalPadding>
                )}
                <Grid item alignSelf={"stretch"}>
                    <MoreEventOptions />
                </Grid>
            </Grid>
        </Grid>
    );
};
