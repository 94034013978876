import React, { PropsWithChildren, createContext, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useReportEventDetail } from "./useReportEventDetail";
import { useReportEventMoreDetail } from "./useReportEventMoreDetail";
import {
    AmountModel,
    EdgeEventGiftValueType,
    EdgeEventModel,
    EdgeReportEventModel,
    EventStatus,
    MediaFileData,
} from "@dono-business/shared/models";
import calendarStar from "../../../assets/images/calendar-star-small.svg";
import crossCircle from "../../../assets/images/cross-circle-small.svg";
import doubleTicks from "../../../assets/images/double-ticks-small.svg";
import liveIcon from "../../../assets/images/live-icon.svg";

import { convertEdgeEventMediaContentToMediaFileData } from "screens/create-edge-event/helpers";
import { Mode } from "screens/create-edge-event/types";
import { AxiosError } from "axios";
import toast from "react-simple-toasts";
import dayjs from "dayjs";
import { useAppRequests } from "@dono-business/shared/hooks";
import { downloadCSVFile } from "@dono-business/shared/utils/export";
import { capitalize } from "@mui/material";
import { getEdgeUserStatusDisplayValue } from "./utils";
import { DetailsBoxData } from "./types";
import { genericDateAndTimeFormatter, getNumberInNationalFormat } from "@dono-business/shared/utils";
export const EventDetailContext = createContext<{
    event: EdgeReportEventModel | null;
    eventDetail:
        | (EdgeEventModel & {
              amount: AmountModel;
          })
        | null;
    statusIcon: string | null;
    companyLogoFile: MediaFileData | null;
    detailsBoxData: DetailsBoxData | null;
    isCancelEventDialogOpen: boolean;
    setIsCancelEventDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;

    hasRegistrationStarted: boolean;
    hasDrawingStarted: boolean;
    handleCopyEvent: () => void;
    handleModifyEvent: () => void;
    handleNavigateBackToReports: () => void;
    loadingEvent: boolean;
    loadingEventDetail: boolean;
    hasParticipantsData: number | undefined;
    handleParticipantsGridExport: () => void;
    handleCancelEventDialogClose: () => void;
    handleCancelEvent: () => Promise<void>;
    handleDownloadQRCode: () => void;
    registrationLandingUrl: string;
    entryLandingUrl: string;
} | null>(null);
export const EventDetailProvider = ({ children }: PropsWithChildren) => {
    const { edgeEventId: _edgeEventId } = useParams();
    const edgeEventId = _edgeEventId ? +_edgeEventId : undefined;
    const {
        reportEventDetail: event,
        loading: loadingEvent,
        fetchData: fetchEvent,
    } = useReportEventDetail(edgeEventId);
    const {
        reportEventMoreDetail: eventDetail,
        loading: loadingEventDetail,
        fetchData: fetchEventDetail,
    } = useReportEventMoreDetail(edgeEventId);

    const { name, uniqueId, status, items: participants, participantCount } = event ?? {};
    const {
        leadFieldData,
        winnerMessage,
        loserMessage,
        registrationEndDate,
        registrationStartDate,
        edgeEventPrizeData,
        termsAndConditions,
        onsiteContact,
        drawingDate,
        logoContent,
    } = eventDetail ?? {};
    const statusIcon =
        status === EventStatus.Upcoming
            ? calendarStar
            : status === EventStatus.Running
            ? liveIcon
            : status === EventStatus.Canceled
            ? crossCircle
            : status === EventStatus.Ended
            ? doubleTicks
            : null;
    const navigate = useNavigate();
    const registrationLandingUrl = window.location.origin + "/landing/registration/" + uniqueId;
    const entryLandingUrl = window.location.origin + "/landing/entry/" + uniqueId;
    const handleParticipantsGridExport = () => {
        if (!participants) return;
        const exportData = participants
            ?.map((participant) => ({
                "First Name": participant.firstName,
                "Last Name": participant.lastName,
                "Mobile Number": participant.phone,
                Amount:
                    participant.userPrize.type === EdgeEventGiftValueType.DonoGift
                        ? participant.userPrize?.donoValue
                            ? `${participant.userPrize?.donoValue?.symbol}${participant.userPrize?.donoValue?.value}`
                            : "-"
                        : participant.userPrize?.nonDonoGift ?? "-",
                "Gift Status": participant.userPrize?.giftStatus ? capitalize(participant.userPrize?.giftStatus) : "-",
                "Winning Status": getEdgeUserStatusDisplayValue(participant?.edgeUserRegistrationStatus) ?? "-",
            }))
            .map((data, i) => {
                const dataWithCustomFields: { [key: string]: string } = { ...data };
                leadFieldData?.forEach(({ name }) => {
                    if (name) {
                        dataWithCustomFields[name] =
                            participants[i].leadData?.find(({ name: fieldName }) => name === fieldName)?.value ?? "-";
                    }
                });
                return dataWithCustomFields;
            });

        downloadCSVFile(exportData, `edge_event_participants_report_${new Date().getTime()}`);
    };
    const handleDownloadQRCode = () => {
        const canvas = document.getElementById("react-qrcode-logo") as HTMLCanvasElement;
        const pngUrl = canvas?.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qr-code.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    const onEventCancelSuccess = () => {
        fetchEvent();
        fetchEventDetail();
    };

    const hasParticipantsData = participantCount;
    const { edgeRequests } = useAppRequests();

    const hasRegistrationStarted = dayjs(registrationStartDate).isBefore(dayjs());
    const hasDrawingStarted = dayjs(drawingDate).isBefore(dayjs());

    const [isCancelEventDialogOpen, setIsCancelEventDialogOpen] = useState(false);
    const detailsBoxData = eventDetail
        ? {
              "Event Name": name,
              "Registration Period": `${
                  registrationStartDate ? genericDateAndTimeFormatter(registrationStartDate) : "Unknown"
              } - ${registrationEndDate ? genericDateAndTimeFormatter(registrationEndDate) : "Unknown"}`,
              "Drawing Time": drawingDate ? genericDateAndTimeFormatter(drawingDate) : "Unknown",
              "Data Collection": (leadFieldData ?? []).map((field) => field.name).join(", "),
              ...(onsiteContact?.length && {
                  "Event Onsite Contact": onsiteContact
                      .map(
                          (contact) =>
                              `${contact.firstName || ""} ${contact.lastName || ""}${
                                  contact.phoneNumber
                                      ? `${
                                            contact.firstName || contact.lastName ? ":" : ""
                                        } ${getNumberInNationalFormat(contact.phoneNumber)}`
                                      : ""
                              }`,
                      )
                      .join("<br/>"),
              }),
              ...((edgeEventPrizeData ?? []).some((gift) => gift.type === EdgeEventGiftValueType.NonDonoGift) && {
                  "Giveaway(s)": edgeEventPrizeData
                      ?.filter((gift) => gift.type === EdgeEventGiftValueType.NonDonoGift)
                      .map(
                          (gift) =>
                              `${gift.type === EdgeEventGiftValueType.NonDonoGift && gift.name} - Qty: ${
                                  gift.quantity
                              }`,
                      )
                      .join("<br/>"),
              }),
              ...(termsAndConditions && { "Terms & Condition": termsAndConditions }),
              ...(winnerMessage && { "Message to Winner(s)": winnerMessage }),
              ...(loserMessage && { "Message to Non-Winner(s)": loserMessage }),
          }
        : null;

    const handleCancelEventDialogClose = () => {
        setIsCancelEventDialogOpen(false);
    };

    const handleCancelEvent = async () => {
        if (edgeEventId !== undefined) {
            try {
                await edgeRequests.cancelEdgeEvent(edgeEventId);
                onEventCancelSuccess();
            } catch (e) {
                if (e instanceof AxiosError && e.response?.data?.message) toast(e.response.data.message);
                else toast("Error canceling the event");
            } finally {
                handleCancelEventDialogClose();
            }
        }
    };
    const handleCopyEvent = () => {
        const mode: Mode = "copy";
        navigate("/create-edge-event", { state: { mode, event: eventDetail } });
    };
    const handleModifyEvent = () => {
        const mode: Mode = "modify";
        navigate("/edit-edge-event", { state: { mode, event: eventDetail } });
    };
    const handleNavigateBackToReports = () => navigate("/report");
    const companyLogoFile = convertEdgeEventMediaContentToMediaFileData("LOGO FILE", logoContent) ?? null;
    return (
        <EventDetailContext.Provider
            value={{
                event,
                eventDetail,
                statusIcon,
                companyLogoFile,
                detailsBoxData,
                isCancelEventDialogOpen,
                setIsCancelEventDialogOpen,
                hasDrawingStarted,
                hasRegistrationStarted,
                handleCopyEvent,
                handleModifyEvent,
                handleNavigateBackToReports,
                loadingEvent,
                loadingEventDetail,
                hasParticipantsData,
                handleParticipantsGridExport,
                handleCancelEventDialogClose,
                handleCancelEvent,
                handleDownloadQRCode,
                registrationLandingUrl,
                entryLandingUrl,
            }}
        >
            {children}
        </EventDetailContext.Provider>
    );
};
