import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import { EventDetailContext } from "../EventDetailProvider";

export const CancelEventDialog = () => {
    const { isCancelEventDialogOpen, handleCancelEventDialogClose, handleCancelEvent } =
        useContext(EventDetailContext) ?? {};
    return (
        <Dialog open={!!isCancelEventDialogOpen} onClose={handleCancelEventDialogClose}>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete this event?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelEventDialogClose} autoFocus>
                    No
                </Button>
                <Button onClick={handleCancelEvent}>Yes</Button>
            </DialogActions>
        </Dialog>
    );
};
