import { Components } from "@mui/material";

export const MuiCssBaseline = (): Components["MuiCssBaseline"] => ({
    styleOverrides: ` 
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
     input[type=number] {
      -moz-appearance: textfield;
    }`,
});
