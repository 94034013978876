import React from "react";

export const CopyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 25" fill="none">
            <path
                d="M9.8374 18.5459C9.2874 18.5459 8.81674 18.3502 8.4254 17.9589C8.03407 17.5676 7.83807 17.0966 7.8374 16.5459V4.5459C7.8374 3.9959 8.0334 3.52523 8.4254 3.1339C8.8174 2.74257 9.28807 2.54657 9.8374 2.5459H18.8374C19.3874 2.5459 19.8584 2.7419 20.2504 3.1339C20.6424 3.5259 20.8381 3.99657 20.8374 4.5459V16.5459C20.8374 17.0959 20.6417 17.5669 20.2504 17.9589C19.8591 18.3509 19.3881 18.5466 18.8374 18.5459H9.8374ZM9.8374 16.5459H18.8374V4.5459H9.8374V16.5459ZM5.8374 22.5459C5.2874 22.5459 4.81674 22.3502 4.4254 21.9589C4.03407 21.5676 3.83807 21.0966 3.8374 20.5459V7.5459C3.8374 7.26257 3.9334 7.02523 4.1254 6.8339C4.3174 6.64257 4.55474 6.54657 4.8374 6.5459C5.12007 6.54523 5.35774 6.64123 5.5504 6.8339C5.74307 7.02657 5.83874 7.2639 5.8374 7.5459V20.5459H15.8374C16.1207 20.5459 16.3584 20.6419 16.5504 20.8339C16.7424 21.0259 16.8381 21.2632 16.8374 21.5459C16.8367 21.8286 16.7407 22.0662 16.5494 22.2589C16.3581 22.4516 16.1207 22.5472 15.8374 22.5459H5.8374Z"
                fill="white"
            />
        </svg>
    );
};
