import { UnunploadedFileStatus } from "@dono-business/shared/components/UploadMedia";
import {
    EdgeEventModel,
    MediaFileData,
    PaymentMethodModel,
    RegisterEdgeEventFormFieldsModel,
    EdgeLogoContentModel,
} from "@dono-business/shared/models";
import { BucksBalanceModel } from "@dono-business/shared/models/bucks-balance-model";
import { GiftContentModel } from "@dono-business/shared/models/gift-content-model";
import { uuidv4 } from "@firebase/util";
import dayjs from "dayjs";
import React, { createContext, PropsWithChildren, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useBucksBalance } from "@dono-business/shared/screens/bucks";
import logo from "@dono-business/shared/images/logo.svg";
import {
    calculateAmountByGifts,
    convertEdgeEventPrizeDataToGift,
    convertRegistrationFieldsToLeadFieldData,
} from "./helpers";
import {
    MediaMessageError,
    GiftWithId,
    Mode,
    OnsiteContactWithId,
    RegistrationField,
    SubmitRequest,
    UseSubmitEventModifyParams,
    UseSubmitEventSharedParams,
} from "./types";
import { useCompany } from "@dono-business/shared/hooks/useCompany";
import { useRegistrationForm } from "./useRegistrationForm";
import { useSubmitEvent } from "./useSubmitEvent";
import {
    CreateEdgeEventGiftContent,
    CreateEdgeEventLogoContent,
} from "@dono-business/shared/models/edge-event-create-update-params";

export const CreateEdgeEventContext = createContext<{
    activeStep: number;
    setActiveStep: (step: number) => void;
    registerEventFormValues?: RegisterEdgeEventFormFieldsModel;
    mode: Mode;
    handleStep1Submit: () => void;
    registrationFormOptions: ReturnType<typeof useRegistrationForm>;
    companyLogoMediaId?: string;
    setCompanyLogoMediaId: (mediaId?: string) => void;
    companyLogoFile?: MediaFileData;
    setCompanyLogoFile: (file?: MediaFileData) => void;
    isCompanyLogoUploading: boolean;
    setIsCompanyLogoUploading: (isUploading: boolean) => void;
    hasCompanyLogoUnuploadedFile: UnunploadedFileStatus;
    setHasCompanyUnuploadedFile: (hasUnuploadedFile: UnunploadedFileStatus) => void;
    previousCompanyLogoMediaContent?: EdgeLogoContentModel;
    setPreviousCompanyLogoMediaContent: (content?: EdgeLogoContentModel) => void;
    giftsList: GiftWithId[];
    setGiftsList: (gifts: GiftWithId[] | ((prevGifts: GiftWithId[]) => GiftWithId[])) => void;
    giftsListError: string;
    setGiftsListError: (error: string) => void;
    isSubmittingRequest: boolean;
    submitRequest: SubmitRequest;
    event?: EdgeEventModel;
    setEvent: (event: EdgeEventModel) => void;
    registrationFields: RegistrationField[];
    setRegistrationFields: (
        fields: RegistrationField[] | ((fields: RegistrationField[]) => RegistrationField[]),
    ) => void;
    isWinnerMediaUploading: boolean;
    setIsWinnerMediaUploading: (isUploading: boolean) => void;
    hasWinnerMediaUnuploadedFile: UnunploadedFileStatus;
    setHasWinnerMediaUnuploadedFile: (hasUnuploadedFile: UnunploadedFileStatus) => void;
    setIsLoserMediaUploading: (isUploading: boolean) => void;
    hasLoserMediaUnuploadedFile: UnunploadedFileStatus;
    setHasLoserMediaUnuploadedFile: (hasUnuploadedFile: UnunploadedFileStatus) => void;
    isLoserMediaUploading: boolean;
    winnerFile?: MediaFileData;
    setWinnerFile: (file?: MediaFileData) => void;
    loserFile?: MediaFileData;
    setLoserFile: (file?: MediaFileData) => void;
    winnerMediaId?: string;
    setWinnerMediaId: (mediaId?: string) => void;
    loserMediaId?: string;
    setLoserMediaId: (mediaId?: string) => void;
    winnerMessage?: string;
    setWinnerMessage: (message: string) => void;
    loserMessage?: string;
    setLoserMessage: (message: string) => void;
    nonWinnerMessageError: MediaMessageError;
    winnerMessageError: MediaMessageError;
    setWinnerMessageError: (error: MediaMessageError) => void;
    setNonWinnerMessageError: (error: MediaMessageError) => void;
    rawAmountToPay: number;
    selectedPaymentMethod?: PaymentMethodModel;
    setSelectedPaymentMethod: (method?: PaymentMethodModel) => void;
    previousWinnerMediaContent?: GiftContentModel;
    setPreviousWinnerMediaContent: (content?: GiftContentModel) => void;
    previousLoserMediaContent?: GiftContentModel;
    setPreviousLoserMediaContent: (content?: GiftContentModel) => void;
    defaultRegistrationFieldsNames: string[];
    shouldUseBucksBalance: boolean;
    setShouldUseBucksBalance: (isUsed: boolean) => void;
    bucksBalance: BucksBalanceModel | null;
    previouslyPaidAmount: number;
    draggableLogoSrc?: string;

    onsiteContacts: OnsiteContactWithId[];
    setOnsiteContacts: React.Dispatch<React.SetStateAction<OnsiteContactWithId[]>>;
} | null>(null);

export const CreateEdgeEventProvider = ({ children }: PropsWithChildren) => {
    const {
        state,
    }: {
        state: null | {
            event: EdgeEventModel;
            mode: Mode;
        };
    } = useLocation();
    const { event: _event, mode: _mode } = state ?? {};
    const previousData = useRef(_event);
    const mode = useRef(_mode ?? "create");
    const registrationFormOptions = useRegistrationForm({
        defaultValues: previousData.current
            ? {
                  eventName: previousData.current?.name,
                  companyName: previousData.current?.company.name,
                  registrationPeriodStartDateAndTime: dayjs(previousData.current?.registrationStartDate),
                  registrationPeriodEndDateAndTime: dayjs(previousData.current?.registrationEndDate),
                  drawingTime: dayjs(previousData.current?.drawingDate),
                  termsAndConditions: previousData.current?.termsAndConditions ?? "",
              }
            : undefined,
    });

    const { watch, setValue } = registrationFormOptions;
    const registerEventFormValues = watch();
    const [onsiteContacts, setOnsiteContacts] = useState(
        (previousData.current?.onsiteContact?.map((contact) => ({ ...contact, id: uuidv4() })) ??
            []) as OnsiteContactWithId[],
    );

    const [companyLogoMediaId, setCompanyLogoMediaId] = useState(previousData.current?.logoContent?.logoContentId);
    const [companyLogoFile, setCompanyLogoFile] = useState<MediaFileData>();
    const [previousCompanyLogoMediaContent, setPreviousCompanyLogoMediaContent] = useState(
        previousData.current?.logoContent,
    );
    const [isCompanyLogoUploading, setIsCompanyLogoUploading] = useState(false);
    const [hasCompanyLogoUnuploadedFile, setHasCompanyUnuploadedFile] = useState<UnunploadedFileStatus>(false);

    const [winnerMediaId, setWinnerMediaId] = useState(previousData.current?.winnerMediaContent?.giftContentId);
    const [loserMediaId, setLoserMediaId] = useState(previousData.current?.loserMediaContent?.giftContentId);

    const [winnerMessage, setWinnerMessage] = useState(previousData.current?.winnerMessage);
    const [loserMessage, setLoserMessage] = useState(previousData.current?.loserMessage);
    const [nonWinnerMessageError, setNonWinnerMessageError] = useState<MediaMessageError>(undefined);
    const [winnerMessageError, setWinnerMessageError] = useState<MediaMessageError>(undefined);
    const defaultRegistrationFieldsNames = ["First Name", "Last Name", "Mobile Number", "Email"];
    const previousDataRegistrationFieldsNames = previousData.current?.leadFieldData?.map((field) => field.name);
    const fieldNamesConvertedToRegistrationFields = (
        previousDataRegistrationFieldsNames ?? defaultRegistrationFieldsNames
    ).map((fieldName) => ({
        name: fieldName,
        isActive: true,
        id: uuidv4(),
    }));
    const [registrationFields, setRegistrationFields] = useState<RegistrationField[]>(
        fieldNamesConvertedToRegistrationFields,
    );
    const leadFieldData = convertRegistrationFieldsToLeadFieldData(registrationFields);
    const [giftsList, setGiftsList] = useState<GiftWithId[]>(
        convertEdgeEventPrizeDataToGift(previousData.current?.edgeEventPrizeData ?? []),
    );
    const [giftsListError, setGiftsListError] = useState<string>("");

    const [winnerFile, setWinnerFile] = useState<MediaFileData>();
    const [loserFile, setLoserFile] = useState<MediaFileData>();

    const [isWinnerMediaUploading, setIsWinnerMediaUploading] = useState(false);
    const [isLoserMediaUploading, setIsLoserMediaUploading] = useState(false);
    const [hasWinnerMediaUnuploadedFile, setHasWinnerMediaUnuploadedFile] = useState<UnunploadedFileStatus>(false);
    const [hasLoserMediaUnuploadedFile, setHasLoserMediaUnuploadedFile] = useState<UnunploadedFileStatus>(false);

    const [previousWinnerMediaContent, setPreviousWinnerMediaContent] = useState(
        previousData.current?.winnerMediaContent,
    );
    const [previousLoserMediaContent, setPreviousLoserMediaContent] = useState(previousData.current?.loserMediaContent);

    const company = useCompany(previousData.current?.company);

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (company) setValue("companyName", company.name);
    }, [company]);

    const [event, setEvent] = useState(previousData.current);

    const handleStep1Submit = () => {
        setActiveStep(1);
    };

    const draggableLogoSrc =
        previousCompanyLogoMediaContent?.logoContentValue ??
        companyLogoFile?.filesContent[0].content ??
        company?.logo ??
        logo;

    const requestWinnerMediaContent =
        previousWinnerMediaContent &&
        previousWinnerMediaContent.giftContentId &&
        previousWinnerMediaContent.giftContentType
            ? (previousWinnerMediaContent as CreateEdgeEventGiftContent)
            : winnerFile?.type && winnerMediaId
            ? { giftContentId: winnerMediaId, giftContentType: winnerFile.type }
            : undefined;
    const requestLoserMediaContent =
        previousLoserMediaContent &&
        previousLoserMediaContent.giftContentId &&
        previousLoserMediaContent.giftContentType
            ? (previousLoserMediaContent as CreateEdgeEventGiftContent)
            : loserFile?.type && loserMediaId
            ? { giftContentId: loserMediaId, giftContentType: loserFile.type }
            : undefined;
    const requestCompanyLogoMediaContent =
        previousCompanyLogoMediaContent &&
        previousCompanyLogoMediaContent.logoContentId &&
        previousCompanyLogoMediaContent.logoContentType
            ? (previousCompanyLogoMediaContent as CreateEdgeEventLogoContent)
            : companyLogoFile?.type && companyLogoMediaId
            ? { logoContentId: companyLogoMediaId, logoContentType: companyLogoFile.type as "photo" }
            : undefined;

    const sharedParams: UseSubmitEventSharedParams = {
        registerEventFormValues,
        leadFieldData,
        giftsList,
        company,
        winnerMessage,
        loserMessage,
        winnerMediaContent: requestWinnerMediaContent,
        loserMediaContent: requestLoserMediaContent,
        companyLogoMediaContent: requestCompanyLogoMediaContent,
        onsiteContacts: onsiteContacts.map(({ id, ...contact }) => contact),
    };
    const modifyParams: UseSubmitEventModifyParams | null =
        previousData.current &&
        previousData.current.Id &&
        previousData.current.uniqueId &&
        previousData.current.createdByUserId &&
        previousData.current.status &&
        previousData.current.dateCreated
            ? {
                  Id: previousData.current?.Id,
                  uniqueId: previousData.current?.uniqueId,
                  createdByUserId: previousData.current?.createdByUserId,
                  status: previousData.current?.status,
                  dateCreated: previousData.current?.dateCreated,
              }
            : null;
    const { submitRequest, isSubmittingRequest } = useSubmitEvent(
        mode.current
            ? mode.current === "create" || mode.current === "copy"
                ? {
                      mode: mode.current,
                      params: sharedParams,
                  }
                : modifyParams
                ? {
                      mode: mode.current,
                      params: {
                          ...sharedParams,
                          ...modifyParams,
                      },
                  }
                : null
            : null,
    );

    const previouslyPaidAmount = useRef<number>(
        calculateAmountByGifts(convertEdgeEventPrizeDataToGift(previousData.current?.edgeEventPrizeData ?? [])),
    ).current;

    const rawAmountToPay = calculateAmountByGifts(giftsList);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodModel>();
    const [shouldUseBucksBalance, setShouldUseBucksBalance] = useState(false);
    const { bucksBalance } = useBucksBalance();
    useEffect(() => {
        setShouldUseBucksBalance(!!bucksBalance?.availableBalance?.value);
    }, [bucksBalance]);
    return (
        <CreateEdgeEventContext.Provider
            value={{
                registerEventFormValues,
                activeStep,
                setActiveStep,
                mode: mode.current,
                handleStep1Submit,
                registrationFormOptions,
                companyLogoFile,
                setCompanyLogoFile,
                companyLogoMediaId,
                setCompanyLogoMediaId,
                isCompanyLogoUploading,
                setIsCompanyLogoUploading,
                hasCompanyLogoUnuploadedFile,
                setHasCompanyUnuploadedFile,
                previousCompanyLogoMediaContent,
                setPreviousCompanyLogoMediaContent,
                giftsList,
                setGiftsList,
                giftsListError,
                setGiftsListError,
                isSubmittingRequest,
                submitRequest,
                event,
                setEvent,
                registrationFields,
                setRegistrationFields,
                isLoserMediaUploading,
                setIsLoserMediaUploading,
                isWinnerMediaUploading,
                setIsWinnerMediaUploading,
                hasWinnerMediaUnuploadedFile,
                setHasWinnerMediaUnuploadedFile,
                winnerFile,
                setWinnerFile,
                loserFile,
                hasLoserMediaUnuploadedFile,
                setHasLoserMediaUnuploadedFile,
                setLoserFile,
                winnerMediaId,
                setWinnerMediaId,
                loserMediaId,
                setLoserMediaId,
                winnerMessage,
                winnerMessageError,
                nonWinnerMessageError,
                setNonWinnerMessageError,
                setWinnerMessageError,
                setWinnerMessage,
                loserMessage,
                setLoserMessage,
                rawAmountToPay,
                selectedPaymentMethod,
                setSelectedPaymentMethod,
                previousLoserMediaContent,
                previousWinnerMediaContent,
                setPreviousWinnerMediaContent,
                setPreviousLoserMediaContent,
                defaultRegistrationFieldsNames,
                bucksBalance,
                shouldUseBucksBalance,
                setShouldUseBucksBalance,
                previouslyPaidAmount,
                draggableLogoSrc,

                onsiteContacts,
                setOnsiteContacts,
            }}
        >
            {children}
        </CreateEdgeEventContext.Provider>
    );
};
