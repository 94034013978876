import { getToken } from "@dono-business/shared/utils";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { CreateEdgeEventProvider } from "./CreateEdgeEventProvider";
import { Steps } from "./Steps";

export const CreateEdgeEventScreen = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/login");
        }
    }, [navigate]);
    return (
        <CreateEdgeEventProvider>
            <Steps />
        </CreateEdgeEventProvider>
    );
};
