import React, { useContext } from "react";
import { CreateEdgeEventContext } from "../CreateEdgeEventProvider";
import { ListUtilsProvider } from "components/list-utils";
import { RegistrationFieldsContent } from "./RegistrationFieldsContent";
import { RegistrationFieldsContext } from "./Context";
export const RegistrationFields = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { setRegistrationFields } = useContext(CreateEdgeEventContext)!;
    return (
        <ListUtilsProvider idField={"id"} setItems={setRegistrationFields} Context={RegistrationFieldsContext}>
            <RegistrationFieldsContent />
        </ListUtilsProvider>
    );
};
