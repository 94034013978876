import { useState, useEffect, useCallback } from "react";
import { AmountModel, EdgeEventModel } from "@dono-business/shared/models";
import { useAppRequests } from "@dono-business/shared/hooks";

export const useReportEventMoreDetail = (edgeEventId?: number) => {
    const { edgeRequests } = useAppRequests();

    const [reportEventMoreDetail, setReportEventMoreDetail] = useState<
        (EdgeEventModel & { amount: AmountModel }) | null
    >(null);
    const [loading, setLoading] = useState(false);
    const fetchData = useCallback(async () => {
        if (!edgeEventId) return;

        setLoading(true);
        try {
            const response = await edgeRequests.getEventReportMoreById(edgeEventId);
            if (response?.data) setReportEventMoreDetail(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [edgeEventId]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { reportEventMoreDetail, loading, fetchData };
};
