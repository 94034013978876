import React from "react";

export const ClockIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="mdi:clock-outline">
                <path
                    id="Vector"
                    d="M12.3993 20.8379C14.521 20.8379 16.5559 19.995 18.0561 18.4947C19.5564 16.9945 20.3993 14.9596 20.3993 12.8379C20.3993 10.7162 19.5564 8.68133 18.0561 7.18104C16.5559 5.68075 14.521 4.83789 12.3993 4.83789C10.2776 4.83789 8.24273 5.68075 6.74244 7.18104C5.24215 8.68133 4.39929 10.7162 4.39929 12.8379C4.39929 14.9596 5.24215 16.9945 6.74244 18.4947C8.24273 19.995 10.2776 20.8379 12.3993 20.8379ZM12.3993 2.83789C13.7125 2.83789 15.0129 3.09655 16.2261 3.5991C17.4394 4.10164 18.5418 4.83824 19.4704 5.76682C20.3989 6.69541 21.1355 7.7978 21.6381 9.01106C22.1406 10.2243 22.3993 11.5247 22.3993 12.8379C22.3993 15.4901 21.3457 18.0336 19.4704 19.909C17.595 21.7843 15.0515 22.8379 12.3993 22.8379C6.86929 22.8379 2.39929 18.3379 2.39929 12.8379C2.39929 10.1857 3.45286 7.64219 5.32822 5.76682C7.20359 3.89146 9.74713 2.83789 12.3993 2.83789ZM12.1493 7.83789C12.5635 7.83789 12.8993 8.17368 12.8993 8.58789V12.5184C12.8993 12.8715 13.0854 13.1983 13.389 13.3785L16.7739 15.3868C17.1181 15.591 17.229 16.0371 17.0207 16.3788C16.8151 16.7159 16.3761 16.824 16.0376 16.6209L11.8848 14.1292C11.5836 13.9485 11.3993 13.623 11.3993 13.2717V8.58789C11.3993 8.17368 11.7351 7.83789 12.1493 7.83789Z"
                    fill="#B993C2"
                />
            </g>
        </svg>
    );
};
