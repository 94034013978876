import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { EdgeEventModel } from "@dono-business/shared/models";
import { useAppRequests } from "@dono-business/shared/hooks";
const styles = {
    container: {
        padding: "40px 0",
        background: "#EBF0F5",
        height: "100vh",
    },
    h1: {
        color: "#88B04B",
        fontWeight: 900,
        fontSize: "40px",
        marginBottom: "10px",
    },
    p: {
        color: "#404F5E",
        fontSize: "20px",
        margin: "0",
    },
    i: {
        color: "#9ABC66",
        fontSize: "100px",
        lineHeight: "200px",
        marginLeft: "-15px",
    },
    card: {
        background: "white",
        padding: "60px",
        borderRadius: "4px",
        boxShadow: "0 2px 3px #C8D0D8",
        display: "inline-block",
        margin: "0 auto",
        minWidth: "450px",
    },
};

export const PaymentResultScreen = () => {
    const { edgeRequests } = useAppRequests();

    const { result } = useParams();

    const [queryParams] = useSearchParams();
    const eventUniqueId = queryParams.get("eventUniqueId");

    const [event, setEvent] = useState<EdgeEventModel>();

    useEffect(() => {
        if (eventUniqueId) {
            edgeRequests.getEdgeEventByUniqueId(eventUniqueId).then((res) => {
                setEvent(res.data);
            });
        }
    }, [eventUniqueId]);

    const iconStyles = { ...styles.i };
    let message = <></>;
    let color = "";
    if (result === "successful") {
        message = (
            <>
                <p>Your Edge Event has been created.</p>
                <p>You can visit the edge web page by this link.</p>
                <br />
                <Box display="flex" gap={1}>
                    <Button
                        className="payment-button"
                        href={`${window.location.origin}/landing/entry/${eventUniqueId}`}
                        target="_blank"
                    >
                        Go to Landing Page
                    </Button>
                    <Button
                        className="payment-button"
                        href={`${window.location.origin}/report/events/${event?.Id}`}
                        target="_blank"
                    >
                        Go to Detail Page
                    </Button>
                </Box>
            </>
        );
        color = "#88B04B";
    } else {
        iconStyles.color = "#E94B3B";
        message = (
            <>
                <p>Payment failed. Please try again.</p>
                <Button className="payment-button" onClick={() => (window.location.href = window.location.origin)}>
                    Back to Home
                </Button>
            </>
        );
        color = "#E94B3B";
    }

    return (
        <Box sx={{ textAlign: "center" }} style={styles.container}>
            <div style={styles.card}>
                <div
                    style={{
                        borderRadius: "200px",
                        height: "200px",
                        width: "200px",
                        background: "#F8FAF5",
                        margin: "0 auto",
                    }}
                >
                    {result === "successful" ? <i style={iconStyles}>✓</i> : <i style={iconStyles}>❌</i>}
                </div>
                <h1 style={{ color }}>{result === "successful" ? "Success" : "Failed"}</h1>
                {message}
            </div>
        </Box>
    );
};
