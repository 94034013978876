import React, { PropsWithChildren, ReactNode } from "react";
import { Button } from "@dono-business/shared/components";
import {
    Box,
    ButtonProps,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Grid,
    GridProps,
    IconButton,
    SnackbarContent,
    SxProps,
    Typography,
    TypographyProps,
    DialogContent,
    styled,
} from "@mui/material";
import { ModifyIcon, RemoveOutlinedIcon } from "@dono-business/shared/icons";
import { Snackbar } from "@mui/material";
import { Close } from "@mui/icons-material";
export const SmallButton = styled(Button)({
    width: "96px",
    height: "44px",
    fontWeight: "600",
    lineHeight: 1,
});

export const SaveButton = styled(SmallButton)(({ theme }) => ({
    background: theme.palette.background.active,
    "&.Mui-disabled": { background: "#cacaca" },
}));
export const StyledSnackbarContent = styled(SnackbarContent)({
    paddingTop: "4px !important",
    paddingBottom: "4px !important",
    borderRadius: "4px",
    boxShadow: "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)",
});
export const SavedItemLabel = styled(Typography)(({ theme }) => ({
    fontWeight: "700",
    lineHeight: "normal",
    display: "inline-block",
    width: "92px",
    [theme.breakpoints.down("sm")]: { width: "60px" },
}));
export const SavedItemValue = styled(Typography)({
    lineHeight: "normal",
});

export const SavedItemGridContainer = (props: GridProps & { hideActions?: boolean }) => {
    const { hideActions, sx, ...gridProps } = props;
    return (
        <Grid
            container
            sx={[
                {
                    ...(hideActions
                        ? { ".actions": { display: "none" } }
                        : {
                              ".actions": { visibility: "hidden" },
                              ":hover": {
                                  background: "#fcfcfc",
                                  ".actions": { visibility: "visible" },
                              },
                          }),
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...gridProps}
        />
    );
};
export const SavedItemActionsContainer = (props: GridProps) => {
    const { sx, ...gridProps } = props;

    return (
        <Grid
            xs={12}
            sm={"auto"}
            item
            sx={[
                {
                    display: "flex",
                    justifyContent: { md: "space-between", xs: "flex-end" },
                    alignItems: "center",
                    px: "15px",
                    gap: { xs: 0, md: "44px" },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            className="actions"
            {...gridProps}
        />
    );
};
export const SavedItemActionIconButton = styled(IconButton)({
    padding: ".4rem",
    "&.Mui-disabled": { opacity: ".6" },
});
export const SavedItemRemoveButtonWrapper = ({ children }: PropsWithChildren) => (
    <Box component={"span"} p="3px" display={"flex"}>
        {children}
    </Box>
);

export const FormGridContainer = (props: GridProps & { onClickAway?: () => void }) => {
    const { sx, onClickAway, ...gridProps } = props;
    return (
        <ClickAwayListener onClickAway={onClickAway ?? (() => null)}>
            <Grid
                container
                component={"form"}
                sx={[
                    {
                        borderRadius: "25px",
                        background: "#fcfcfc",
                        p: { xs: "32px 20px 42px 20px", md: "32px 87px 52px 52px" },
                    },
                    ...(Array.isArray(sx) ? sx : [sx]),
                ]}
                {...gridProps}
            />
        </ClickAwayListener>
    );
};

export const FormTitle = ({ sx, ...typographyProps }: TypographyProps) => (
    <Typography
        sx={[
            {
                color: (theme) => theme.palette.text.primary,
                fontWeight: "700",
                fontSize: "16px",
                position: "relative",
                lineHeight: "normal",
            },
            ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...typographyProps}
    />
);

export const FormButtonsContainer = (props: GridProps) => {
    const { sx, ...gridProps } = props;
    return (
        <Grid
            xs={12}
            item
            sx={[{ display: "flex", justifyContent: "flex-end", gap: "24px" }, ...(Array.isArray(sx) ? sx : [sx])]}
            {...gridProps}
        />
    );
};
export const NewItemButton = ({ sx, ...buttonProps }: ButtonProps) => (
    <Button
        fullWidth={false}
        variant="contained"
        sx={[
            {
                width: "164px",
                fontWeight: "600",
                background: (theme) => theme.palette.background.active,
                height: "44px",
                ":hover": { background: (theme) => theme.palette.background.active },
            },
            ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...buttonProps}
    />
);

export const SavedItem = <Item,>({
    item,
    onDeleteButtonClick,
    onEditButtonClick,
    disableDelete,
    disableEdit,
    isEditing,
    renderEditMode,
    renderItem,
    containerSx,
}: {
    item: Item;
    onEditButtonClick: (item: Item) => void;
    onDeleteButtonClick: (item: Item) => void;
    disableDelete?: boolean;
    disableEdit?: boolean;
    isEditing: boolean;
    renderEditMode: (item: Item) => ReactNode;
    renderItem: (item: Item) => ReactNode;
    containerSx?: SxProps;
}) => {
    const handleDeleteButtonClick = () => onDeleteButtonClick(item);
    const handleEditButtonClick = () => onEditButtonClick(item);
    return (
        <SavedItemGridContainer hideActions={isEditing} sx={containerSx}>
            <Grid item xs={12} sm display={"flex"} alignSelf={"center"}>
                {isEditing ? renderEditMode(item) : renderItem(item)}
            </Grid>
            <SavedItemActionsContainer>
                <SavedItemActionIconButton onClick={handleEditButtonClick} disabled={disableEdit}>
                    <ModifyIcon />
                </SavedItemActionIconButton>
                <SavedItemActionIconButton onClick={handleDeleteButtonClick} disabled={disableDelete}>
                    <SavedItemRemoveButtonWrapper>
                        <RemoveOutlinedIcon />
                    </SavedItemRemoveButtonWrapper>
                </SavedItemActionIconButton>
            </SavedItemActionsContainer>
        </SavedItemGridContainer>
    );
};
export const ItemDeleteSnack = ({
    isShowing,
    handleClose,
    handleUndo,
    message,
}: {
    isShowing: boolean;
    handleClose: () => void;
    handleUndo: () => void;
    message: string;
}) => (
    <Snackbar
        open={isShowing}
        onClose={handleClose}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
        <SnackbarContent
            message={message}
            action={
                <>
                    <Box
                        onClick={handleUndo}
                        component={"span"}
                        sx={{ textDecoration: "underline", cursor: "pointer", mr: "15px" }}
                    >
                        UNDO
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close htmlColor="#fff" />
                    </IconButton>
                </>
            }
            sx={{ background: "#1c1c1e", width: "344px" }}
        />
    </Snackbar>
);

export const ItemEditSnack = ({
    isShowing,
    handleClose,
    message,
}: {
    isShowing: boolean;
    handleClose: () => void;
    message: string;
}) => (
    <Snackbar
        open={isShowing}
        onClose={handleClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
        <StyledSnackbarContent
            message={message}
            action={
                <>
                    <IconButton onClick={handleClose}>
                        <Close htmlColor="#fff" />
                    </IconButton>
                </>
            }
            sx={{ background: "#207B00" }}
        />
    </Snackbar>
);
export const ItemSaveSnack = ({
    isShowing,
    handleClose,
    message,
}: {
    isShowing: boolean;
    handleClose: () => void;
    message: string;
}) => (
    <Snackbar
        open={isShowing}
        onClose={handleClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
        <StyledSnackbarContent
            message={message}
            action={
                <>
                    <IconButton onClick={handleClose}>
                        <Close htmlColor="#fff" />
                    </IconButton>
                </>
            }
            sx={{ background: "#207B00" }}
        />
    </Snackbar>
);
export const UnsavedItemDialog = ({
    onClose,
    isOpen,
    text,
    title,
}: {
    onClose: () => void;
    isOpen: boolean;
    title: string;
    text: string;
}) => {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text} </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
    );
};
