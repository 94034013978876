import { useState, useEffect } from "react";
import { EdgeReportGetAllEventModel } from "@dono-business/shared/models";
import { useAppRequests } from "@dono-business/shared/hooks";

export const useReportEvents = () => {
    const { edgeRequests } = useAppRequests();

    const [reportEvents, setReportEvents] = useState<EdgeReportGetAllEventModel[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await edgeRequests.getEventsReport();
                if (response?.data?.items) setReportEvents(response.data.items);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { reportEvents, loading };
};
