const storageKey = "user";

export const login = (id: number, phone: string, xtoken: string, email?: string) => {
    const user = {
        id,
        phone,
        xtoken,
        email,
    };

    localStorage.setItem(storageKey, JSON.stringify(user));
};

export const logout = () => {
    localStorage.removeItem(storageKey);
    window.location.href = window.location.origin;
};

export const getUser = () => {
    const data = localStorage.getItem(storageKey);

    return data && JSON.parse(data);
};

export const getToken = () => {
    const data = getUser();

    return data && data["xtoken"] ? data["xtoken"] : undefined;
};

export const getEmailValidationRegex = () =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
