import { useAppRequests } from "@dono-business/shared/hooks";
import { EdgeEventGiftValueType, EdgeEventCreateParams, EdgeEventPrizeData } from "@dono-business/shared/models";

import { useState } from "react";
import { SubmitRequest, UseSubmitEventModifyParams, UseSubmitEventSharedParams } from "./types";

type SubmitEventOptions =
    | { mode: "create" | "copy"; params: UseSubmitEventSharedParams }
    | { mode: "modify"; params: UseSubmitEventSharedParams & UseSubmitEventModifyParams }
    | null;
export const useSubmitEvent = (options: SubmitEventOptions) => {
    const { edgeRequests } = useAppRequests();

    const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);
    const { mode, params } = options ?? {};
    const {
        registerEventFormValues,
        leadFieldData,
        giftsList,
        company,
        winnerMessage,
        loserMessage,
        winnerMediaContent,
        loserMediaContent,
        companyLogoMediaContent,
        onsiteContacts,
    } = params ?? {};
    const getSharedParams: () => EdgeEventCreateParams | null = () => {
        const {
            eventName,
            registrationPeriodStartDateAndTime,
            registrationPeriodEndDateAndTime,
            drawingTime,
            termsAndConditions,
        } = registerEventFormValues ?? {};
        const edgeEventPrizeData =
            giftsList &&
            giftsList.map((gift) => ({
                type: gift.type,
                quantity: +gift.quantity,
                ...(gift.type === EdgeEventGiftValueType.DonoGift
                    ? { amount: { value: +gift.value, currencyCode: "USD", symbol: "$" } }
                    : { name: gift.value }),
            }));

        if (
            registerEventFormValues &&
            eventName &&
            registrationPeriodStartDateAndTime &&
            registrationPeriodEndDateAndTime &&
            drawingTime &&
            company &&
            leadFieldData
        ) {
            const params: EdgeEventCreateParams = {
                name: eventName,
                company,
                registrationStartDate: registrationPeriodStartDateAndTime.toISOString(),
                registrationEndDate: registrationPeriodEndDateAndTime.toISOString(),
                drawingDate: drawingTime.toISOString(),
                termsAndConditions,
                leadFieldData,
                type: "custom",
                edgeEventPrizeData: edgeEventPrizeData as EdgeEventPrizeData,
                ...(onsiteContacts && { onsiteContact: onsiteContacts }),
                ...(companyLogoMediaContent && { logoContent: companyLogoMediaContent }),
                ...(winnerMessage && { winnerMessage }),
                ...(loserMessage && { loserMessage }),
                ...(winnerMediaContent && { winnerMediaContent }),
                ...(loserMediaContent && { loserMediaContent }),
            };

            return params;
        }
        return null;
    };

    const submitRequest: SubmitRequest = async ({ onCreateSuccess, onModifySuccess }) => {
        const sharedParams = getSharedParams();
        if (sharedParams) {
            setIsSubmittingRequest(true);
            try {
                if (mode === "modify") {
                    if (params) {
                        const { Id, uniqueId, createdByUserId, status, dateCreated } = params;
                        await edgeRequests.updateEdgeEvent({
                            ...sharedParams,
                            Id,
                            uniqueId,
                            createdByUserId,
                            status,
                            dateCreated,
                        });
                        onModifySuccess();
                    }
                } else {
                    const resp = await edgeRequests.createEdgeEvent(sharedParams);
                    const event = resp.data;
                    onCreateSuccess(event);
                }
            } finally {
                setIsSubmittingRequest(false);
            }
        }
    };
    return { submitRequest, isSubmittingRequest };
};
