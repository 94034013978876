import React from "react";
import { Box, Button, Divider, IconButton, Popover, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { getUser } from "@dono-business/shared/utils";
import LogoutIcon from "@mui/icons-material/Logout";
import { AccountIcon } from "@dono-business/shared/icons";

export const UsernameWithSignoutButton = () => {
    const user = getUser();
    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate("/login");
    };
    const redirectToBucks = () => {
        navigate("/bucks");
        handleClose();
    };
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "username" : undefined;
    return (
        <>
            <Button
                variant="text"
                aria-describedby={id}
                onClick={handleClick}
                fullWidth={false}
                sx={{ display: "flex", alignItems: "center", gap: 0.75 }}
            >
                <AccountIcon />
                <Typography variant="label" mr={1} sx={{ color: "#0C2145", fontSize: 16, lineHeight: "16px" }}>
                    {user?.email || user?.phone}
                </Typography>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 40,
                    horizontal: "center",
                }}
                PaperProps={{ sx: { minWidth: "150px" } }}
            >
                <Button
                    onClick={redirectToBucks}
                    variant="text"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pl: 2,
                        cursor: "pointer",
                    }}
                >
                    <Typography>DONO Bucks</Typography>
                </Button>{" "}
                <Divider />
                <Button
                    onClick={logout}
                    variant="text"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pl: 2,
                        gap: 3,
                        cursor: "pointer",
                    }}
                >
                    <Typography>Logout</Typography>
                    <IconButton>
                        <LogoutIcon color="error" />
                    </IconButton>
                </Button>{" "}
            </Popover>
        </>
    );
};
