import * as requests from "@dono-business/shared/requests";
import { baseUrl } from "config";
import customAxios from "./customAxios";

const authRequests = requests.authRequests(baseUrl);
const edgeRequests = requests.edgeRequests(baseUrl, customAxios);
const giftRequests = requests.giftRequests(baseUrl, customAxios);
const uploadRequests = requests.uploadRequests(baseUrl, customAxios);
const userRequests = requests.userRequests(baseUrl, customAxios);
const bucksRequests = requests.bucksRequests(baseUrl, customAxios);

export { authRequests, edgeRequests, giftRequests, uploadRequests, userRequests, bucksRequests };
