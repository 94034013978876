import { Box, capitalize } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { EventDetailContext } from "../EventDetailProvider";
import dayjs from "dayjs";
import { StarIcon, ClockIcon, ExclamationMarkIcon, CheckCircleIcon } from "@dono-business/shared/icons";
import { systemTimezoneValue } from "@dono-business/shared/utils";

export const ImportantDetails = () => {
    const timezone = systemTimezoneValue;
    const { eventDetail, statusIcon } = useContext(EventDetailContext) ?? {};
    const { drawingDate, status, missingFields } = eventDetail ?? {};
    const hasMissingInformation = missingFields?.length;
    const data = [
        {
            key: "missingInformation",
            icon: hasMissingInformation ? <ExclamationMarkIcon /> : <CheckCircleIcon />,
            label: (
                <Box component="span" fontWeight={"800"}>
                    {hasMissingInformation ? "Missing Information" : "Complete"}
                </Box>
            ),
        },
        {
            key: "drawingDate",
            icon: <StarIcon />,
            label: `Drawing Date: ${drawingDate ? dayjs(drawingDate).format("MM/DD/YYYY") : "-"}`,
        },
        {
            key: "drawingTime",

            icon: <ClockIcon />,
            label: `Drawing Time: ${drawingDate ? `${dayjs(drawingDate).format("h:mm A")} (${timezone})` : "-"}`,
        },
        {
            key: "status",

            icon: <img src={statusIcon ?? ""} alt={status} width={"20px"} height={"20px"} />,
            label: status ? capitalize(status) : "-",
        },
    ];
    return (
        <Grid container justifyContent={"center"}>
            <Grid item container xs={"auto"} direction={"column"} rowGap={"16px"} width={"fit-content"}>
                {data.map(({ icon, label, key }) => (
                    <Grid item display={"flex"} alignItems="center" gap={"10px"} key={key}>
                        <Box
                            sx={{
                                width: "24px",
                                height: "24px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {icon}
                        </Box>
                        <Typography sx={{ color: (theme) => theme.palette.text.darker, lineHeight: "1.5" }}>
                            {label}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};
