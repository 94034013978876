import { MediaFileData } from "@dono-business/shared/models";
import { Box } from "@mui/system";
import React from "react";
const idealPersonalizeImageWidth = 140;
const idealPersonalizeImageHeight = 180;
type Props = {
    fileData?: MediaFileData;
    width?: number | string;
    height?: number | string;
};

export const MediaPreview = ({ fileData, width, height }: Props) => {
    if (!fileData) return null;
    const { filesContent, type: giftContentType } = fileData;
    const _width = width ?? idealPersonalizeImageWidth;
    const _height = height ?? idealPersonalizeImageHeight;
    return (
        <>
            {!!filesContent && !!filesContent.length && (
                <Box
                    border={"1px solid #cbcbcb"}
                    borderRadius={2}
                    width={_width}
                    height={_height}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    overflow={"hidden"}
                >
                    {filesContent?.map(
                        (file, index) =>
                            (giftContentType === "photo" && (
                                <img
                                    style={{
                                        height: "100%",
                                        maxWidth: "100%",
                                        objectFit: "contain",
                                    }}
                                    key={index}
                                    alt={file.name}
                                    src={file.content}
                                />
                            )) ||
                            (giftContentType === "video" && (
                                <video
                                    style={{
                                        height: "100%",
                                        maxWidth: "100%",
                                        objectFit: "contain",
                                    }}
                                    key={index}
                                    src={file.content}
                                    controls
                                />
                            )),
                    )}
                </Box>
            )}
        </>
    );
};
