import React from "react";

export const EventCancelIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
            <path
                d="M9.94775 3V4H4.94775V6H5.94775V19C5.94775 19.5304 6.15847 20.0391 6.53354 20.4142C6.90861 20.7893 7.41732 21 7.94775 21H17.9478C18.4782 21 18.9869 20.7893 19.362 20.4142C19.737 20.0391 19.9478 19.5304 19.9478 19V6H20.9478V4H15.9478V3H9.94775ZM7.94775 6H17.9478V19H7.94775V6ZM9.94775 8V17H11.9478V8H9.94775ZM13.9478 8V17H15.9478V8H13.9478Z"
                fill="white"
            />
        </svg>
    );
};
