import { OnsiteContactFormFieldsModel } from "@dono-business/shared/models";
import React from "react";
import { useOnsiteContactForm } from "./useOnsiteContactsForm";
import { FormControl, Grid, TextField, capitalize } from "@mui/material";
import { Controller } from "react-hook-form";
import { validateUSPhoneNumber } from "@dono-business/shared/utils";
import {
    FormButtonsContainer,
    FormGridContainer,
    FormTitle,
    SaveButton,
    SmallButton,
    UnsavedItemDialog,
    useUnsavedItem,
} from "../../../components/list-utils";

type Props = {
    mode: "add" | "edit";
    defaultValues?: OnsiteContactFormFieldsModel;
    onSubmit: (values: OnsiteContactFormFieldsModel) => void;
    onCancel: () => void;
    nonAllowedPhoneNumbers: string[];
};

export const OnsiteContactForm = ({ defaultValues, onSubmit, onCancel, nonAllowedPhoneNumbers, mode }: Props) => {
    const {
        control,
        handleSubmit,
        formState: { isValid },
        trigger,
    } = useOnsiteContactForm({ defaultValues });
    const handleFormSubmit = (data: OnsiteContactFormFieldsModel) => {
        onSubmit(data);
    };
    const handleCancelButtonClick = () => {
        onCancel();
    };
    const isPhoneNumberAllowed = (phoneNumber: string) =>
        nonAllowedPhoneNumbers?.find((_phoneNumber) => _phoneNumber === phoneNumber) === undefined;

    const { handleFormBlur, handleFormClick, unsavedDialogOpen, toggleUnsavedDialogOpen } = useUnsavedItem({
        isFormValid: async () => await trigger(),
    });
    return (
        <>
            <FormGridContainer
                onSubmit={handleSubmit(handleFormSubmit)}
                onClick={handleFormClick}
                onClickAway={handleFormBlur}
            >
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: "35px",
                    }}
                >
                    <FormTitle>{capitalize(mode)} Contact</FormTitle>
                </Grid>

                <Grid item container xs={12} columnSpacing={"16px"} rowSpacing={{ xs: "25px", md: "42px" }} mb="24px">
                    <Grid item xs={12} md={6}>
                        {" "}
                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name={"firstName"}
                                rules={{ required: "Please enter contact’s first name." }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Enter First Name"
                                        label="First Name"
                                        autoComplete="off"
                                        error={Boolean(error)}
                                        helperText={error?.message ?? ""}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name={"lastName"}
                                rules={{ required: "Please enter contact’s last name." }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Enter Last Name"
                                        label="Last Name"
                                        autoComplete="off"
                                        error={Boolean(error)}
                                        helperText={error?.message ?? ""}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} position="relative">
                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name={"phoneNumber"}
                                rules={{
                                    required: "Mobile Number is mandatory",
                                    validate: {
                                        isValidNumber: (value) =>
                                            validateUSPhoneNumber(value) || "Please enter a valid number",
                                        isAllowed: (value) =>
                                            isPhoneNumberAllowed(value) || "This mobile number is already added",
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        error={Boolean(error)}
                                        helperText={error?.message}
                                        placeholder="Enter mobile number"
                                        label="Mobile Number"
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>{" "}
                </Grid>
                <FormButtonsContainer>
                    <SmallButton variant="outlined" onClick={handleCancelButtonClick}>
                        Cancel
                    </SmallButton>
                    <SaveButton variant="contained" type="submit" disabled={!isValid}>
                        Save
                    </SaveButton>
                </FormButtonsContainer>
            </FormGridContainer>

            <UnsavedItemDialog
                title={"Unsaved Contact"}
                isOpen={unsavedDialogOpen}
                onClose={() => toggleUnsavedDialogOpen(false)}
                // eslint-disable-next-line quotes
                text={'In order to save the contact info, please click on the "Save" button.'}
            />
        </>
    );
};
