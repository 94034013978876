import React from "react";
import { Button } from "@mui/material";
import { BackIcon } from "@dono-business/shared/icons";
type StepBackButtonProps = {
    onClick: () => void;
};
export const StepBackButton = ({ onClick }: StepBackButtonProps) => (
    <Button
        onClick={onClick}
        fullWidth={false}
        variant="text"
        sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: 80 }}
    >
        <BackIcon />
        Back
    </Button>
);
