import { RegistrationFieldFormFieldsModel } from "@dono-business/shared/models/";
import { useForm } from "react-hook-form";

export const useRegistrationFieldsForm = ({ defaultValues }: { defaultValues?: RegistrationFieldFormFieldsModel }) => {
    const formOptions = useForm<RegistrationFieldFormFieldsModel>({
        mode: "all",
        defaultValues: defaultValues ?? { name: "" },
    });
    return formOptions;
};
