import React from "react";
import { ButtonProps } from "./";
import { MdOutlineFileDownload } from "react-icons/md";
import { useSmallScreenSize } from "@dono-business/shared/hooks";
import { ButtonWithIcon } from "./ButtonWithIcon";

export const ExportButton = (props: ButtonProps) => {
    const isSmallScreen = useSmallScreenSize();
    return (
        <ButtonWithIcon {...props}>
            {!isSmallScreen && <MdOutlineFileDownload fontSize={"18"} />}
            Export
        </ButtonWithIcon>
    );
};
