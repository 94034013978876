import { fontFamily } from "@dono-business/shared/theme/system/typography";
import { Components, Theme } from "@mui/material";

export const MuiButtonBase = (): Components["MuiButtonBase"] => ({
    styleOverrides: {
        root: {
            borderRadius: 8,
        },
    },
});

export const MuiButton = (theme: Theme): Components["MuiButton"] => ({
    styleOverrides: {
        root: {
            fontFamily,
            fontSize: 16,
            borderRadius: 8,
            textTransform: "none",

            "&.Mui-disabled": {
                color: theme.palette.background.paper,
            },
        },
        outlined: {
            fontWeight: "normal",
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.background.active}`,
            "&:hover": {
                backgroundColor: theme.palette.background.paper,
            },
        },
        contained: {
            background: "linear-gradient(314.1deg, #434E82 0%, #061C3F 98.17%)",
            "&:hover": {
                background: "linear-gradient(314.1deg, #434E82 0%, #061C3F 98.17%)",
            },
            "&.Mui-disabled": {
                color: theme.palette.text.contrast,
                background: theme.palette.background.disabled,
            },
        },
        text: {
            color: theme.palette.text.primary,
            border: "none",
        },
        sizeMedium: {
            padding: 9,
            height: 48,
        },
    },
    defaultProps: {
        fullWidth: true,
        size: "medium",
        variant: "contained",
    },
});
