import { CircularProgress } from "@mui/material";
import { BoxProps } from "@mui/system";
import React from "react";

import { SpinnerContainer, StyledBox } from "./StepContentContainer.styles";

export const StepContentContainer = ({ isLoading, children, ...props }: BoxProps & { isLoading?: boolean }) => {
    return (
        <StyledBox {...props}>
            {isLoading && (
                <SpinnerContainer>
                    <CircularProgress />
                </SpinnerContainer>
            )}
            {children}
        </StyledBox>
    );
};
