import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
export const wait = (timeout = 2000) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({});
        }, timeout);
    });
};

export function formatDateForInput(date: Date) {
    const year = date.getFullYear().toString().padStart(4, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hour}:${minute}`;
}

export function convertToUTC(dateTimeLocalString: string) {
    // Create a new Date object from the input string
    const dateObj = new Date(dateTimeLocalString);

    // Get the UTC time in ISO format
    const utcTimeString = dateObj.toISOString();

    return utcTimeString;
}

export const systemTimezoneValue = dayjs.tz.guess();
export type DatePickerTimezoneOption = { value: string; label: string; isSystemTimezone?: boolean };
export const getDatePickerTimezoneOptions = () => {
    let timezones: DatePickerTimezoneOption[] = [
        {
            value: "America/New_York",
            label: "Eastern (-04:00)",
        },

        {
            value: "America/Chicago",
            label: "Central (-05:00)",
        },

        {
            value: "America/Denver",
            label: "Mountain (-06:00)",
        },

        {
            value: "America/Los_Angeles",
            label: "Pacific (-07:00)",
        },
    ];
    let systemTimezone = timezones.find((tz) => tz.value === systemTimezoneValue);
    if (systemTimezone) {
        systemTimezone.isSystemTimezone = true;
    } else {
        systemTimezone = { label: systemTimezoneValue, value: systemTimezoneValue, isSystemTimezone: true };
        timezones = [systemTimezone, ...timezones];
    }
    return timezones;
};

export const genericDateAndTimeFormatter = <D extends string | number | Date>(
    date: D,
    timeZone = systemTimezoneValue,
    showTimezone = true,
    dateTransformer: (date: unknown) => unknown = (date) => date,
) => {
    const timezoneLabel = (getDatePickerTimezoneOptions().find((option) => option.value === timeZone)?.label ?? "")
        .replaceAll("(", "")
        .replaceAll(")", "");
    const dateObject = dateTransformer(date);
    const isValid = typeof dateObject === "string" || typeof dateObject === "number" || dateObject instanceof Date;
    const formattedDateTime = (isValid ? new Date(dateObject) : new Date()).toLocaleString(undefined, {
        timeStyle: "short",
        dateStyle: "short",
        timeZone,
    });
    return `${formattedDateTime} ${showTimezone ? `(${timezoneLabel})` : ""}`;
};
