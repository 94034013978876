import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { WideButtonWithIcon } from "./styles";
import { MdOutlineFileDownload } from "react-icons/md";
import { EventDetailContext } from "../EventDetailProvider";
import { ParticipantsGrid } from "./ParticipantsGrid";

export const Participants = () => {
    const { handleParticipantsGridExport, hasParticipantsData } = useContext(EventDetailContext) ?? {};
    const handleExportButtonClick = () => handleParticipantsGridExport?.();
    return (
        <Grid container direction={"column"} alignItems={"center"}>
            <Grid item my={"29px"}>
                <WideButtonWithIcon
                    sx={{ width: "228px" }}
                    onClick={handleExportButtonClick}
                    disabled={!hasParticipantsData}
                >
                    <MdOutlineFileDownload fontSize={"18"} />
                    Export Participant Data{" "}
                </WideButtonWithIcon>
            </Grid>
            <Grid item width="100%">
                <ParticipantsGrid />
            </Grid>
        </Grid>
    );
};
