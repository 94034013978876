import { useState, useEffect, useCallback } from "react";
import { EdgeReportEventModel } from "@dono-business/shared/models";
import { useAppRequests } from "@dono-business/shared/hooks";

export const useReportEventDetail = (edgeEventId?: number) => {
    const { edgeRequests } = useAppRequests();

    const [reportEventDetail, setReportEventDetail] = useState<EdgeReportEventModel | null>(null);
    const [loading, setLoading] = useState(false);
    const fetchData = useCallback(async () => {
        if (!edgeEventId) return;

        setLoading(true);
        try {
            const response = await edgeRequests.getEventReportById(edgeEventId);
            if (response?.data) setReportEventDetail(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [edgeEventId]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { reportEventDetail, loading, fetchData };
};
