import { EdgeEventModel } from "@dono-business/shared/models";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import React, { useContext } from "react";
import { MdClose } from "react-icons/md";

import ArtWork1 from "../../../../assets/images/artwork1.svg";
import CalendarIcon from "../../../../assets/images/calendar-icon.svg";
import InstructionsIcon from "../../../../assets/images/instructions-icon.svg";
import PrizeIcon from "../../../../assets/images/prize-icon.svg";
import { InstructionsModalContent } from "./InstructionsModalContent";
import { ModalContext } from "./";
import { PrizeModalContent } from "./PrizeModalContent";
import { ItemContainer, ItemLabel } from "./styles";
import { TermsModalContent } from "./TermsModalContent";
import { TimeModalContent } from "./TimeModalContent";
interface LandingContainerProps {
    event?: EdgeEventModel;
    showTerms: boolean;
}
export const ModalItems = ({ event, showTerms }: LandingContainerProps) => {
    const {
        closeModal,
        isInstructionModalOpen,
        isTimeModalOpen,
        isPrizeModalOpen,
        isTermsModalOpen,
        setIsInstructionModalOpen,
        setIsPrizeModalOpen,
        setIsTermsModalOpen,
        setIsTimeModalOpen,
    } = useContext(ModalContext) ?? {};
    return (
        <>
            <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <ItemContainer onClick={() => setIsPrizeModalOpen?.(true)}>
                    <img src={PrizeIcon} alt="DONO" width={32} height={32} />
                    <ItemLabel variant="label" mt={1}>
                        Prizes
                    </ItemLabel>
                </ItemContainer>
                <ItemContainer onClick={() => setIsTimeModalOpen?.(true)}>
                    <img src={CalendarIcon} alt="DONO" width={32} height={32} />
                    <ItemLabel variant="label" mt={1}>
                        Edge Time
                    </ItemLabel>
                </ItemContainer>

                {showTerms ? (
                    <ItemContainer onClick={() => setIsTermsModalOpen?.(true)}>
                        <img src={InstructionsIcon} alt="DONO" width={32} height={32} />{" "}
                        <ItemLabel variant="label" sx={{ fontSize: "10px" }}>
                            Terms & Conditions
                        </ItemLabel>
                    </ItemContainer>
                ) : (
                    <ItemContainer onClick={() => setIsInstructionModalOpen?.(true)}>
                        <img src={InstructionsIcon} alt="DONO" width={32} height={32} />
                        <ItemLabel variant="label" mt={1}>
                            Instructions
                        </ItemLabel>
                    </ItemContainer>
                )}
            </Box>

            <Modal
                open={
                    Boolean(isInstructionModalOpen) ||
                    Boolean(isTimeModalOpen) ||
                    Boolean(isPrizeModalOpen) ||
                    Boolean(isTermsModalOpen)
                }
                onClose={closeModal}
            >
                <Box
                    sx={(theme) => ({
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "85%", sm: 360 },
                        maxHeight: "80%",
                        overflow: "auto",
                        backgroundColor: theme.palette.background.paper,
                        outline: "none",
                        padding: theme.spacing(2),
                        borderRadius: theme.spacing(2),
                    })}
                >
                    <Box>
                        <IconButton onClick={closeModal}>
                            <MdClose />
                        </IconButton>
                    </Box>
                    <Box textAlign="center">
                        <img src={ArtWork1} alt="DONO" />
                    </Box>
                    {isInstructionModalOpen && <InstructionsModalContent event={event} />}
                    {isTimeModalOpen && <TimeModalContent event={event} />}
                    {isPrizeModalOpen && <PrizeModalContent event={event} />}
                    {isTermsModalOpen && <TermsModalContent event={event} />}
                </Box>
            </Modal>
        </>
    );
};
