import { Button, Countdown } from "@dono-business/shared/components";
import { EdgeEventModel } from "@dono-business/shared/models";
import { formatToInternational, validateUSPhoneNumber } from "@dono-business/shared/utils";
import { Box, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import dayjs from "dayjs";
import ArtWork1 from "../../../../assets/images/artwork1.svg";
import { ModalItems, ModalProvider } from "../modals";
import { Controller, useForm } from "react-hook-form";
import { useAppRequests } from "@dono-business/shared/hooks";
import DonoLogo from "../../../../assets/images/logo.svg";

export const InviteLandingScreen = () => {
    const { edgeRequests } = useAppRequests();

    const [event, setEvent] = useState<EdgeEventModel>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { eventUniqueId } = useParams();

    useEffect(() => {
        if (eventUniqueId) {
            edgeRequests.getEdgeEventByUniqueId(eventUniqueId).then((res) => {
                setEvent(res.data);
            });
        }
    }, [eventUniqueId]);

    const {
        control,
        formState: { isValid },
        getValues,
        handleSubmit,
    } = useForm({
        defaultValues: { phoneNumber: "" },
        mode: "onChange",
    });

    const inviteUser = useCallback(() => {
        if (event && event?.Id) {
            setIsSubmitting(true);
            edgeRequests
                .inviteUser(event?.Id, formatToInternational(getValues().phoneNumber))
                .then(() => {
                    window.location.href = "https://app.donocard.com";
                })
                .catch(() => {
                    setIsSubmitting(false);
                });
        }
    }, [event, getValues]);
    const hasRegistrationEnded = dayjs(event?.registrationEndDate).isBefore(dayjs());
    const logo = event?.logoContent?.logoContentValue ?? event?.company.logo ?? DonoLogo;

    return (
        <ModalProvider>
            <Box textAlign="center" my={3}>
                <a href={window.location.origin}>
                    <img src={DonoLogo} alt="DONO" />
                </a>
            </Box>
            <Box
                sx={{ background: "linear-gradient(90deg, #0C2145 0%, #12264B 50.77%, #434E82 100%)" }}
                p={3}
                width="100%"
            >
                <Box display="flex" alignItems="center" gap={2} maxWidth={390} margin="auto">
                    <Box
                        sx={{
                            width: "75px",
                            minWidth: "75px",
                            height: "75px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            backgroundImage: `url(${logo})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                    />
                    <Box display="flex" flexDirection="column">
                        <Typography fontSize={20} fontWeight={500} color="text.contrast">
                            {event?.name || "-"}
                        </Typography>
                        <Typography fontSize={14} fontWeight={500} color="background.freshDark">
                            {event?.status === "ended" ? (
                                "Ended"
                            ) : hasRegistrationEnded ? (
                                "Registration period is over."
                            ) : (
                                <>
                                    Registration time remaining:{" "}
                                    <Countdown
                                        endDate={
                                            event?.registrationEndDate
                                                ? new Date(event?.registrationEndDate).toLocaleString()
                                                : ""
                                        }
                                    />
                                </>
                            )}
                        </Typography>
                        <Typography fontSize={12} fontWeight={500} color="#C2C6DA">
                            By {event?.company?.name || "-"}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box textAlign="center" mt={8} mb={3}>
                <img src={ArtWork1} alt="DONO" />
            </Box>
            <Box maxWidth={400} px={2} margin="auto">
                <form onSubmit={handleSubmit(inviteUser)}>
                    <Box mt={8} textAlign="center">
                        <Typography variant="h3" fontSize={20}>
                            Start
                        </Typography>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            rules={{
                                required: { value: true, message: "Required" },
                                validate: (value) => validateUSPhoneNumber(value) || "Please enter a valid number",
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    type="tel"
                                    placeholder="Mobile Number"
                                    autoComplete="off"
                                    variant="standard"
                                    prefix="+1"
                                    error={Boolean(error)}
                                    helperText={error?.message ?? ""}
                                    {...field}
                                />
                            )}
                        />
                    </Box>
                    <Button sx={{ mt: 4, mb: 5 }} type="submit" isLoading={isSubmitting} disabled={!isValid}>
                        Go to DONO app
                    </Button>
                </form>
                <ModalItems event={event} showTerms={false} />
            </Box>
        </ModalProvider>
    );
};
