import { Box, BoxProps, Tooltip, useTheme } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { BsQuestionCircleFill } from "react-icons/bs";

type Props = { children: string };

export const InstructionTooltip = ({ children }: Props) => {
    const theme = useTheme();
    return (
        <Tooltip title={children} placement={"top"} arrow>
            <Box component="span">
                <BsQuestionCircleFill style={{ width: "12px", height: "12px" }} color={theme.palette.text.primary} />
            </Box>
        </Tooltip>
    );
};
