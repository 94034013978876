import { MuiDateCalendar } from "./components/datecalendar";
import { createTheme as createMuiTheme } from "@mui/material/styles";

import { MuiAlert } from "./components/alert";
import { MuiButton, MuiButtonBase } from "./components/button";
import { MuiCheckbox } from "./components/checkbox";
import { MuiCssBaseline } from "./components/cssbaseline";
import { MuiDateTimePicker } from "./components/datetimepicker";
import { MuiTextField } from "./components/textfield";
import { darkPaletteOption, lightPaletteOption } from "./system/palette";
import { shape } from "./system/shape";
import { typography } from "./system/typography";
import { MuiAutocomplete } from "./components/autocomplete";
import { MuiSelect } from "./components/select";

export const createTheme = (mode?: string) => {
    const initialTheme = createMuiTheme({
        palette: mode === "dark" ? darkPaletteOption : lightPaletteOption,
        typography,
        shape,
    });
    return createMuiTheme(initialTheme, {
        components: {
            MuiButtonBase: MuiButtonBase(),
            MuiButton: MuiButton(initialTheme),
            MuiTextField: MuiTextField(initialTheme),
            MuiAlert: MuiAlert(initialTheme),
            MuiCheckbox: MuiCheckbox(),
            MuiCssBaseline: MuiCssBaseline(),
            MuiDateTimePicker: MuiDateTimePicker(),
            MuiDateCalendar: MuiDateCalendar(),
            MuiAutocomplete: MuiAutocomplete(),
            MuiSelect: MuiSelect(),
        },
    });
};

export const theme = createTheme();
