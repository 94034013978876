import { Grid, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { MediaPreview } from "./MediaPreview";
import { CreateEdgeEventContext } from "./CreateEdgeEventProvider";
import { convertEdgeEventMediaContentToMediaFileData, convertGiftMediaContentToMediaFileData } from "./helpers";
import { EdgeEventGiftValueType } from "@dono-business/shared/models";
import { getNumberInNationalFormat, genericDateAndTimeFormatter } from "@dono-business/shared/utils";
import { createCompanyLogoPreviewPlaceHolder } from "@dono-business/shared/utils/other";
import { useAppTimezone } from "@dono-business/shared/components";
export const Review = () => {
    const {
        event,
        selectedPaymentMethod,
        winnerFile,
        loserFile,
        companyLogoFile,
        previousWinnerMediaContent,
        previousLoserMediaContent,
        previousCompanyLogoMediaContent,
    } = useContext(CreateEdgeEventContext) ?? {};
    const { appTimezone } = useAppTimezone();
    const data: { [key: string]: string | undefined } = event
        ? {
              "Event Name": event.name,
              "Registration Period": `${genericDateAndTimeFormatter(
                  event.registrationStartDate,
                  appTimezone,
              )} - ${genericDateAndTimeFormatter(event.registrationEndDate, appTimezone)}`,
              "Drawing Time": genericDateAndTimeFormatter(event.drawingDate, appTimezone),
              "Data Collection": event.leadFieldData.map((field) => field.name).join(", "),
              ...(event.onsiteContact?.length && {
                  "Event Onsite Contact": event.onsiteContact
                      .map(
                          (contact) =>
                              `${contact.firstName || ""} ${contact.lastName || ""}${
                                  contact.phoneNumber
                                      ? `${
                                            contact.firstName || contact.lastName ? ":" : ""
                                        } ${getNumberInNationalFormat(contact.phoneNumber)}`
                                      : ""
                              }`,
                      )
                      .join("<br/>"),
              }),
              ...(event.edgeEventPrizeData.some((gift) => gift.type === EdgeEventGiftValueType.NonDonoGift) && {
                  "Giveaway(s)": event.edgeEventPrizeData
                      .filter((gift) => gift.type === EdgeEventGiftValueType.NonDonoGift)
                      .map(
                          (gift) =>
                              `${gift.type === EdgeEventGiftValueType.NonDonoGift && gift.name} - Qty: ${
                                  gift.quantity
                              }`,
                      )
                      .join("<br/>"),
              }),
              ...(event.termsAndConditions && { "Terms & Condition": event.termsAndConditions }),
          }
        : {};

    const previousWinnerFile = convertGiftMediaContentToMediaFileData("WINNER FILE", previousWinnerMediaContent);
    const previousLoserFile = convertGiftMediaContentToMediaFileData("NON-WINNER FILE", previousLoserMediaContent);
    const previousCompanyLogoFile = convertEdgeEventMediaContentToMediaFileData(
        "LOGO FILE",
        previousCompanyLogoMediaContent,
    );
    const _winnerFile = previousWinnerFile || winnerFile;
    const _loserFile = previousLoserFile || loserFile;
    const _companyLogoFile = previousCompanyLogoFile || companyLogoFile;
    const theme = useTheme();
    return (
        <Grid container>
            <Grid item mb="32px" xs={12}>
                <Typography
                    sx={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: (theme) => theme.palette.text.primary,
                    }}
                >
                    Event Details
                </Typography>
            </Grid>

            <Grid item xs={12} mb={3}>
                <Box>
                    <Typography display={"block"} fontSize={16} fontWeight={600} mb={3} color={"#43496A"}>
                        Company Logo
                    </Typography>
                    <MediaPreview
                        fileData={_companyLogoFile ?? createCompanyLogoPreviewPlaceHolder()}
                        width={200}
                        height={200}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                {Object.entries(data).map(([key, value]) => (
                    <Box mb={3} key={key}>
                        <Typography display={"block"} fontSize={16} fontWeight={600} color={"#43496A"} mb={1}>
                            {`${key}: `}
                        </Typography>
                        <Typography
                            fontSize={14}
                            fontWeight={400}
                            color={"#43496A"}
                            sx={{
                                wordBreak: "break-word",
                                "& a": { textDecoration: "underline", color: theme.palette.text.prominent },
                            }}
                            dangerouslySetInnerHTML={{ __html: value ?? "" }}
                        />
                    </Box>
                ))}
            </Grid>
            <Grid item container xs={12} md={8} columnSpacing={5}>
                {_winnerFile && (
                    <Grid xs={12} md={_loserFile ? 6 : 12} item display="flex">
                        <Box display="flex" flexDirection={"column"} gap={3}>
                            <Typography fontSize={16} fontWeight={600} color={"#43496A"}>
                                Message to Winner(s):{" "}
                            </Typography>
                            <MediaPreview fileData={_winnerFile} />
                            <Typography
                                fontSize={14}
                                fontWeight={400}
                                color={theme.palette.text.primary}
                                sx={{
                                    wordBreak: "break-word",
                                    "& a": { textDecoration: "underline", color: theme.palette.text.prominent },
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: event?.winnerMessage ?? "",
                                }}
                            ></Typography>
                        </Box>
                    </Grid>
                )}
                {_loserFile && (
                    <Grid xs={12} md={_winnerFile ? 6 : 12} item display="flex">
                        <Box display="flex" flexDirection={"column"} gap={3}>
                            <Typography fontSize={16} fontWeight={600} color={"#43496A"}>
                                Message to Non-Winners(s):{" "}
                            </Typography>
                            <MediaPreview fileData={_loserFile} />
                            <Typography
                                fontSize={14}
                                fontWeight={400}
                                sx={{
                                    wordBreak: "break-word",
                                    "& a": { textDecoration: "underline", color: theme.palette.text.prominent },
                                }}
                                color={theme.palette.text.primary}
                                dangerouslySetInnerHTML={{ __html: event?.loserMessage ?? "" }}
                            ></Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
