/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, FormHelperText, Typography } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactQuill, { ReactQuillProps } from "react-quill";
import { TextEditorInput } from "./TextEditorInput";
import Delta from "quill-delta";
import { DeltaStatic, Sources } from "quill";
type Props = ReactQuillProps & {
    label?: string;
    characterLimit?: number | boolean;
    error?: { message?: string } | boolean;
    uniqueName: string;
};
export type TextChangeHandler = (delta: DeltaStatic, oldContents: DeltaStatic, source: Sources) => unknown;
const DEFAULT_CHAR_LIMIT = 2000;
export type TextEditorRef = ReactQuill & { getRemainingLength: () => number };
export const TextEditor = forwardRef(function TextEditor(
    { label, characterLimit: _characterLimit, error, ...inputProps }: Props,
    ref: React.ForwardedRef<TextEditorRef>,
) {
    const innerRef = useRef<ReactQuill | null>(null);
    const quill = innerRef.current;
    const quillEditor = quill?.getEditor();
    const characterLimit = _characterLimit === true ? DEFAULT_CHAR_LIMIT : _characterLimit;
    const hasCharacterLimit = typeof characterLimit === "number";
    const [remainingLength, setRemainingLength] = useState(hasCharacterLimit ? characterLimit : Infinity);

    const getCurrentContentLength = () =>
        quillEditor
            ?.getText()
            .replace(/<(.|\n)*?>/g, "")
            .trim().length === 0
            ? 0
            : quillEditor?.getLength() ?? 0;

    const getRemainingLength = () => {
        if (hasCharacterLimit) {
            const currentContentLength = getCurrentContentLength();
            const remainingLength = characterLimit - currentContentLength;
            return remainingLength;
        }
        return Infinity;
    };

    useEffect(() => {
        if (hasCharacterLimit) {
            setRemainingLength(getRemainingLength());
        }
    });

    useEffect(() => {
        const onTextChange: TextChangeHandler = (delta) => {
            const { ops } = delta ?? {};
            if (Array.isArray(ops)) {
                const linkOp = ops.find((op) => typeof op?.attributes?.link === "string");
                if (linkOp) {
                    const linkHref = (linkOp.attributes?.link as string).trim();
                    if (linkHref.startsWith("https://") === false && linkHref.startsWith("http://") === false) {
                        quillEditor?.updateContents(
                            //@ts-ignore
                            ops.reduce(
                                (delta, op, index, array) =>
                                    typeof op.retain === "number"
                                        ? index === array.length - 1
                                            ? delta.retain(op.retain, { link: `https://${linkHref}` })
                                            : delta.retain(op.retain)
                                        : delta,
                                new Delta(),
                            ),
                        );
                    }
                }
            }
        };
        quillEditor?.on("text-change", onTextChange);
        return () => {
            quillEditor?.off("text-change", onTextChange);
        };
    });
    //@ts-ignore
    useImperativeHandle(ref, () => ({ getRemainingLength, ...innerRef.current }));
    return (
        <Box
            sx={{
                position: "relative",
            }}
        >
            {label && (
                <Typography
                    color={"text.primary"}
                    variant="caption"
                    sx={(theme) => ({
                        transform: "translate(10px,-10px) scale(0.75)", // TODO: replace with a mui rich text editor
                        transformOrigin: "top left",
                        position: "absolute",
                        width: "fit-content",
                        background: theme.palette.background.paper,
                        px: 0.5,
                        ...(error && { color: theme.palette.error.main }),
                    })}
                >
                    {label}
                </Typography>
            )}
            <TextEditorInput {...inputProps} error={!!error} ref={innerRef} />
            {hasCharacterLimit && (
                <Box
                    display={"flex"}
                    width={"100"}
                    justifyContent={"space-between"}
                    flexDirection={"row-reverse"}
                    className="remaining-length"
                >
                    <Typography
                        sx={{
                            fontSize: "12px",
                            color: (theme) => (remainingLength < 0 ? theme.palette.error.main : "#707070"),
                            lineHeight: "20px",
                        }}
                    >
                        {remainingLength}
                    </Typography>
                    {error && typeof error === "object" && typeof error.message === "string" && error.message && (
                        <FormHelperText error sx={{ fontSize: "12px", marginLeft: 0 }}>
                            {error.message}
                        </FormHelperText>
                    )}
                </Box>
            )}
        </Box>
    );
});
