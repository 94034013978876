import { PaletteColorOptions, ThemeOptions } from "@mui/material";
import { lightColors, darkColors } from "./colors";

declare module "@mui/material" {
    export interface PaletteColor {
        lighter: string;
        darker: string;
        active?: string;
        darkest?: string;
    }
    export interface SimplePaletteColorOptions {
        lighter?: string;
        darker?: string;
        active?: string;
        darkest?: string;
        cloudy?: string;
    }
    interface CustomPalette {
        red?: SimplePaletteColorOptions;
        orange?: SimplePaletteColorOptions;
        green?: SimplePaletteColorOptions;
        blue?: SimplePaletteColorOptions;
        purple?: SimplePaletteColorOptions;
    }
    export type Palette = CustomPalette;
    interface TypeText {
        success: string;
        prominent: string;
        contrast: string;
        fresh: string;
        dark: string;
        darker: string;
    }
    interface TypeBackground {
        freshDark: string;
        freshLight: string;
        active: string;
        disabled: string;
        darker: string;
    }
    interface PaletteOptions extends CustomPalette {
        button?: PaletteColorOptions;
    }
}

export const lightPaletteOption: ThemeOptions["palette"] = {
    ...lightColors,
    mode: "light",
    primary: lightColors.blue,
    secondary: lightColors.purple,
    background: {
        paper: lightColors.neutral.white,
        default: lightColors.neutral.white,
        active: lightColors.blue.main,
        freshDark: lightColors.purple.dark,
        freshLight: lightColors.purple.main,
        disabled: lightColors.neutral.grey.darker,
        darker: lightColors.neutral.black,
    },
    error: {
        main: lightColors.red.main,
        dark: lightColors.red.dark,
    },
    text: {
        primary: lightColors.blue.main,
        contrast: lightColors.neutral.white,
        success: lightColors.green.main,
        prominent: lightColors.blue.light,
        fresh: lightColors.purple.cloudy,
        dark: lightColors.neutral.black,
        darker: lightColors.blue.dark,
    },
    button: {
        main: lightColors.blue.light || "",
    },
    divider: lightColors.neutral.grey.main,
    success: {
        main: lightColors.green.main,
        light: lightColors.green.light,
    },
};

export const darkPaletteOption: ThemeOptions["palette"] = {
    ...darkColors,
    mode: "dark",
    primary: lightColors.blue,
    secondary: lightColors.purple,
    background: {
        paper: lightColors.neutral.white,
        default: lightColors.neutral.grey.lighter,
        active: lightColors.blue.light,
        freshDark: lightColors.purple.dark,
        freshLight: lightColors.purple.main,
        disabled: lightColors.neutral.grey.darker,
        darker: lightColors.neutral.black,
    },
    error: {
        main: lightColors.red.main,
    },
    text: {
        primary: lightColors.neutral.white,
        contrast: lightColors.neutral.white,
        success: lightColors.green.main,
        prominent: lightColors.blue.light,
        fresh: lightColors.purple.cloudy,
        dark: lightColors.neutral.black,
        darker: lightColors.blue.dark,
    },
    button: {
        main: lightColors.blue.light || "",
    },
    divider: lightColors.neutral.grey.main,
    success: {
        main: lightColors.green.main,
        light: lightColors.green.light,
    },
};
