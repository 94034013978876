import { EdgeEventModel } from "@dono-business/shared/models";
import { Box } from "@mui/system";
import React from "react";

import { ModalCaption, ModalMessage, ModalSubtitle, ModalTitle } from "./styles";

interface InstructionsModalContentProps {
    event?: EdgeEventModel;
}
export const InstructionsModalContent = ({ event }: InstructionsModalContentProps) => {
    return (
        <Box pl={2}>
            <ModalTitle display="block">{event?.name} Instructions</ModalTitle>
            <ModalSubtitle display="block" mt={3}>
                1- Scan the QR code
            </ModalSubtitle>
            <ModalSubtitle display="block" mt={2}>
                2- Fill in the fields & click "Register"
                <ModalCaption display="block" pl={2} mt={1}>
                    You will receive a text message confirming your registration.
                </ModalCaption>
            </ModalSubtitle>
            <ModalSubtitle display="block" mt={2}>
                3- Follow the DONO text message link to check status
                <ModalCaption display="block" pl={2} mt={1}>
                    You can also check your status by logging into the DONO mobile app and going to "Raffles".{" "}
                </ModalCaption>
            </ModalSubtitle>
            <ModalMessage display="block" mt={4} mb={3}>
                Good Luck!
            </ModalMessage>
        </Box>
    );
};
