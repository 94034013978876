import React, { useState, useEffect, useRef } from "react";
import { Box, Link } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ExpandableText = ({ text, isHtml }: { text: string; isHtml: boolean }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpandable, setIsExpandable] = useState(false);
    const textRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const checkExpandability = () => {
            if (textRef.current) {
                setIsExpandable(textRef.current.scrollHeight > textRef.current.clientHeight);
            }
        };

        checkExpandability();
        const mutationObserver = new MutationObserver(checkExpandability);
        textRef.current && mutationObserver.observe(textRef.current, { subtree: true, childList: true });
        window.addEventListener("resize", checkExpandability);

        return () => {
            window.removeEventListener("resize", checkExpandability);
            mutationObserver.disconnect();
        };
    }, []);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Box>
            <Container ref={textRef} isExpanded={isExpanded} isExpandable={isExpandable}>
                {isHtml ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                ) : (
                    <div>{text}</div>
                )}
            </Container>
            {isExpandable && <Link onClick={toggleExpand}>{isExpanded ? "Close" : "More..."}</Link>}
        </Box>
    );
};
const Container = styled(Box)<{ isExpanded: boolean; isExpandable: boolean }>(({ isExpanded, isExpandable }) => ({
    position: "relative",
    overflow: "hidden",
    maxHeight: isExpanded ? "none" : "4em",
    wordBreak: "break-word",
    ...(isExpandable &&
        isExpanded === false && {
            "&:after": {
                // eslint-disable-next-line quotes
                content: '""',
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "100%",
                height: "50%",
                backgroundImage: "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
            },
        }),
}));
