import { alpha, IconButton, InputAdornment, StandardTextFieldProps, styled, TextField, useTheme } from "@mui/material";
import { SearchIcon } from "@dono-business/shared/icons";
import React from "react";
import { MdOutlineCancel } from "react-icons/md";

type Props = Omit<StandardTextFieldProps, "onChange"> & { onValueChange: (value: string) => void };

export const SearchBar = (props: Props) => {
    const { onValueChange, ...textFieldProps } = props;
    const theme = useTheme();
    const handleValueChange = (value: string) => onValueChange(value);
    return (
        <StyledTextField
            {...textFieldProps}
            onChange={(e) => handleValueChange(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: "16px" }}>
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: textFieldProps.value ? (
                    <InputAdornment position="end" sx={{ marginRight: "-8px" }}>
                        <IconButton onClick={() => handleValueChange("")}>
                            <MdOutlineCancel fontSize={"24px"} color={theme.palette.text.darker} />
                        </IconButton>
                    </InputAdornment>
                ) : undefined,
                ...textFieldProps.InputProps,
            }}
        />
    );
};

const StyledTextField = styled(TextField)(({ theme }) => ({
    fontSize: "16px",
    width: "360px",
    transition: "all .5s ease",

    ".MuiInputBase-root": {
        "&": {
            height: "40px",
            paddingLeft: "16px",
            ".MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${theme.palette.background.freshDark}`,
                borderRadius: "4px",
            },
            ".MuiInputBase-input": {
                color: theme.palette.text.darker,
                "&::placeholder": { color: theme.palette.text.fresh, opacity: 1 },
            },
        },

        "&:hover": {
            background: alpha(theme.palette.background.freshDark, 0.15),
            ".MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${theme.palette.text.darker}`,
            },
            ".MuiInputBase-input": { "&::placeholder": { color: theme.palette.text.darker, opacity: 1 } },
        },
        "&.Mui-focused": {
            background: "initial",
            ".MuiInputBase-input": { "&::placeholder": { color: theme.palette.text.fresh, opacity: 1 } },
            ".MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${theme.palette.background.freshDark}`,
            },
        },
    },
}));
