import { Grid, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import {
    BoldBlueText,
    RegistrationKitButtonsWrapper,
    RegistrationKitIconButton,
    RegistrationKitRow,
    WideButtonWithIcon,
} from "./styles";
import { MdOutlineFileDownload } from "react-icons/md";
import { EventDetailContext } from "../EventDetailProvider";
import { QRCode } from "react-qrcode-logo";
import { Box } from "@mui/material";
import { ShareIcon, CopyIcon, LinkIcon } from "@dono-business/shared/icons/";
import { shareUrl } from "./utils";
import { copyTextToClipboard } from "../utils";

export const RegistrationKit = () => {
    const { handleDownloadQRCode, event, registrationLandingUrl, entryLandingUrl } =
        useContext(EventDetailContext) ?? {};
    const { name } = event ?? {};
    const handleDownloadQRCodeButtonClick = () => handleDownloadQRCode?.();

    const rows = [
        {
            label: "Registration Page",
            shareTitle: `${name ?? ""} - Registration Page`,
            url: registrationLandingUrl ?? "",
        },
        {
            label: "Entry Page",
            shareTitle: `${name ?? ""} - Entry Page`,
            url: entryLandingUrl ?? "",
        },
    ];
    return (
        <Grid container direction={"column"} alignItems={"center"}>
            <Grid item mt={"28px"} mb="20px">
                <WideButtonWithIcon sx={{ width: "228px" }} onClick={handleDownloadQRCodeButtonClick}>
                    <MdOutlineFileDownload fontSize={"18"} />
                    Download QR Code{" "}
                </WideButtonWithIcon>
                <span hidden>
                    <QRCode value={registrationLandingUrl} size={180} />
                </span>
            </Grid>
            {rows.map((row) => {
                const shareData = { title: row.shareTitle, url: row.url };
                const canShare =
                    typeof navigator.canShare === "function" ? navigator.canShare(shareData) : navigator.share;
                return (
                    <RegistrationKitRow item container key={row.label}>
                        <BoldBlueText>{row.label}</BoldBlueText>
                        <RegistrationKitButtonsWrapper>
                            {canShare && (
                                <Tooltip title="Share Url">
                                    <span>
                                        <RegistrationKitIconButton onClick={() => shareUrl(shareData)}>
                                            <ShareIcon />
                                        </RegistrationKitIconButton>
                                    </span>
                                </Tooltip>
                            )}
                            <Tooltip title="Copy Url">
                                <span>
                                    <RegistrationKitIconButton onClick={() => copyTextToClipboard(row.url)}>
                                        <CopyIcon />
                                    </RegistrationKitIconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Open Url">
                                <span>
                                    <RegistrationKitIconButton onClick={() => window.open(row.url, "_blank")}>
                                        <LinkIcon />
                                    </RegistrationKitIconButton>
                                </span>
                            </Tooltip>
                        </RegistrationKitButtonsWrapper>
                    </RegistrationKitRow>
                );
            })}
        </Grid>
    );
};
