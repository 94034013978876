import React, { Context, PropsWithChildren, createContext, useRef, useState } from "react";
import { uuidv4 } from "@firebase/util";
interface ListUtilsValues<Item, K extends keyof Item> {
    isDeleteSnackShowing: boolean;
    isEditSnackShowing: boolean;
    isSaveSnackShowing: boolean;
    isNewItemFormShowing: boolean;
    selectedItem: Item | null;
    handleNewItemSave: (data: Omit<Item, K>) => void;
    handleNewItemCancel: () => void;
    handleNewItemButtonClick: () => void;
    handleItemEditSave: (data: Item) => void;
    handleItemEditCancel: () => void;
    handleSelectItemForEdit: (item: Item) => void;
    handleItemDelete: (item: Item) => void;
    handleItemDeleteUndo: () => void;
    handleDeleteSnackClose: () => void;
    handleEditSnackClose: () => void;
    handleSaveSnackClose: () => void;
}
export const createListUtilsContext = <Item, K extends keyof Item>() =>
    createContext<null | ListUtilsValues<Item, K>>(null);

export const ListUtilsProvider = <Item, K extends keyof Item>({
    idField,
    setItems,
    children,
    Context,
}: PropsWithChildren<{
    idField: K;
    setItems: (items: Item[] | ((items: Item[]) => Item[])) => void;
    Context: Context<ListUtilsValues<Item, K> | null>;
    newItemFormOpenByDefault?: boolean;
}>) => {
    const [isNewItemFormShowing, _setIsNewItemFormActive] = useState(false);
    const [isDeleteSnackShowing, _setIsDeleteSnackShowing] = useState(false);
    const [isEditSnackShowing, _setIsEditSnackShowing] = useState(false);
    const [isSaveSnackShowing, _setIsSaveSnackShowing] = useState(false);
    const [selectedItem, _setSelectedItem] = useState<Item | null>(null);
    const _deletedItemData = useRef<{ index: number; item: Item } | null>(null);

    const handleNewItemSave = (data: Omit<Item, K>) => {
        const newItem = { ...data, [idField]: uuidv4() } as Item;
        setItems((items) => [...items, newItem]);
        _setIsSaveSnackShowing(true);
        _setIsNewItemFormActive(false);
    };
    const handleNewItemCancel = () => _setIsNewItemFormActive(false);
    const handleNewItemButtonClick = async () => {
        handleItemEditCancel();
        _setIsNewItemFormActive(true);
    };
    const handleItemEditSave = (data: Item) => {
        setItems((items) => {
            const itemIndex = items.findIndex((item) => item[idField] === data[idField]);
            const newItems = [...items];
            newItems[itemIndex] = data;
            return newItems;
        });
        _setIsEditSnackShowing(true);
        _setSelectedItem(null);
    };
    const handleItemEditCancel = () => {
        _setSelectedItem(null);
    };
    const handleSelectItemForEdit = (item: Item) => {
        _setIsNewItemFormActive(false);

        _setSelectedItem(item);
    };
    const handleItemDelete = (item: Item) => {
        setItems((items) => {
            const deletedItemIndex = items.findIndex((_item) => _item[idField] === item[idField]);
            if (deletedItemIndex !== undefined) {
                _deletedItemData.current = { index: deletedItemIndex, item };
                const newItems = [...items];
                newItems.splice(deletedItemIndex, 1);
                return newItems;
            }
            return items;
        });
        _setIsDeleteSnackShowing(true);
    };

    const handleItemDeleteUndo = () => {
        setItems((items) => {
            if (_deletedItemData.current) {
                const { index, item } = _deletedItemData.current;
                const revertedItems = [...items];
                revertedItems.splice(index, 0, item);
                return revertedItems;
            }
            return items;
        });
        handleDeleteSnackClose();
    };

    const handleDeleteSnackClose = () => {
        _setIsDeleteSnackShowing(false);
        _deletedItemData.current = null;
    };
    const handleEditSnackClose = () => {
        _setIsEditSnackShowing(false);
    };
    const handleSaveSnackClose = () => {
        _setIsSaveSnackShowing(false);
    };

    return (
        <Context.Provider
            value={{
                isDeleteSnackShowing,
                isEditSnackShowing,
                isSaveSnackShowing,
                isNewItemFormShowing,
                selectedItem,
                handleNewItemSave,
                handleNewItemCancel,
                handleNewItemButtonClick,
                handleItemEditSave,
                handleItemEditCancel,
                handleSelectItemForEdit,
                handleItemDelete,
                handleItemDeleteUndo,
                handleDeleteSnackClose,
                handleEditSnackClose,
                handleSaveSnackClose,
            }}
        >
            {children}
        </Context.Provider>
    );
};
