import { systemTimezoneValue } from "@dono-business/shared/utils";
import React, { PropsWithChildren, createContext, useState } from "react";
export type AppTimezoneValues = {
    appTimezone: string;
    setAppTimezone: (timezone: string) => void;
};
export const AppTimezoneContext = createContext<AppTimezoneValues>({
    appTimezone: systemTimezoneValue,
    setAppTimezone: () => null,
});
export const AppTimezoneProvider = ({ children }: PropsWithChildren) => {
    const [appTimezone, setAppTimezone] = useState(systemTimezoneValue);
    return (
        <AppTimezoneContext.Provider value={{ appTimezone, setAppTimezone }}>{children}</AppTimezoneContext.Provider>
    );
};
