import { Box, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { InstructionTooltip } from "../InstructionTooltip";
import { OnsiteContactWithId } from "../types";

import {
    ItemDeleteSnack,
    ItemEditSnack,
    ItemSaveSnack,
    NewItemButton,
    SavedItemLabel,
    SavedItemValue,
} from "../../../components/list-utils/components";
import { SavedItem } from "components/list-utils/";
import { PersonIcon } from "@dono-business/shared/icons";
import { getNumberInNationalFormat } from "@dono-business/shared/utils";
import { OnsiteContactsContext } from "./Context";
import { CreateEdgeEventContext } from "../CreateEdgeEventProvider";
import { OnsiteContactForm } from "./OnsiteContactForm";

export const OnsiteContactsContent = () => {
    const { onsiteContacts: _onsiteContacts } = useContext(CreateEdgeEventContext) ?? {};
    const onsiteContacts = _onsiteContacts ?? [];
    const {
        handleItemDelete: handleDeleteContact,
        handleSelectItemForEdit: handleSelectContactForEdit,
        handleItemEditSave: handleContactEditSave,
        handleItemEditCancel: handleContactEditCancel,
        selectedItem: currentlyEditingContact,
        handleNewItemCancel: handleNewContactCancel,
        handleNewItemSave: handleNewContactSave,
        handleItemDeleteUndo: handleContactDeleteUndo,
        handleNewItemButtonClick: handleNewContactButtonClick,
        handleDeleteSnackClose,
        handleEditSnackClose,
        handleSaveSnackClose,
        isDeleteSnackShowing,
        isEditSnackShowing,
        isSaveSnackShowing,
        isNewItemFormShowing: isNewContactFormShowing,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    } = useContext(OnsiteContactsContext)!;
    return (
        <Grid container>
            <Grid item xs={12} display="flex" position={"relative"} mb={3}>
                <Typography
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                        fontWeight: "700",
                        fontSize: "16px",
                        position: "relative",
                    }}
                >
                    Event Onsite Contact <span style={{ fontWeight: "400" }}>(Optional)</span>
                </Typography>
                <Box ml={"1rem"}>
                    <InstructionTooltip>
                        This person will be notified by text of the drawing in real-time.
                    </InstructionTooltip>
                </Box>
            </Grid>
            {!!onsiteContacts?.length && (
                <Grid item container xs={12} mb={"21px"}>
                    {onsiteContacts.map((contact) => (
                        <SavedItem<OnsiteContactWithId>
                            key={contact.id}
                            item={contact}
                            onDeleteButtonClick={handleDeleteContact}
                            onEditButtonClick={handleSelectContactForEdit}
                            renderItem={({ firstName, lastName, phoneNumber }) => (
                                <Grid container minHeight={"100px"} pl="15px" py={"16px"}>
                                    <Grid item xs="auto" mr={{ xs: 2, md: "45px" }}>
                                        <Box
                                            component={"span"}
                                            width={"36px"}
                                            height="36px"
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                            className="person-icon"
                                        >
                                            <PersonIcon />
                                        </Box>
                                    </Grid>
                                    <Grid xs item container rowSpacing={1}>
                                        {
                                            <>
                                                <Grid item xs={12}>
                                                    <SavedItemLabel>Name:</SavedItemLabel>
                                                    <SavedItemValue>
                                                        {firstName || lastName
                                                            ? `${firstName ?? ""} ${lastName ?? ""}`
                                                            : "-"}
                                                    </SavedItemValue>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SavedItemLabel>Phone:</SavedItemLabel>
                                                    <SavedItemValue>
                                                        {phoneNumber ? getNumberInNationalFormat(phoneNumber) : "-"}
                                                    </SavedItemValue>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            )}
                            renderEditMode={({ firstName, lastName, phoneNumber, id }) => (
                                <Grid container pl="15px">
                                    <Grid item xs="auto" mr={{ xs: 1, md: "45px" }} pt="10px">
                                        <Box
                                            component={"span"}
                                            width={"36px"}
                                            height="36px"
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                            className="person-icon"
                                        >
                                            <PersonIcon />
                                        </Box>
                                    </Grid>
                                    <Grid item maxWidth={"700px"}>
                                        <OnsiteContactForm
                                            mode="edit"
                                            onSubmit={(data) => handleContactEditSave({ ...data, id })}
                                            onCancel={handleContactEditCancel}
                                            defaultValues={{ firstName, lastName, phoneNumber }}
                                            nonAllowedPhoneNumbers={onsiteContacts
                                                .map((contact) => contact.phoneNumber)
                                                .filter((num) => num !== phoneNumber)}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            isEditing={currentlyEditingContact?.id === contact.id}
                            disableDelete={isDeleteSnackShowing}
                        />
                    ))}
                </Grid>
            )}
            {isNewContactFormShowing ? (
                <Grid item maxWidth={"800px"}>
                    <OnsiteContactForm
                        mode="add"
                        onSubmit={handleNewContactSave}
                        onCancel={handleNewContactCancel}
                        nonAllowedPhoneNumbers={onsiteContacts.map((contact) => contact.phoneNumber)}
                    />{" "}
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <NewItemButton onClick={handleNewContactButtonClick} disabled={isNewContactFormShowing}>
                        + New Contact
                    </NewItemButton>
                </Grid>
            )}

            <ItemDeleteSnack
                isShowing={isDeleteSnackShowing}
                handleClose={handleDeleteSnackClose}
                handleUndo={handleContactDeleteUndo}
                message="Onsite Contact removed."
            />
            <ItemEditSnack
                isShowing={isEditSnackShowing}
                handleClose={handleEditSnackClose}
                message="Onsite Contact updated."
            />
            <ItemSaveSnack
                isShowing={isSaveSnackShowing}
                handleClose={handleSaveSnackClose}
                message="Onsite Contact successfully added!"
            />
        </Grid>
    );
};
