import { GiftFormFieldsModel, RegistrationFieldFormFieldsModel } from "@dono-business/shared/models";
import React from "react";
import { useGiftsForm } from "./useGiftsForm";
import { ClickAwayListener, FormControl, Grid, TextField, capitalize } from "@mui/material";
import { Controller } from "react-hook-form";
import {
    FormButtonsContainer,
    FormGridContainer,
    FormTitle,
    SaveButton,
    SmallButton,
    UnsavedItemDialog,
    useUnsavedItem,
} from "../../../components/list-utils";
import { GiftBoxIcon } from "@dono-business/shared/icons";

type Props = {
    defaultValues?: GiftFormFieldsModel;
    onSubmit: (values: GiftFormFieldsModel) => void;
    onCancel: () => void;
    nonAllowedGiftNames: string[];
};

export const GiftForm = ({ defaultValues, onSubmit, onCancel, nonAllowedGiftNames }: Props) => {
    const {
        control,
        handleSubmit,
        formState: { isValid },
        trigger,
    } = useGiftsForm({ defaultValues });
    const handleFormSubmit = (data: GiftFormFieldsModel) => {
        onSubmit(data);
    };
    const handleCancelButtonClick = () => {
        onCancel();
    };
    const isGiftAllowed = (name: string) =>
        nonAllowedGiftNames.find((_name) => _name.toLowerCase() === name.toLowerCase()) === undefined;
    const { handleFormBlur, handleFormClick, unsavedDialogOpen, toggleUnsavedDialogOpen } = useUnsavedItem({
        isFormValid: async () => await trigger(),
    });

    return (
        <>
            <ClickAwayListener onClickAway={handleFormBlur}>
                <Grid
                    component="form"
                    rowSpacing={2}
                    container
                    onSubmit={handleSubmit(handleFormSubmit)}
                    p={{ xs: "6.5px", md: "0 0 0 6.5px" }}
                    bgcolor={{ xs: "#fcfcfc", md: "transparent" }}
                    onClick={handleFormClick}
                >
                    <Grid
                        item
                        xs={"auto"}
                        sx={{
                            alignItems: "center",
                            mr: "20px",
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        <GiftBoxIcon />
                    </Grid>
                    <Grid
                        item
                        flexShrink={1}
                        flexBasis={{ xs: "100%", md: "250px", lg: "350px", xl: "450px" }}
                        sx={{ mr: { xs: 0, md: "20px" } }}
                    >
                        {" "}
                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name={"name"}
                                rules={{
                                    required: "Prize name is mandatory",
                                    validate: {
                                        isAllowed: (value) => isGiftAllowed(value) || "This prize is already added",
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Name or describe the prize"
                                        label="Prize"
                                        autoComplete="off"
                                        error={Boolean(error)}
                                        helperText={error?.message ?? ""}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item container xs={12} md display={"flex"} rowSpacing={2}>
                        {" "}
                        <Grid item xs={12} md sx={{ mr: { xs: 0, md: "20px" } }}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={"quantity"}
                                    rules={{
                                        required: "Quantity is mandatory",
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            placeholder="How many?"
                                            label="Quantity"
                                            type="number"
                                            autoComplete="off"
                                            error={Boolean(error)}
                                            helperText={error?.message ?? ""}
                                        />
                                    )}
                                />
                            </FormControl>{" "}
                        </Grid>
                        <Grid item xs={12} md={"auto"} display={"flex"} justifyContent={"flex-end"}>
                            <SmallButton
                                sx={{ mr: "10px", height: "56px", minWidth: "96px" }}
                                variant="outlined"
                                onClick={handleCancelButtonClick}
                            >
                                Cancel
                            </SmallButton>
                            <SaveButton
                                sx={{ height: "56px", minWidth: "96px" }}
                                variant="contained"
                                type="submit"
                                disabled={!isValid}
                            >
                                Save
                            </SaveButton>
                        </Grid>
                    </Grid>{" "}
                </Grid>
            </ClickAwayListener>
            <UnsavedItemDialog
                title={"Unsaved Gift"}
                isOpen={unsavedDialogOpen}
                onClose={() => toggleUnsavedDialogOpen(false)}
                // eslint-disable-next-line quotes
                text={'In order to save the gift info, please click on the "Save" button.'}
            />
        </>
    );
};
