import { Accordion, AccordionDetails, AccordionSummary, SxProps, Theme, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { BlueText } from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
    label: string;
    isExpanded: boolean;
    accordionRootStyles?: SxProps<Theme>;
    accordionSummaryStyles?: SxProps<Theme>;
    labelStyles?: SxProps<Theme>;

    onChange: (e: React.SyntheticEvent, isExpanded: boolean) => void;
};

export const CustomAccordion = ({
    label,
    isExpanded,
    onChange,
    children,
    accordionRootStyles,
    accordionSummaryStyles,
    labelStyles,
}: PropsWithChildren<Props>) => {
    const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        onChange(event, isExpanded);
    };
    return (
        <Accordion
            expanded={isExpanded}
            onChange={handleChange}
            sx={[
                { boxShadow: "none" },
                ...(Array.isArray(accordionRootStyles) ? accordionRootStyles : [accordionRootStyles]),
            ]}
        >
            <AccordionSummary
                aria-controls={`${label}-header`}
                id={`${label}-header`}
                expandIcon={
                    <ExpandMoreIcon
                        sx={(theme) => ({ fill: isExpanded ? theme.palette.text.contrast : theme.palette.text.darker })}
                    />
                }
                sx={[
                    (theme) => ({
                        border: `1px solid ${theme.palette.text.darker}`,
                        borderRadius: "4px",
                        height: "60px",
                        transition: "200ms all ease",
                        pr: "26px",
                        pl: "32px",

                        ...(isExpanded && {
                            background: theme.palette.text.darker,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }),
                        "&.Mui-expanded": { minHeight: "auto" },
                    }),
                    ...(Array.isArray(accordionSummaryStyles) ? accordionSummaryStyles : [accordionSummaryStyles]),
                ]}
            >
                <BlueText
                    sx={[
                        (theme) => ({ ...(isExpanded && { color: theme.palette.text.contrast }) }),
                        ...(Array.isArray(labelStyles) ? labelStyles : [labelStyles]),
                    ]}
                >
                    {label}
                </BlueText>
            </AccordionSummary>
            <AccordionDetails
                sx={(theme) => ({
                    border: "1px solid #bababa",
                    boxShadow: "none",
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                    p: 0,
                })}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    );
};
