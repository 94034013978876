import {
    EdgeEventCreateParams,
    EdgeEventModel,
    EdgeReportGetAllEventModel,
    EdgeReportEventModel,
    AmountModel,
    EdgeEventUpdateParams,
} from "@dono-business/shared/models";
import { AxiosInstance } from "axios";

export const edgeRequests = (baseUrl: string, customAxios: AxiosInstance) => ({
    createStripeCheckoutSession: (
        edgeEventId: number,
        paymentClient: string,
        successURL: string,
        cancelURL: string,
        useBucksBalance: boolean,
        paymentProvider = "Stripe",
    ) => {
        const data = {
            edgeEventId,
            paymentClient,
            successURL,
            cancelURL,
            useBucksBalance,
            paymentProvider,
        };
        return customAxios.post(`${baseUrl}/management/edge-event/payment/checkout-session`, data);
    },

    createEdgeEvent: (params: EdgeEventCreateParams) => {
        return customAxios.post<EdgeEventModel>(`${baseUrl}/edge/event `, params);
    },
    updateEdgeEvent: (params: EdgeEventUpdateParams) => {
        return customAxios.put<number>(`${baseUrl}/edge/event`, params);
    },
    cancelEdgeEvent: (edgeEventId: number) => {
        return customAxios.put<EdgeEventModel>(`${baseUrl}/edge/${edgeEventId}/cancel`);
    },
    getEdgeEventById: (id: number) => {
        return customAxios.get<EdgeEventModel>(`${baseUrl}/edge/event/${id}`);
    },

    getEdgeEventByUniqueId: (uniqueId: string) => {
        return customAxios.get<EdgeEventModel>(`${baseUrl}/edge/event/invite/${uniqueId}`);
    },

    inviteUser: (edgeEventId: number, phoneNumber: string) => {
        return customAxios.post(`${baseUrl}/edge/event/${edgeEventId}/invite`, {
            edgeEventId,
            phoneNumber,
        });
    },

    registerUser: (
        eventId: number,
        phoneNumber: string,
        firstName: string,
        lastName: string,
        email: string,
        leadData: { name: string; value: string }[],
    ) => {
        // TODO: refactor this method
        return customAxios.post(`${baseUrl}/edge/event/${eventId}/webregister`, {
            eventId,
            phoneNumber,
            firstName,
            lastName,
            email,
            leadData,
        });
    },

    getEventsReport: () => {
        return customAxios.get<{ items: EdgeReportGetAllEventModel[] }>(`${baseUrl}/edge/events/report`);
    },

    getEventReportById: (edgeEventId: number) => {
        return customAxios.get<EdgeReportEventModel>(`${baseUrl}/edge/event/${edgeEventId}/report`);
    },
    getEventReportMoreById: (edgeEventId: number) => {
        return customAxios.get<EdgeEventModel & { amount: AmountModel }>(
            `${baseUrl}/edge/event/more/${edgeEventId}/report`,
        );
    },
});
