import { UploadMedia } from "@dono-business/shared/components/UploadMedia";
import { TextEditor, TextEditorRef } from "@dono-business/shared/components/texteditor";
import { Box, FormControl, Grid, Typography } from "@mui/material";
import React, { useContext, useRef } from "react";
import { CreateEdgeEventContext } from "./CreateEdgeEventProvider";
import { convertGiftMediaContentToMediaFileData } from "./helpers";
import { useAppRequests } from "@dono-business/shared/hooks";
import { InstructionTooltip } from "./InstructionTooltip";
import { maximumLengthErrorMessage } from "@dono-business/shared/utils/other";
export const EventGiftMedia = () => {
    const { uploadRequests } = useAppRequests();

    const {
        setWinnerMediaId,
        setIsWinnerMediaUploading,
        setWinnerFile,
        setLoserMediaId,
        setIsLoserMediaUploading,
        setLoserFile,
        setHasLoserMediaUnuploadedFile,
        setHasWinnerMediaUnuploadedFile,
        winnerMessage,
        setWinnerMessage,
        loserMessage,
        setLoserMessage,
        previousLoserMediaContent,
        previousWinnerMediaContent,
        setPreviousLoserMediaContent,
        setPreviousWinnerMediaContent,
        draggableLogoSrc,
        setWinnerMessageError,
        setNonWinnerMessageError,
        winnerMessageError,
        nonWinnerMessageError,
    } = useContext(CreateEdgeEventContext) ?? {};

    const winnerMessageTextEditorRef = useRef<TextEditorRef>(null);
    const nonWinnerMessageTextEditorRef = useRef<TextEditorRef>(null);

    return (
        <Grid container spacing={2.5}>
            <Grid container item xs={12} lg={6} direction={"column"}>
                <Grid item mb={2.5} display="flex">
                    <Typography sx={{ color: "#434E82", fontWeight: 500 }}>For the Winner(s)</Typography>
                    <Box ml={"1rem"}>
                        <InstructionTooltip>
                            Not ready to upload? No problem. Finish creating this event and upload later.
                        </InstructionTooltip>
                    </Box>
                </Grid>
                <Grid item>
                    <UploadMedia
                        onClearUploadedFile={() => {
                            setWinnerMediaId?.();
                            setWinnerFile?.();
                        }}
                        onUploadStart={() => {
                            setIsWinnerMediaUploading?.(true);
                        }}
                        onUploadFinish={(data) => {
                            setIsWinnerMediaUploading?.(false);
                            if (data) {
                                setWinnerFile?.(data.file);
                                setWinnerMediaId?.(data.mediaId);
                            } else setHasWinnerMediaUnuploadedFile?.("uploadError");
                        }}
                        onFileSelected={() => setHasWinnerMediaUnuploadedFile?.(true)}
                        onClearSelectedFile={() => setHasWinnerMediaUnuploadedFile?.(false)}
                        defaultFile={convertGiftMediaContentToMediaFileData("WINNER FILE", previousWinnerMediaContent)}
                        onClearDefaultFile={() => setPreviousWinnerMediaContent?.()}
                        uploadPhotoRequest={uploadRequests.uploadPhoto}
                        draggableSrc={draggableLogoSrc}
                        uploadVideoRequest={uploadRequests.uploadVideo}
                    />
                </Grid>
                <Grid item width={"100%"}>
                    <FormControl fullWidth sx={{ mt: 6, maxWidth: { xs: "100%", md: "80%", lg: "100%" } }}>
                        <TextEditor
                            uniqueName="messageToWinners"
                            label="Message to Winner(s)"
                            value={winnerMessage}
                            onChange={(value) => {
                                setWinnerMessage?.(value);
                                setWinnerMessageError?.(
                                    (winnerMessageTextEditorRef.current?.getRemainingLength() ?? 0) < 0
                                        ? { message: maximumLengthErrorMessage }
                                        : undefined,
                                );
                            }}
                            characterLimit
                            ref={winnerMessageTextEditorRef}
                            error={winnerMessageError}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container direction={"column"} item xs={12} lg={6}>
                <Grid item mb={2.5} display="flex">
                    <Typography sx={{ color: "#434E82", fontWeight: 500 }}>For the Non-Winner(s)</Typography>
                    <Box ml={"1rem"}>
                        <InstructionTooltip>
                            Not ready to upload? No problem. Finish creating this event and upload later.
                        </InstructionTooltip>
                    </Box>
                </Grid>
                <Grid item>
                    <UploadMedia
                        onClearUploadedFile={() => {
                            setLoserMediaId?.();
                            setLoserFile?.();
                        }}
                        onUploadStart={() => {
                            setIsLoserMediaUploading?.(true);
                        }}
                        onUploadFinish={(data) => {
                            setIsLoserMediaUploading?.(false);
                            if (data) {
                                setLoserFile?.(data.file);
                                setLoserMediaId?.(data.mediaId);
                            } else setHasLoserMediaUnuploadedFile?.("uploadError");
                        }}
                        onFileSelected={() => setHasLoserMediaUnuploadedFile?.(true)}
                        onClearSelectedFile={() => setHasLoserMediaUnuploadedFile?.(false)}
                        defaultFile={convertGiftMediaContentToMediaFileData?.("LOSER FILE", previousLoserMediaContent)}
                        onClearDefaultFile={() => setPreviousLoserMediaContent?.()}
                        uploadPhotoRequest={uploadRequests.uploadPhoto}
                        uploadVideoRequest={uploadRequests.uploadVideo}
                        draggableSrc={draggableLogoSrc}
                    />{" "}
                </Grid>
                <Grid item width={"100%"}>
                    <FormControl fullWidth sx={{ mt: 6, maxWidth: { xs: "100%", md: "80%", lg: "100%" } }}>
                        <TextEditor
                            uniqueName="messageToNonwinners"
                            label="Message to Non-Winner(s)"
                            value={loserMessage}
                            onChange={(value) => {
                                setLoserMessage?.(value);
                                setNonWinnerMessageError?.(
                                    (nonWinnerMessageTextEditorRef.current?.getRemainingLength() ?? 0) < 0
                                        ? { message: maximumLengthErrorMessage }
                                        : undefined,
                                );
                            }}
                            characterLimit
                            ref={nonWinnerMessageTextEditorRef}
                            error={nonWinnerMessageError}
                        />
                    </FormControl>
                </Grid>
            </Grid>{" "}
        </Grid>
    );
};
