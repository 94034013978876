import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

interface Props extends ButtonProps {
    label: string;
}

export const BackButton = ({ label, ...buttonProps }: Props) => {
    return (
        <Button
            variant="text"
            size="small"
            fullWidth={false}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                lineHeight: "normal",
                gap: "8px",
            }}
            {...buttonProps}
        >
            <ChevronLeftIcon sx={{ color: "#1564DC" }} />{" "}
            <Typography sx={{ color: "#1564DC", fontWeight: "350", fontSize: "14px" }}>{label}</Typography>
        </Button>
    );
};
