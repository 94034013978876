import { Box, TextField, Typography } from "@mui/material";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import Logo from "@dono-business/shared/images/logo.svg";
import { LoginContainer, LogoContainer } from "./LoginScreen.styles";
import { Button } from "@dono-business/shared/components";
import { getEmailValidationRegex, getToken } from "@dono-business/shared/utils";
type Props = { redirectPath: string; pageTitle: string };
export const LoginScreen = ({ redirectPath, pageTitle }: Props) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const actionCodeSettings = {
        url: window.location.href + "/authenticate",
        handleCodeInApp: true,
    };

    useEffect(() => {
        const token = getToken();
        if (token) {
            navigate(redirectPath);
        }
    }, [navigate, redirectPath]);

    const submitUsername = async ({ username }: { username: string }) => {
        const auth = getAuth();
        setIsLoading(true);
        sendSignInLinkToEmail(auth, username, actionCodeSettings)
            .then(() => {
                window.localStorage.setItem("emailForSignIn", username);
                setEmailSent(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const { control, handleSubmit } = useForm({ defaultValues: { username: "" } });
    return (
        <LoginContainer>
            <LogoContainer>
                <img src={Logo} alt="DONO" />
            </LogoContainer>
            <Typography variant="title" color="primary">
                {pageTitle}
            </Typography>
            {emailSent ? (
                <Box sx={{ mt: 5 }}>
                    <Typography component={"div"}>
                        Login link sent to your email. Please check your email to login.
                    </Typography>
                </Box>
            ) : (
                <>
                    <Box m="auto" width={300}>
                        <form onSubmit={handleSubmit(submitUsername)}>
                            <Controller
                                name="username"
                                control={control}
                                rules={{
                                    required: { value: true, message: "Email is required" },
                                    pattern: {
                                        value: getEmailValidationRegex(),
                                        message: "Please enter a valid email address",
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        label="Username"
                                        placeholder="Email"
                                        sx={{ mt: 5 }}
                                        error={Boolean(error)}
                                        helperText={error?.message ?? ""}
                                        size="small"
                                        autoComplete="email"
                                    />
                                )}
                            />

                            <Button sx={{ mt: 3 }} type="submit" isLoading={isLoading}>
                                Submit
                            </Button>
                        </form>
                    </Box>

                    <div id="recaptcha-container" />
                </>
            )}
        </LoginContainer>
    );
};
