// Formats the valid number to a string with format like "$5.20"
export const formatNumberToCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(
        amount,
    );
};

// Formatter function for grid cells that should render an amount in currency format
export const amountCellValueFormatter = (amount: unknown) =>
    isNaN(Number(amount)) ? "-" : formatNumberToCurrency(Number(amount));
export const createDropDownFilterOptionsFromEnum = (_enum: { [key: string]: string }) =>
    Object.entries(_enum).map(([label, value]) => ({ label, value }));
