import "./App.css";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { theme } from "@dono-business/shared/theme";
import {
    CreateEdgeEventScreen,
    ReportScreen,
    PaymentResultScreen,
    InviteLandingScreen,
    EntryLandingScreen,
    EmailLinkLogin,
    LoginScreen,
    RegistrationLandingScreen,
    BucksScreen,
    EventDetailScreen,
} from "screens";
import { Layout } from "components/layout";
import { ProtectedRoute } from "@dono-business/shared/components/ProtectedRoute";
import { MuiDateLocalizationProvider } from "@dono-business/shared/components/MuiDateLocalizationProvider";
import { AppRequestsProvider, AppTimezoneProvider } from "@dono-business/shared/components";
import * as requests from "api";

function App() {
    return (
        <AppRequestsProvider requests={requests}>
            <AppTimezoneProvider>
                <MuiDateLocalizationProvider>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute>
                                        <Layout />
                                    </ProtectedRoute>
                                }
                            >
                                <Route index element={<Navigate to={"/create-edge-event"} />} />
                                <Route path="create-edge-event" element={<CreateEdgeEventScreen />} />
                                <Route path="edit-edge-event" element={<CreateEdgeEventScreen />} />
                                <Route path="report" element={<ReportScreen />} />
                                <Route path="report/events/:edgeEventId" element={<EventDetailScreen />} />
                                <Route path="bucks" element={<BucksScreen />} />
                            </Route>
                            <Route
                                path="login"
                                element={<LoginScreen pageTitle="Edge Login" redirectPath="/create-edge-event" />}
                            />
                            <Route path="login/authenticate" element={<EmailLinkLogin />} />
                            <Route path="landing/entry/:eventUniqueId" element={<EntryLandingScreen />} />
                            <Route path="landing/invite/:eventUniqueId" element={<InviteLandingScreen />} />
                            <Route path="landing/registration/:eventUniqueId" element={<RegistrationLandingScreen />} />
                            <Route path="payment/:result" element={<PaymentResultScreen />} />
                        </Routes>
                    </ThemeProvider>
                </MuiDateLocalizationProvider>
            </AppTimezoneProvider>
        </AppRequestsProvider>
    );
}

export default App;
