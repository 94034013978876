import { AxiosInstance } from "axios";

export const uploadRequests = (baseUrl: string, customAxios: AxiosInstance) => ({
    uploadAudio: (audio: File) => {
        const formData = new FormData();
        formData.append("audioFile", audio);

        return customAxios.post(`${baseUrl}/gift/uploadAudio`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    uploadPhoto: (photo: File) => {
        const formData = new FormData();
        formData.append("photoFile", photo);

        return customAxios.post(`${baseUrl}/gift/uploadPhoto`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    uploadVideo: (video: File) => {
        const formData = new FormData();
        formData.append("videoFile", video);

        return customAxios.post(`${baseUrl}/gift/uploadVideo`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
});
