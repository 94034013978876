import React from "react";
import { EventDetailProvider } from "./EventDetailProvider";
import { EventDetailContent } from "./EventDetailContent/";

export const EventDetailScreen = () => {
    return (
        <EventDetailProvider>
            <EventDetailContent />
        </EventDetailProvider>
    );
};
