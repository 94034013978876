import React from "react";

export const ModifyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 25" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.06 4.08988L20.41 5.43988C21.2 6.21988 21.2 7.48988 20.41 8.26988L7.18 21.4999H3V17.3199L13.4 6.90988L16.23 4.08988C17.01 3.30988 18.28 3.30988 19.06 4.08988ZM5 19.4999L6.41 19.5599L16.23 9.72988L14.82 8.31988L5 18.1399V19.4999Z"
                fill="#0C2145"
            />
        </svg>
    );
};
