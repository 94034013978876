import { StepContentContainer } from "@dono-business/shared/components/step-content-container";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Divider, FormControl, Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useRef } from "react";
import { Controller } from "react-hook-form";

import { WideButton } from "./CreateEdgeEventScreen.styles";
import { CreateEdgeEventContext } from "./CreateEdgeEventProvider";
import { TextEditor, TextEditorRef } from "@dono-business/shared/components/texteditor";
import { DateTimePickerWithTimezone } from "@dono-business/shared/components/DateTimePickerWithTimeZone";
import { Dayjs } from "dayjs";
import { InstructionTooltip } from "./InstructionTooltip";
import { InstructionTooltipPositioner } from "./styles";
import { UploadMedia } from "@dono-business/shared/components/UploadMedia";
import { useAppRequests } from "@dono-business/shared/hooks";
import { convertEdgeEventMediaContentToMediaFileData } from "./helpers";
import { maximumLengthErrorMessage } from "@dono-business/shared/utils/other";
import { OnsiteContacts } from "./OnsiteContacts/";
import { useAppTimezone } from "@dono-business/shared/components";

export const Step1 = () => {
    const {
        handleStep1Submit,
        registrationFormOptions,
        setIsCompanyLogoUploading,
        setHasCompanyUnuploadedFile,
        setCompanyLogoMediaId,
        setCompanyLogoFile,
        previousCompanyLogoMediaContent,
        setPreviousCompanyLogoMediaContent,
        hasCompanyLogoUnuploadedFile,
        isCompanyLogoUploading,
    } = useContext(CreateEdgeEventContext) ?? {};
    const { formState, trigger, control, watch, handleSubmit } = registrationFormOptions ?? {};

    const { isValid, errors } = formState ?? {};
    const handleNextButtonClick = () => {
        if (hasUnuploadedFile) return;
        if (!isValid) trigger?.();
    };
    const registrationStartDate = watch?.("registrationPeriodStartDateAndTime");
    const registrationEndDate = watch?.("registrationPeriodEndDateAndTime");

    const areMinutesIn15MinInterval = (fieldValue: Dayjs) =>
        fieldValue.minute() % 15 === 0 || "Minutes should be in 15 minute interval";
    const compareStartDateWithEndDateToBeBefore = (endDate: Dayjs) =>
        !endDate.isBefore(registrationStartDate, "minute") || "End Date should be after Start Date";
    const compareStartDateWithEndDateToBeDifferent = (endDate: Dayjs) =>
        !endDate.isSame(registrationStartDate, "minute") ||
        "Registration period must be at least 15 minutes. Please extend this period";

    const compareDrawDateWithEndDate = (drawDate: Dayjs) =>
        drawDate.isAfter(registrationEndDate, "minute") || "Drawing Time should be after Registration Time";
    const { uploadRequests } = useAppRequests();
    const theme = useTheme();
    const hasSameStartAndEndDateError =
        errors?.registrationPeriodEndDateAndTime?.type === "compareStartDateWithEndDateToBeDifferent";
    const isAboveMd = useMediaQuery(theme.breakpoints.up("md"));
    const isSideErrorShowing = hasSameStartAndEndDateError && isAboveMd;
    const formRef = useRef<HTMLFormElement | null>(null);
    const { appTimezone, setAppTimezone } = useAppTimezone();
    const termsAndConditionsRef = useRef<TextEditorRef | null>(null);
    const hasUploadError = hasCompanyLogoUnuploadedFile === "uploadError";
    const hasUnuploadedFile = hasCompanyLogoUnuploadedFile === true;
    const isUploading = !!isCompanyLogoUploading;
    return (
        <StepContentContainer display="flex" flexDirection={"column"}>
            <Grid container>
                <Grid
                    xs={12}
                    item
                    container
                    rowGap={4}
                    ref={formRef}
                    component="form"
                    onSubmit={handleSubmit?.(handleStep1Submit ?? (() => null))}
                >
                    <Grid item container xs={11} sm={12} md={8}>
                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name="eventName"
                                rules={{ required: { value: true, message: "Event Name is mandatory" } }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Enter Event Name"
                                        label="Event Name"
                                        error={Boolean(error)}
                                        helperText={error?.message ?? ""}
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item container xs={12} columnSpacing={2} rowSpacing={4}>
                        <Grid item xs={11} sm={12} md={4}>
                            <FormControl fullWidth>
                                {" "}
                                <Controller
                                    control={control}
                                    name="registrationPeriodStartDateAndTime"
                                    rules={{
                                        required: { value: true, message: "Registration Start Date is mandatory" },
                                        validate: { areMinutesIn15MinInterval },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <DateTimePickerWithTimezone
                                            {...field}
                                            controlledTimezone
                                            timezone={appTimezone}
                                            onTimezoneChange={setAppTimezone}
                                            compactTimePicker
                                            slotProps={{
                                                textField: {
                                                    label: "Registration Start Date",
                                                    error: Boolean(error),
                                                    helperText: error?.message ?? "",
                                                    autoComplete: "off",
                                                },
                                            }}
                                            minutesStep={15}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={11} sm={12} md={4}>
                            <FormControl fullWidth sx={{ position: "relative" }}>
                                <Controller
                                    control={control}
                                    name="registrationPeriodEndDateAndTime"
                                    rules={{
                                        required: { value: true, message: "Registration End Date is mandatory" },
                                        validate: {
                                            compareStartDateWithEndDateToBeBefore,
                                            compareStartDateWithEndDateToBeDifferent,
                                            areMinutesIn15MinInterval,
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <DateTimePickerWithTimezone
                                            {...field}
                                            controlledTimezone
                                            timezone={appTimezone}
                                            onTimezoneChange={setAppTimezone}
                                            compactTimePicker
                                            slotProps={{
                                                textField: {
                                                    label: "Registration End Date",
                                                    error: !isSideErrorShowing && Boolean(error),
                                                    helperText: !isSideErrorShowing && (error?.message ?? ""),
                                                    autoComplete: "off",
                                                },
                                            }}
                                            minutesStep={15}
                                            timezonePickerStyles={
                                                isSideErrorShowing
                                                    ? { border: `2px solid ${theme.palette.error.main}` }
                                                    : undefined
                                            }
                                        />
                                    )}
                                />
                                <InstructionTooltipPositioner>
                                    <InstructionTooltip>
                                        Select the time zone for the registration period. Depending on where and when
                                        registration will happen, this may be different from your current time zone.
                                    </InstructionTooltip>
                                </InstructionTooltipPositioner>
                            </FormControl>
                        </Grid>
                        {isSideErrorShowing && (
                            <Grid item xs>
                                <Box pl="28px">
                                    <Typography fontSize={14} sx={{ color: theme.palette.error.main }}>
                                        {errors.registrationPeriodEndDateAndTime?.message}
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        xs={11}
                        sm={12}
                        md={8}
                        item
                        container
                        position={"relative"}
                        component={FormControl}
                        columnSpacing={2}
                    >
                        <Controller
                            control={control}
                            name="drawingTime"
                            rules={{
                                required: { value: true, message: "Drawing Time is mandatory" },
                                validate: {
                                    compareDrawDateWithEndDate,
                                    areMinutesIn15MinInterval,
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <DateTimePickerWithTimezone
                                    {...field}
                                    compactTimePicker
                                    controlledTimezone
                                    timezone={appTimezone}
                                    onTimezoneChange={setAppTimezone}
                                    slotProps={{
                                        textField: {
                                            label: "Drawing Time",
                                            error: Boolean(error),
                                            helperText: error?.message ?? "",
                                            autoComplete: "off",
                                        },
                                    }}
                                    minutesStep={15}
                                    renderDateTimePicker={(dateTimePicker) => (
                                        <Grid item xs md={6}>
                                            {dateTimePicker}
                                        </Grid>
                                    )}
                                    renderTimezonePicker={(timezonePicker) => (
                                        <Grid item xs={"auto"} position="relative">
                                            {timezonePicker}
                                            <InstructionTooltipPositioner>
                                                <InstructionTooltip>
                                                    Select the time zone for this drawing. Depending on where and when
                                                    the drawing is held, this may be different from your current time
                                                    zone.
                                                </InstructionTooltip>
                                            </InstructionTooltipPositioner>
                                        </Grid>
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name="termsAndConditions"
                                rules={{
                                    validate: {
                                        characterLimit: () => {
                                            const remaniningLength =
                                                termsAndConditionsRef.current?.getRemainingLength() ?? 0;
                                            return remaniningLength >= 0 || maximumLengthErrorMessage;
                                        },
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextEditor
                                        {...field}
                                        uniqueName="termsAndConditions"
                                        ref={(el) => {
                                            field.ref(el);
                                            termsAndConditionsRef.current = el;
                                        }}
                                        error={error}
                                        characterLimit
                                        label="Terms & Conditions"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Divider orientation="horizontal" sx={{ borderColor: "#eaeaea", my: 5 }} />
                </Grid>
                <Grid item xs={12}>
                    <OnsiteContacts />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Divider orientation="horizontal" sx={{ borderColor: "#eaeaea", my: 5 }} />
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.text.primary,
                            fontWeight: "700",
                            fontSize: "16px",
                            position: "relative",
                            mb: 3,
                            display: "block",
                        }}
                    >
                        Company Logo <span style={{ fontWeight: "400" }}>(Optional)</span>
                    </Typography>
                    <UploadMedia
                        placeholderTitle={<Typography fontWeight={"700"}>Upload Logo</Typography>}
                        placeholderText="You may upload a logo for this event. The ideal dimensions for a logo size is 100 x 100 pixels. "
                        idealHeight={100}
                        idealWidth={100}
                        acceptedFileTypes={["image/*"]}
                        uploadPhotoRequest={uploadRequests.uploadPhoto}
                        onUploadStart={() => setIsCompanyLogoUploading?.(true)}
                        onUploadFinish={(data) => {
                            setIsCompanyLogoUploading?.(false);
                            if (data) {
                                setCompanyLogoFile?.(data.file);
                                setCompanyLogoMediaId?.(data.mediaId);
                            } else setHasCompanyUnuploadedFile?.("uploadError");
                        }}
                        onFileSelected={() => setHasCompanyUnuploadedFile?.(true)}
                        onClearSelectedFile={() => setHasCompanyUnuploadedFile?.(false)}
                        defaultFile={convertEdgeEventMediaContentToMediaFileData(
                            "LOGO FILE",
                            previousCompanyLogoMediaContent,
                        )}
                        onClearDefaultFile={() => setPreviousCompanyLogoMediaContent?.()}
                        onClearUploadedFile={() => {
                            setCompanyLogoMediaId?.();
                            setCompanyLogoFile?.();
                        }}
                        placeholderWidth={250}
                        placeholderHeight={300}
                    />
                </Grid>
                <Grid item xs={12} mt={2} textAlign="right" display={"flex"} justifyContent="flex-end">
                    <span onClick={handleNextButtonClick}>
                        <WideButton
                            disabled={!isValid || isUploading || hasUploadError}
                            fullWidth={false}
                            onClick={() => formRef.current?.requestSubmit()}
                        >
                            {isUploading ? (
                                "Uploading..."
                            ) : (
                                <>
                                    Next <KeyboardArrowRight />
                                </>
                            )}
                        </WideButton>
                    </span>
                </Grid>
            </Grid>
        </StepContentContainer>
    );
};
