import React, { SVGProps } from "react";

export const StepTwoEdgeColoredIcon = () => (
    <svg width="119" height="119" viewBox="0 0 119 119" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_549_8100)">
            <path
                d="M91 14.0059H28C21.3726 14.0059 16 19.3784 16 26.0059V88.0059C16 94.6333 21.3726 100.006 28 100.006H91C97.6274 100.006 103 94.6333 103 88.0059V26.0059C103 19.3784 97.6274 14.0059 91 14.0059Z"
                fill="url(#paint0_linear_549_8100)"
            />
            <path
                d="M91 14.5059H28C21.6487 14.5059 16.5 19.6546 16.5 26.0059V88.0059C16.5 94.3571 21.6487 99.5059 28 99.5059H91C97.3513 99.5059 102.5 94.3571 102.5 88.0059V26.0059C102.5 19.6546 97.3513 14.5059 91 14.5059Z"
                stroke="white"
                strokeOpacity="0.01"
            />
        </g>
        <path
            d="M66.496 82H35.08V75.676L54.256 56.636C55.7067 55.14 56.9533 53.576 57.996 51.944C59.0387 50.312 59.56 48.476 59.56 46.436C59.56 45.1667 59.3333 44.0333 58.88 43.036C58.4267 41.9933 57.8147 41.1093 57.044 40.384C56.2733 39.6587 55.3667 39.1147 54.324 38.752C53.2813 38.344 52.1707 38.14 50.992 38.14C48.544 38.14 46.5267 38.9333 44.94 40.52C43.3533 42.0613 42.356 44.056 41.948 46.504L35.488 45.416C35.76 43.6027 36.3267 41.9253 37.188 40.384C38.0947 38.7973 39.228 37.4373 40.588 36.304C41.948 35.1253 43.512 34.2187 45.28 33.584C47.0933 32.9493 49.02 32.632 51.06 32.632C53.0547 32.632 54.9587 32.9267 56.772 33.516C58.6307 34.1053 60.2627 34.9667 61.668 36.1C63.0733 37.2333 64.184 38.6613 65 40.384C65.8613 42.1067 66.292 44.0787 66.292 46.3C66.292 47.8413 66.088 49.2693 65.68 50.584C65.272 51.8987 64.7053 53.1453 63.98 54.324C63.3 55.5027 62.484 56.636 61.532 57.724C60.58 58.7667 59.5827 59.8093 58.54 60.852L42.9 76.084H66.496V82Z"
            fill="#0C2145"
        />
        <path
            d="M79.5723 91.1021H72.5723C72.1722 91.1021 71.8123 90.842 71.6923 90.462L70.0925 85.3819L66.6923 83.4219L61.4923 84.5619C61.0921 84.642 60.6923 84.462 60.4923 84.1219L57.0123 78.082C56.8123 77.7419 56.8525 77.3018 57.1323 77.0019L60.7124 73.082V69.142L57.1122 65.202C56.8321 64.902 56.7922 64.462 56.9923 64.1219L60.4923 58.0619C60.6923 57.7218 61.0921 57.5418 61.4923 57.6219L66.6923 58.762L70.0925 56.8019L71.6923 51.7218C71.8123 51.3622 72.1722 51.1021 72.5723 51.1021H79.5523C79.9524 51.1021 80.3123 51.3622 80.4323 51.7422L82.0321 56.8222L85.4323 58.7823L90.6323 57.6422C91.0325 57.5622 91.4323 57.7421 91.6323 58.0823L95.1323 64.1423C95.3324 64.4824 95.2922 64.9224 95.0124 65.2223L91.4323 69.1423V73.0622L95.0124 76.9821C95.2925 77.2821 95.3324 77.7221 95.1323 78.0622L91.6323 84.1222C91.4323 84.4623 91.0325 84.6423 90.6323 84.5622L85.4323 83.4221L82.0321 85.3822L80.4323 90.4623C80.3324 90.842 79.9724 91.1021 79.5723 91.1021Z"
            fill="#B39CB8"
        />
        <path
            d="M79.5723 91.102H72.5723C72.1722 91.102 71.8123 90.842 71.6923 90.462L70.0925 85.3819L66.6923 83.4218L61.4923 84.5619C61.0921 84.642 60.6923 84.462 60.4923 84.1219L57.0123 78.082C56.8123 77.7418 56.8525 77.3018 57.1323 77.0019L60.7124 73.082V69.142L57.1122 65.202C56.8321 64.902 56.7922 64.462 56.9923 64.1219L60.4923 58.0619C60.6923 57.7218 61.0921 57.5418 61.4923 57.6219L66.6923 58.762L70.0925 56.8019L71.6923 51.7218C71.8123 51.3622 72.1722 51.1021 72.5723 51.1021H79.5523C79.9524 51.1021 80.3123 51.3622 80.4323 51.7422L82.0321 56.8222L85.4323 58.7823L90.6323 57.6422C91.0325 57.5622 91.4323 57.7421 91.6323 58.0823L95.1323 64.1423C95.3324 64.4824 95.2922 64.9224 95.0124 65.2223L91.4323 69.1423V73.0622L95.0124 76.9821C95.2925 77.2821 95.3324 77.722 95.1323 78.0622L91.6323 84.1222C91.4323 84.4623 91.0325 84.6423 90.6323 84.5622L85.4323 83.4221L82.0321 85.3822L80.4323 90.4623C80.3324 90.842 79.9724 91.102 79.5723 91.102ZM73.2523 89.2619H78.8924L80.4125 84.4818C80.4725 84.2619 80.6323 84.0816 80.8324 83.9617L84.8525 81.6417C85.0525 81.5217 85.2925 81.4818 85.5124 81.5418L90.3924 82.6219L93.2124 77.7419L89.8324 74.0418C89.6725 73.8819 89.5924 73.6417 89.5924 73.4218V68.782C89.5924 68.5622 89.6725 68.3219 89.8324 68.162L93.2124 64.462L90.3924 59.582L85.5124 60.662C85.2925 60.7019 85.0523 60.6821 84.8525 60.5622L80.8324 58.2422C80.6323 58.1222 80.4923 57.9422 80.4125 57.7221L78.8924 52.9419L73.2523 52.9422L71.7323 57.7224C71.6723 57.9422 71.5124 58.1225 71.3123 58.2424L67.3123 60.5622C67.1123 60.6822 66.8723 60.7221 66.6525 60.6621L61.7725 59.582L58.9323 64.462L62.3123 68.1621C62.4722 68.322 62.5523 68.5622 62.5523 68.7821V73.4222C62.5523 73.642 62.4722 73.8823 62.3123 74.0421L58.9323 77.7422L61.7523 82.6222L66.6323 81.5421C66.8522 81.5022 67.0924 81.522 67.2922 81.642L71.3123 83.962C71.5123 84.082 71.6524 84.262 71.7322 84.4821L73.2523 89.2619Z"
            fill="white"
        />
        <path
            d="M76.0716 79.3821C65.1116 79.0419 65.1116 63.1421 76.0716 62.8021C87.0316 63.162 87.0316 79.0421 76.0716 79.3821ZM76.0716 64.6621C67.5516 64.9422 67.5516 77.2821 76.0716 77.5621C84.5916 77.2819 84.5916 64.9221 76.0716 64.6621Z"
            fill="white"
        />
        <path
            d="M84.8128 63.2821C84.8128 64.4821 82.9727 64.4821 82.9727 63.2821C82.9729 62.082 84.8128 62.082 84.8128 63.2821Z"
            fill="white"
        />
        <path
            d="M87.1332 72.022C86.6332 72.022 86.2133 71.6021 86.2133 71.1021C86.2133 69.4221 85.7934 67.7821 85.0132 66.322C84.7732 65.8819 84.9331 65.322 85.3932 65.082C87.3533 64.2419 88.0933 69.8019 88.0732 71.122C88.0531 71.6022 87.6332 72.0221 87.1332 72.0221L87.1332 72.022Z"
            fill="white"
        />
        <defs>
            <filter
                id="filter0_d_549_8100"
                x="0"
                y="0.00585938"
                width="119"
                height="118"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="8" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.239216 0 0 0 0 0.121569 0 0 0 0 0.262745 0 0 0 0.2 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_549_8100" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_549_8100" result="shape" />
            </filter>
            <linearGradient
                id="paint0_linear_549_8100"
                x1="69.331"
                y1="19.5959"
                x2="10.814"
                y2="133.583"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#B993C2" />
                <stop offset="1" stopColor="#5D4A61" />
            </linearGradient>
        </defs>
    </svg>
);
