import { useState } from "react";

export const useUnsavedItem = ({ isFormValid }: { isFormValid: () => Promise<boolean> }) => {
    const handleFormBlur = async () => {
        if (hasFocusedOnForm) {
            const isValid = await isFormValid();
            if (!isValid) return;

            setHasFocusedOnForm(false);
            toggleUnsavedDialogOpen(true);
        }
    };
    const handleFormClick = () => setHasFocusedOnForm(true);

    const toggleUnsavedDialogOpen = (isShowing: boolean) => {
        setUnsavedDialogOpen(isShowing);
    };
    const [hasFocusedOnForm, setHasFocusedOnForm] = useState(false);
    const [unsavedDialogOpen, setUnsavedDialogOpen] = useState(false);
    return { unsavedDialogOpen, toggleUnsavedDialogOpen, handleFormClick, handleFormBlur };
};
