import { CustomDataGrid } from "@dono-business/shared/components";
import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { useReportEventParticipantsGridColumns } from "./useReportEventParticipantsGridColumns";
import { EventDetailContext } from "../EventDetailProvider";

export const ParticipantsGrid = () => {
    const participantsGridColumns = useReportEventParticipantsGridColumns();
    const { event } = useContext(EventDetailContext) ?? {};
    const { items: participants } = event ?? {};
    return (
        <CustomDataGrid
            getRowId={(r) => r.id}
            rows={participants?.map((p) => ({ ...p, id: uuidv4() })) ?? []}
            columns={participantsGridColumns}
            autoHeight
            initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
            disableRowSelectionOnClick
        />
    );
};
