import React from "react";

export const SearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 25" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2278 14.1418L20.9578 19.8718L19.4678 21.3618L13.7378 15.6318C12.6678 16.4018 11.3778 16.8718 9.96777 16.8718C6.37777 16.8718 3.46777 13.9618 3.46777 10.3718C3.46777 6.78183 6.37777 3.87183 9.96777 3.87183C13.5578 3.87183 16.4678 6.78183 16.4678 10.3718C16.4678 11.7818 15.9978 13.0718 15.2278 14.1418ZM9.96777 5.87183C7.47777 5.87183 5.46777 7.88183 5.46777 10.3718C5.46777 12.8618 7.47777 14.8718 9.96777 14.8718C12.4578 14.8718 14.4678 12.8618 14.4678 10.3718C14.4678 7.88183 12.4578 5.87183 9.96777 5.87183Z"
                fill="#707070"
            />
        </svg>
    );
};
