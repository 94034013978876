import { useState, useEffect } from "react";
import { BucksTransactionModel } from "@dono-business/shared/models/bucks-transaction-model";
import { useAppRequests } from "@dono-business/shared/hooks";

export const useBucksTransactions = () => {
    const { bucksRequests } = useAppRequests();

    const [bucksTransactions, setBucksTransactions] = useState<BucksTransactionModel[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const pageSize = 10;
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await bucksRequests.getUserBucksTransactions(pageNumber, pageSize);
                setBucksTransactions(response?.data?.items ?? []);
                setPageCount(response?.data?.totalPages ?? 0);
                setTotalItems(response?.data?.totalItems ?? 0);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [pageNumber, pageSize]);

    return { bucksTransactions, loading, pageCount, pageSize, pageNumber, setPageNumber, totalItems };
};
