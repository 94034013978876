import { ContentModel, MediaFileData } from "../models";
import businessLogo from "../images/business-logo.svg";
export const convertContentToMediaFileData = (
    fileName: string,
    mediaContent?: ContentModel,
): MediaFileData | undefined => {
    if (mediaContent && mediaContent.type && mediaContent.value) {
        return {
            type: mediaContent.type as "photo" | "video",
            filesContent: [{ name: fileName, content: mediaContent.value }],
        };
    }
};
export const maximumLengthErrorMessage = "Maximum length exceeded";
export const createCompanyLogoPreviewPlaceHolder = (): MediaFileData => ({
    type: "photo",
    filesContent: [{ name: "LOGO", content: businessLogo }],
});
