import * as React from "react";
import { SVGProps } from "react";

export const AccountIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={14} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.63 0a3.658 3.658 0 1 0 0 7.315A3.658 3.658 0 0 0 6.63 0ZM6.4 8.686A6.54 6.54 0 0 0 0 15.307c0 .16 0 .326.008.472A.237.237 0 0 0 .236 16h12.786a.238.238 0 0 0 .23-.221c.008-.17.006-.301.006-.465a7.035 7.035 0 0 0-2.314-5.085 6.233 6.233 0 0 0-4.086-1.543H6.4Z"
            fill="#0C2145"
        />
        <path
            d="M7.043 1.373a.228.228 0 0 0-.007.45A1.888 1.888 0 0 1 8.458 3.66a.23.23 0 1 0 .457 0 2.34 2.34 0 0 0-1.778-2.283.227.227 0 0 0-.093 0v-.003ZM.001 15.309c-.003.16.004-3.623-.001 0Z"
            fill="#fff"
        />
    </svg>
);
