import React from "react";

export const RemoveOutlinedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 19 18" fill="none">
            <path
                d="M16.6758 0H2.67578C1.57578 0 0.675781 0.9 0.675781 2V16C0.675781 17.1 1.57578 18 2.67578 18H16.6758C17.7758 18 18.6758 17.1 18.6758 16V2C18.6758 0.9 17.7758 0 16.6758 0ZM16.6758 16H2.67578V2H16.6758V16ZM4.67578 8H14.6758V10H4.67578V8Z"
                fill="#0C2145"
            />
        </svg>
    );
};
