export const flatten = (obj: object, parent?: string): object => {
    let res = Object.create({});

    for (const [key, value] of Object.entries(obj)) {
        const propName = parent ? parent + "_" + key : key;
        const flattened = Object.create({});

        if (value instanceof Date) {
            flattened[key] = value.toISOString();
        } else if (typeof value === "object" && value !== null) {
            res = { ...res, ...flatten(value as object, propName) };
        } else {
            res[propName] = value;
        }
    }

    return res;
};
